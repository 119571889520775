// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_form_control__6GWGC {
    display: flex;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    margin-left: 1em;
  }
  
  .Checkbox_form_control__6GWGC label {
    margin-bottom: 0.2em;
    font-weight: bold;
    font-size: 0.8em;
  }
  
  .Checkbox_form_control__6GWGC checkbox {
    padding: 2em;
    border: 1px solid #777;
    border-radius: 5px;
  }
  
  .Checkbox_form_control__6GWGC checkbox::placeholder {
    color: #7b7b7b;
  }`, "",{"version":3,"sources":["webpack://./src/componentes/formularios/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".form_control {\r\n    display: flex;\r\n    margin-bottom: 1.5em;\r\n    margin-top: 1.5em;\r\n    margin-left: 1em;\r\n  }\r\n  \r\n  .form_control label {\r\n    margin-bottom: 0.2em;\r\n    font-weight: bold;\r\n    font-size: 0.8em;\r\n  }\r\n  \r\n  .form_control checkbox {\r\n    padding: 2em;\r\n    border: 1px solid #777;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .form_control checkbox::placeholder {\r\n    color: #7b7b7b;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Checkbox_form_control__6GWGC`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react';

import { Link } from 'react-router-dom'

import api from '../../../utils/Api'

// import Btn_Pesquisa from '../../../assets/img/botao_pesquisa.png'

import Styles from '../../layout/estilos/Dashboard.module.css'
import formStyles  from '../../formularios/FormPaginas.module.css'

import toastMessage from './../../../hooks/toastModel'

// import { BsSearch } from "react-icons/bs"
// import { FcSearch } from "react-icons/fc"
import { BiEdit, BiTrash } from "react-icons/bi"

import 'bootstrap/dist/css/bootstrap.min.css';  
import {Container , Button, Row} from 'react-bootstrap' 
// import Tooltip from "react-tooltip";
import {Tooltip, TooltipProvider, TooltipWrapper, removeStyle} from "react-tooltip"
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';


function ListarUsuarios() {
  const siteApi = process.env.REACT_APP_API
  const itensRef = useRef([])

  // const showOrHide = () => setShowElement(true)
  const [isOpen, setIsOpen] = useState({})

  const [lista, setLista] = useState({});
  const [token] = useState(localStorage.getItem('token') || '')
  // const [showElement, setShowElement] = useState(false)

  // const { setFlashMessage } = useFlashMessage()
  const history = useNavigate();

/*
  function gotoPage( id ){
     history(`/usuarios/editar/${id}`)
 }
*/

  const fetchData = async () => {
    let msgType = 'sucess'
    let msgText = ''
    let msgError = false

    const result = await fetch(`${siteApi}/usuarios/`,{
      method: 'POST',
      headers: { 
          'Authorization': `Bearer ${JSON.parse(token)}`,
          'Content-Type': 'application/json',
          'Connection':'keep-alive'
       }})
      .then((result) => result.json())
      .catch((error) => {
        msgText = error.response.data.message
        msgType = 'error'
        msgError = true
    });
    setLista( result.user );

    if (msgError === true){
        toastMessage(msgText, msgType)
    }

  }

  useEffect(() => {
    fetchData();
   }, [token] )


  async function excluirUsuarioId( id ){

    let msgType = 'sucess'
    let msgText = ''
    let msgError = false

    await api.post('/usuarios/remover', JSON.stringify({id: id}),{
      headers: {
      'Authorization': `Bearer ${JSON.parse(token)}`,
      'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const updatedUsers = lista.filter((lista) => lista.id !== id)
        setLista( updatedUsers )
        return response.data
      })
      .catch((error) => {
        msgText = error.response.data.message
        msgType = 'error'
        msgError = true
      })

      if (msgError === true){
        toastMessage(msgText, msgType)
      }

  }


  function hideModal() {
    setIsOpen({show: 0});
  };


  function excluirUsuario( id ){
    setIsOpen({show: id});
  }

  function editarUsuario( userId ){
    history(`/usuarios/editar/${userId}`)
  }

  return (

  <section>
      <div className={Styles.lista_header} >
          <div >
              <h1>Usuarios Cadastrados</h1>
          </div>
          <div className={Styles.actions}>
              <Link to="/usuarios/cadastro" className={Styles.conclude_btn}>Cadastrar Usuario</Link>
          </div>
      </div>

      <div className="App">
          <Container className='p-4 shadow-lg'> 
            <div  className={formStyles.form_container_Row}>

                <Row  className="bg-secondary p-2 text-white font-weight-bold">  
                <div className={Styles.lista_clear}>
                    <div className={formStyles.format05} />
                    <div className={formStyles.format30}>
                        <span className="text-center">Usuário</span>
                    </div>
                    <div className={formStyles.format10} />
                    <div className={formStyles.format50}>
                        <span>Nome do Funcionário</span>
                    </div>
                </div>
                </Row>

              { 
              lista.length > 0 &&
              lista.map((item, index) => {
              return (
                  <Row key={index}  className="bg-white p-0 text-black font-weight-bold">
                  <div key={item.id} className={Styles.lista_row}>
                      <div className={formStyles.format05} />

                      <div className={formStyles.format30}>
                        <span className={Styles.linkCol} onClick={() => { editarUsuario( item.id ) }}>{item.usuario}</span>
                      </div>

                      <div className={formStyles.format50}>
                        <span className={Styles.linkCol} onClick={() => { editarUsuario( item.id ) }}>{item.nome}</span>
                      </div>

                      <div>
                          <Modal  ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                              itensRef.current[item.id][0] = ref } } 
                              show={ isOpen.show === item.id} 
                              onHide={() => hideModal(0) } 
                              className='p-2 shadow-lg text-dark'>

                              <Modal.Header closeButton>
                                  <Modal.Title>
                                      Excluir Usuário
                                  </Modal.Title>
                              </Modal.Header>
  
                              <Modal.Body>
                                  <Container>
                                    <div className={formStyles.form_textcenter}>
                                        <span>Confirma a Exclusão de: {item.nome} ?</span>
                                    </div>
                                  </Container>
                              </Modal.Body>
                          
                              <Modal.Footer>
                                  <Container>
                                      <div className={formStyles.format}>
                                          <div className={formStyles.format30}/>
                                              <div className={formStyles.format20}>
                                                  <Button variant="outline-primary" onClick={() => excluirUsuarioId( item.id )}>&nbsp;Excluir&nbsp;</Button>
                                              </div>
                                              <div className={formStyles.format10}/>
                                              <div className={formStyles.format20}>
                                                  <Button variant="outline-danger" onClick={() => hideModal(0) } >Cancelar</Button>
                                              </div>
                                          <div className={formStyles.format30}/>
                                      </div>
                                  </Container>
                              </Modal.Footer>
                          </Modal>
                      </div>

                      <div className={Styles.actions}>
                        <BiEdit  className={Styles.icon_grid2} onClick={() => { editarUsuario( item.id ) }}
                        data-tip="Editar"
                        data-for="btn_Editar" />
                        <Tooltip id="btn_Editar" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiTrash className={Styles.icon_grid2} onClick={() => { excluirUsuario(item.id) }}
                        data-tip="Excluir"
                        data-for="btn_Excluir" />
                        <Tooltip id="btn_Excluir" type="error" effect="solid" borderColor="black" />
                      </div>
                  </div> 
                  </Row>
                )})
                }

              </div>
          </Container> 
      </div>
  </section>
  )

}

export default ListarUsuarios


/*
                <Row>  
                    <Col className="bg-secondary p-2 text-white font-weight-bold text-center" md={1} /> 
                    <Col className="bg-secondary p-2 text-white font-weight-bold" md={1}>Usuário</Col>
                    <Col className="bg-secondary p-2" md={2}></Col>
                    <Col className="bg-secondary p-2 text-white font-weight-bold" md={6}>Nome do Funcionário</Col>  
                    <Col className="bg-secondary p-2"></Col>  
                    <Col className="bg-secondary p-2"></Col>  
                </Row>

*/


/*

<Link to={`/usuarios/editar/${item.id}`}>Editar</Link>


<span className="bold">{item.docto1}</span>
</div>
<div className={formStyles.format10}>
    <span className="bold"> </span>
</div>
<div className={formStyles.format50}>
    <span className="bold">{item.nome}</span>
</div>
*/

  /*  
      <div className={Styles.lista_header}>
      <h1>Usuarios Cadastrados</h1>
      <Link to="/usuarios/cadastro">Cadastrar Usuario</Link>
    </div>

    <div className={Styles.lista_container}>
    { 
      lista.length > 0 &&
        lista.map((item, index) => {
        return (
        <div key={item.id} className={Styles.lista_row}>
          <span className="bold">{item.nome}</span>

          <div className={Styles.actions}>
              <Link to={`/usuarios/editar/${item.id}`}>Editar</Link>

              <button className={Styles.exclude_btn} onClick={() => { removerUsuario(item.id) }} > Excluir </button>
          </div>

        </div>
        )})
    }

    {lista.length === 0 && <p>Ainda não há Usuários cadastrados!</p>}

    </div>
*/
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__r\\+iBw {
    min-height: 80vh;
    padding: 1em 2em 3em;
    max-width: 1200px;
    margin: 0 auto;
    border: 1px;
    border-color: #000000
  }

  .Container_containerMin__I7smN {
    border: 1px;
    border-color: #000000
  }`, "",{"version":3,"sources":["webpack://./src/componentes/layout/estilos/Container.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;IACd,WAAW;IACX;EACF;;EAEA;IACE,WAAW;IACX;EACF","sourcesContent":[".container {\r\n    min-height: 80vh;\r\n    padding: 1em 2em 3em;\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    border: 1px;\r\n    border-color: #000000\r\n  }\r\n\r\n  .containerMin {\r\n    border: 1px;\r\n    border-color: #000000\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__r+iBw`,
	"containerMin": `Container_containerMin__I7smN`
};
export default ___CSS_LOADER_EXPORT___;

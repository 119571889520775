// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__sjNkQ {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    color: #444;
    border-top: 1px solid #666;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/layout/estilos/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,0BAA0B;AAC9B","sourcesContent":[".footer {\r\n    height: 50px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: #eee;\r\n    color: #444;\r\n    border-top: 1px solid #666;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__sjNkQ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_form_control__W6UNH {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
  }
  
.Select_form_control__W6UNH label {
    margin-bottom: 0em;
    font-weight: bold;
    font-size: 0.8em;
  }
  
.Select_form_control__W6UNH select {
    background-color: #fff;
    padding: 0.2em;
    border: 1px solid #777;
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/componentes/formularios/Select.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;EACtB;;AAEF;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;EAClB;;AAEF;IACI,sBAAsB;IACtB,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".form_control {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-bottom: 0.5em;\r\n  }\r\n  \r\n.form_control label {\r\n    margin-bottom: 0em;\r\n    font-weight: bold;\r\n    font-size: 0.8em;\r\n  }\r\n  \r\n.form_control select {\r\n    background-color: #fff;\r\n    padding: 0.2em;\r\n    border: 1px solid #777;\r\n    border-radius: 5px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Select_form_control__W6UNH`
};
export default ___CSS_LOADER_EXPORT___;

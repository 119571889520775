// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Galery_fieldset__Fd124 {
    display: flex;
    justify-content: space-between;
    padding: 1em 4em 1em 4em;
    background-color: #0082ae;
    color: #FFF;
    border: 1px;
    border-color: #000000
  }

  .Galery_container__1DzCt {
    padding: 0.5em 0.5em 0.5em 0.5em;
    max-width: 1000px;
    margin: 0 auto;
    border: 1px;
    border-color: #0c09c7
  }

  .Galery_imagem__zMHWb {
    cursor: pointer;
    margin-bottom: 0.2em;
  }


  .Galery_img-wrapper__uR\\+Qm {
    overflow: hidden;
  }
  
  .Galery_hover_zoom__fz8lm {
    transition: all 0.5s ease 0.3s;
    transform: scale(1);
  }

  .Galery_hover_zoom__fz8lm:hover {
    transform: scale(1.8);
  }

`, "",{"version":3,"sources":["webpack://./src/componentes/layout/estilos/Galery.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,wBAAwB;IACxB,yBAAyB;IACzB,WAAW;IACX,WAAW;IACX;EACF;;EAEA;IACE,gCAAgC;IAChC,iBAAiB;IACjB,cAAc;IACd,WAAW;IACX;EACF;;EAEA;IACE,eAAe;IACf,oBAAoB;EACtB;;;EAGA;IACE,gBAAgB;EAClB;;EAEA;IACE,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".fieldset {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 1em 4em 1em 4em;\r\n    background-color: #0082ae;\r\n    color: #FFF;\r\n    border: 1px;\r\n    border-color: #000000\r\n  }\r\n\r\n  .container {\r\n    padding: 0.5em 0.5em 0.5em 0.5em;\r\n    max-width: 1000px;\r\n    margin: 0 auto;\r\n    border: 1px;\r\n    border-color: #0c09c7\r\n  }\r\n\r\n  .imagem {\r\n    cursor: pointer;\r\n    margin-bottom: 0.2em;\r\n  }\r\n\r\n\r\n  .img-wrapper {\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .hover_zoom {\r\n    transition: all 0.5s ease 0.3s;\r\n    transform: scale(1);\r\n  }\r\n\r\n  .hover_zoom:hover {\r\n    transform: scale(1.8);\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": `Galery_fieldset__Fd124`,
	"container": `Galery_container__1DzCt`,
	"imagem": `Galery_imagem__zMHWb`,
	"img-wrapper": `Galery_img-wrapper__uR+Qm`,
	"hover_zoom": `Galery_hover_zoom__fz8lm`
};
export default ___CSS_LOADER_EXPORT___;

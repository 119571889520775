import React from 'react'
import Input from '../../formularios/Input'
import CheckBox from '../../formularios/Checkbox'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


import api from '../../../utils/Api'
// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../../componentes/layout/estilos/Dashboard.module.css'
import UsuarioForm from '../../paginas/usuarios/UsuarioForm'
import {Container} from 'react-bootstrap'

/* contexts */

function CadastroUsuario() {

    const [user, setUser] = useState({})
    // const { setFlashMessage } = useFlashMessage()
    const history = useNavigate();

    // const { cadastrar } = useContext( Context )

    async function cadastrar( user ) {
        let msgText = 'Cadastro realizado com sucesso!'
        let msgType = 'success'
        let confirme = ''

        const token = localStorage.getItem('token')
    
        // console.log(user)

           await api.post('/usuarios/cadastrar', user,
          {headers: 
            {
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
            },})
        .then((response) => {
            confirme = response.status
            return response.data
          })
        .catch((error) => {
            if( error ){
                // console.log(error.response.data.message)
                msgText = error.response.data.message
                msgType = 'error'
            }})     
          /// await authUser(data)
      
        // setFlashMessage(msgText, msgType)
        toastMessage(msgText, msgType)

        if ( confirme === 200 ){
            setTimeout(() => {
            history( '/usuarios/')
            }, 1000);
        }

    }



    function handleChange(e) {
       setUser({ ...user, [e.target.name]: e.target.value })
    }
  


    const handleSubmit = (e) => {
       e.preventDefault()
       cadastrar( user )
    }


    function handleChangeAdmin(e) {
        if ( e.target.checked ) {
            setUser({ ...user, [e.target.name]: 1 })
        } else {
            setUser({ ...user, [e.target.name]: 0 } )
        }
     }

    function homeUsuarios(){
        history('/usuarios')
    }

    return (
        <section  className={formStyles.form_container_sm}>
        
        <h1>Cadastrar Usuário</h1> 

        <form onSubmit={handleSubmit} className={formStyles.form_container_sm}>
            
        <div  className={formStyles.form_center}>
            <label> Dados do Usuário </label>
        </div>

        <Container className='p-4 shadow-lg text-dark'>
        <fieldset>       
        <div>
            <Input
            text="Nome"
            type="text"
            name="nome"
            placeholder="Digite o seu nome"
            handleOnChange={handleChange}
            />

            <Input
            text="Usuario"
            type="text"
            name="usuario"
            placeholder="Digite o usuario"
            handleOnChange={handleChange}
            />  

            <Input
            text="E-mail"
            type="email"
            name="email"
            placeholder="Digite o seu e-mail"
            handleOnChange={handleChange}
            />

            <CheckBox
            text="&nbsp;&nbsp;Usuário é Administrador"
            name="nivel"
            type="checkbox"
            handleOnChange={handleChangeAdmin}
            />               

            <Input
            text="Senha"
            type="password"
            name="password"
            placeholder="Digite a sua senha"
            handleOnChange={handleChange}
            />

            <Input
            text="Confirmação de senha"
            type="password"
            name="retpassword"
            placeholder="Confirme a sua senha"
            handleOnChange={handleChange}
            />
        </div>
        </fieldset>
        </Container>
            
        <fieldset>
        <div className={formStyles.format}>
                <div className={formStyles.format}>
                &nbsp;  
                </div>
            </div>
            <div className={Styles.lista_row} />
        </fieldset>

        <div className={formStyles.actions}>
            <div> 
                <input type="submit" value="Cadastrar" />
            </div>  
            <div> 
                <input type="button" value="Voltar" onClick={homeUsuarios}/>
            </div>
        </div>
    </form>

    </section>
    )
}

export default CadastroUsuario
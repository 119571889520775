import React from 'react'
import api from '../../../utils/Api'
import { useState, useEffect } from 'react'

import UsuarioForm from './UsuarioPerfil'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../../componentes/layout/estilos/Dashboard.module.css'

/* hooks */
// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

function PerfilUsuario() {

    const [usuario, setUsuario] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    // const { setFlashMessage } = useFlashMessage()

    
    async function fetchData(){
        await api.post('/usuarios/perfil/', JSON.stringify({id: 0}), {
            headers: {
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            setUsuario(response.data.usuario)
        })
    }


    useEffect(() => {
        fetchData()
    }, [])



    async function updateUsuario(usuario) {
        let msgText = 'Usuario atualizado com sucesso'
        let msgType = 'success'

        await api.post('/usuarios/gravar', JSON.stringify(usuario),{
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },
            })
        .then((response) => {
                return response.data
            })
        .catch((error) => {
                msgType = 'error'
                msgText = error.response.data.message
            })

            // setFlashMessage(data.message, msgType)
            toastMessage(msgText, msgType)

    }

    return (
        <section className={formStyles.form_container_sm}>
            <div>
                <h1>Perfil de: {usuario.nome}</h1>
                <p>Depois da edição os dados serão atualizados no sistema</p>
            </div>
            {usuario.nome && (
            <UsuarioForm handleSubmit={updateUsuario} usuarioData={usuario} btnText="Gravar" />
            )}
        </section>
    )

}

export default PerfilUsuario
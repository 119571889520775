import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

async function PaginaDefault() {
    const pdfDoc = await PDFDocument.create()
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
  
    // const page = pdfDoc.addPage()
    
    const page = pdfDoc.addPage([700, 430]);
  
    const { height } = page.getSize()
    // const { width } = page.getSize()
    const fontSize = 30
    page.drawText('Área de Visualização', {
      x: 220,
      y: height - 7 * fontSize,
      size: fontSize,
      font: timesRomanFont,
      color:  rgb(0.5, 0.5, 0.5),
    })
  
    const pdfBytes = await pdfDoc.save()
    let pdfBlob = new Blob( [pdfBytes], {type: "application/pdf"} )
    
    return pdfBlob
  
  }
  export default PaginaDefault
import React from 'react'
import { useState } from 'react'
import { useRef } from 'react';

import Styles from '../../layout/estilos/Dashboard.module.css'
import formStyles from '../../formularios/FormPaginas.module.css'

import {Container , Button, Row} from 'react-bootstrap'  
// import Tooltip from "react-tooltip"
import {Tooltip, TooltipProvider, TooltipWrapper, removeStyle} from "react-tooltip"
import Modal from 'react-bootstrap/Modal'

import { BsSearch, BsPlusCircle } from "react-icons/bs"
import { BiEdit, BiTrash} from "react-icons/bi"

function PesqTutorNome({showOrHidePesq, adicionarTutorPesq, editarTutorPesq, excluirTutorPesq, listaData}) {

    const itensRef = useRef([])
    const [isOpen, setIsOpen] = useState({})

    function hideModal() {
        setIsOpen({show: 0});
    }

    function excluirTutor( id ){
        setIsOpen({show: id});
    }
    
    return (
        <section>
        <Container> 
            <Row className="bg-secondary p-0 text-white font-weight-bold">  
                <div className={Styles.lista_clear}>

                    <div className={formStyles.format10}>
                        <label>CPF</label>
                    </div>

                    <div className={formStyles.format05} />

                    <div className={formStyles.format70}>
                        <label>Nome do Tutor</label>
                    </div>

                    <div className={formStyles.format05} />

                    <div className={formStyles.format05}>
                        <BsSearch onClick={showOrHidePesq} className={Styles.icon_grid2}
                        data-tip="Pesquisar"
                        data-for="btn_Pesquisa" />
                        <Tooltip id="btn_Pesquisa" type="warning" effect="solid" borderColor="black" />
                    </div>

                    <div className={formStyles.format05}>
                        <BsPlusCircle onClick={adicionarTutorPesq} className={Styles.icon_grid2}
                        data-tip="Cadastrar"
                        data-for="btn_Cadastro" />
                        <Tooltip id="btn_Cadastro" type="error" effect="solid" borderColor="black" />
                    </div>
                </div>
            </Row>
 
            <>
            {

            listaData && listaData.length > 0 &&
            listaData.map((item, index) => {
            return (
                <Row key={index}  className="bg-white p-0 text-black font-weight-bold"> 
                <div key={item.id} className={Styles.lista_row}>

                    <div className={formStyles.format10}>
                        <label onClick={() => { editarTutorPesq( item.id )}}>{item.docto1}</label>
                    </div>

                    <div className={formStyles.format05} />

                    <div className={formStyles.format70}>
                        <label className={Styles.linkCol} onClick={() => { editarTutorPesq( item.id )}}>{item.nome}</label>
                    </div>
                    
                    <div>
                        <Modal ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                            itensRef.current[item.id][0] = ref } } 
                            show={ isOpen.show === item.id} 
                            onHide={() => hideModal(0) }
                            className='p-1 shadow-lg text-dark' >

                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Excluir Tutor
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Container>
                                    <div className={formStyles.form_textcenter}>
                                        <label> Confirma a Exclusão de: {item.nome} ?</label>
                                    </div>
                                </Container>
                            </Modal.Body>

                            <Modal.Footer>
                                <Container>
                                    <div className={formStyles.format}>
                                        <div className={formStyles.format30}/>
                                        <div className={formStyles.format20}>
                                            <Button variant="outline-primary" onClick={() => excluirTutorPesq( item.id )}>&nbsp;Excluir&nbsp;</Button>
                                        </div>
                                        <div className={formStyles.format10}/>
                                        <div className={formStyles.format20}>
                                            <Button variant="outline-danger" onClick={() => hideModal(0) } >Cancelar</Button>
                                        </div>
                                        <div className={formStyles.format30}/>
                                    </div>
                                </Container>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className={Styles.actions}>
                        <BiEdit  className={Styles.icon_grid2} onClick={() => { editarTutorPesq( item.id ) }} 
                        data-tip="Editar"
                        data-for="btn_Editar" />
                        <Tooltip id="btn_Editar" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiTrash  className={Styles.icon_grid2} onClick={() => { excluirTutor(item.id) }}
                        data-tip="Excluir"
                        data-for="btn_Excluir" />
                        <Tooltip id="btn_Excluir" type="error" effect="solid" borderColor="black"  />
                    </div>
                </div>
                </Row>
            )})
            }

        </>

        <>
        { listaData.length === 0 && <p> Não há Tutor Pesquisado! </p>}
        </>
        </Container>
        </section>
        )


}

export default PesqTutorNome


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Animais_addAnimal_header__EHbPU {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .Animais_addAnimal_header__EHbPU h1 {
    margin-bottom: 0.1em;
  }`, "",{"version":3,"sources":["webpack://./src/componentes/paginas/animais/Animais.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;EACtB","sourcesContent":[".addAnimal_header {\r\n    text-align: center;\r\n    margin-bottom: 1em;\r\n  }\r\n  \r\n  .addAnimal_header h1 {\r\n    margin-bottom: 0.1em;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addAnimal_header": `Animais_addAnimal_header__EHbPU`
};
export default ___CSS_LOADER_EXPORT___;

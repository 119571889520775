
import toast from 'react-hot-toast';

function toastMessage(msg, type){
    if (type==='success'){
        toast.success(
            msg,
            {
                duration: 2000,
              
                // Styling
                style: {
                    width: '350px',
                    height: '100px',
                    border: '1px solid',
                    padding: '30px',
                    color: '#120394',
                },

                className: '',
                          
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: 'green',     //cor externa
                    secondary: 'white',   //cor interna
                },
              
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
    }

    if (type==='error'){
        toast.error( 
            msg,
            {
            duration: 2000,
          
            // Styling
            style: {
                width: '350px',
                height: '100px',
                border: '1px solid',
                padding: '30px',
                color: '#9e030b',
            },

            className: '',
                      
            // Change colors of success/error/loading icon
            iconTheme: {
                primary: 'red',     //cor externa
                secondary: 'white',   //cor interna
            },
          
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
    }
}

export default toastMessage;
import React from 'react'
import { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useParams } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import api from '../../../utils/Api'

import {Container , Button, Row, Col,} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';

import { FcDeleteDatabase } from "react-icons/fc"
import { FcCancel } from "react-icons/fc"
import { FcOk } from "react-icons/fc"

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'
import formControl from '../../formularios/Input.module.css'

import Input from '../../formularios/Input'
import TextArea from '../../formularios/TextArea'

import { cpfMask } from '../../../utils/cpfMask'
import { toUpperCase } from '../../../utils/toUpperCase'
import { toNumeric } from '../../../utils/toNumeric'

import Moment from 'moment'

// import toast, { Toaster } from 'react-hot-toast';

// import { toast } from 'react-hot-toast';
import toastMessage from '../../../hooks/toastModel'

function TransferAnimalId() {

    const siteApi = process.env.REACT_APP_API
    const history = useNavigate();

    const { id } = useParams()

    const [token] = useState(localStorage.getItem('token') || '')
    const [lista, setLista] = useState({});
    const [isDelete, setIsDelete] = useState(false)

    const [message, setMessage] = useState({});
    const [showMessage, setShowMessage] = useState('');

    const [changePesquisa, setChangePesquisa] = useState(false);

    const [showElement, setShowElement] = useState(false)

    const [cpfTmp, setCpfTmp] = useState({})

    const [isOpen, setIsOpen] = useState({})

    const [transfer, setTransfer] = useState({})
    const [remTutor, setRemTutor] = useState({})
    const itensRef = useRef([])

    
    async function fetchData(){
        const result = await fetch(`${siteApi}/transfer/pesqanimalid` ,{
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({animalId: id}),}
        ).then((result) => result.json()).catch((err) => {
          // console.log(err)
          return err.response.data
        });
        setShowElement(false)
        setLista( result.pesqTrans )
    }



    useEffect(() => {
        fetchData()   
    },[]) 




    useEffect(() => {
        setChangePesquisa(true)   
    },[lista])




    async function declararTutor(animalId , tutorCpf){

        let msgText = ''
        let msgType = ''
        let confirme = ''

        if (tutorCpf && animalId){ 

        let cpf = tutorCpf  

        cpf = cpf.replace(/[^\d]+/g,'');	
        
        if ( cpf.length === 11 ) {



        await api.post('/transfer/declarartutor', JSON.stringify({animalId: animalId, cpf: cpf}),
                {headers: 
                {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },})
                .then((response) => {
                     confirme = response.status
                     msgText = response.data.message
                     msgType = 'success'
                     setMessage(response)

                })
                .catch((error) => {
                    if( error.response ){
                        confirme = error.response.status
                        msgText = error.response.data.message
                        msgType = 'error'

                        setMessage(error.response) // => the response payload 
                    }})

            toastMessage(msgText, msgType)

            if ( confirme === 200 ){
                setShowElement(false)
                fetchData()
            }  else {
                setCpfTmp( { ...cpfTmp, docto_tutor: null} )
            }        
          
        } 
    }
    }


    useEffect(() => {
        let linhaMessage

        if (message.status===200){
            linhaMessage = "CPF consultado com Sucesso"
        } else {
            linhaMessage = "CPF não encontrado"
        }

        setShowMessage(linhaMessage)

        setTimeout(() => {
            setShowMessage('')
        }, 3000);

    },[message]) 

    

    
    async function removerTutorId(){
        let msgText = ''
        let msgType = ''
        let confirme = ''
        
            await api.post('/transfer/removertutorid', JSON.stringify(remTutor),
                {headers: 
                {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },})
                .then((response) => {
                    confirme = response.status
                    msgText = response.data.message
                    msgType = 'success'
                }).catch((error) => {
                    confirme = error.response.status
                    msgText = error.response.data.message    //  setMessage(error.response) // => the response payload 
                    msgType = 'error'
                })
           
            toastMessage(msgText, msgType)

            if ( confirme === 200 ){
                setShowElement(false)
                setIsDelete(false)
                setIsOpen({show: 0})
                setRemTutor({})
                fetchData()
            }   
    }




useEffect(() => {
    if (isDelete){
        removerTutorId()
    } 
},[remTutor])



function CarregarDados(tutor_id){
    setRemTutor({
        dtremocao: itensRef.current[tutor_id][1].getElementsByTagName("input").dtremocao.value,
        tutor_docto: itensRef.current[tutor_id][2].getElementsByTagName("input").tutor_docto.value,
        tutor_nome: itensRef.current[tutor_id][3].getElementsByTagName("input").tutor_nome.value,
        animal_nome: itensRef.current[tutor_id][4].getElementsByTagName("input").animal_nome.value,
        animal_chip: itensRef.current[tutor_id][5].getElementsByTagName("input").animal_chip.value,
        justificativa: itensRef.current[tutor_id][6].getElementsByTagName("textarea").justificativa.value,
        tutorId: itensRef.current[tutor_id][7].getElementsByTagName("input").tutorId.value,
        animalId: itensRef.current[tutor_id][8].getElementsByTagName("input").animalId.value
    })
}


    function removerTutor(tutor_id){
        CarregarDados(tutor_id)
        setIsDelete(true)
    }



    function formRemoverTutor(tutorId) {
        setIsOpen({show: tutorId});
    }


        
    function hideModal() {
        setIsOpen({show: 0});
    };
    
    


    function gravarTutorChip(itemAnimal) {
        if ( itemAnimal > 0 ) {
            const animalId = itemAnimal
            const tutorCpf = cpfTmp.docto_tutor
            declararTutor(animalId , tutorCpf )
        }
    }



    function mostraCpf() {
        setShowElement(true)
    }



    function cancelCpf(){
        setCpfTmp( { ...cpfTmp, docto_tutor: null} )
        setShowElement(false)
    }



    function handleChangeCpfTutor(e) {
        setCpfTmp( { ...cpfTmp, [e.target.name]: cpfMask(e.target.value)} ) 
    }


    
    function handleChange(e) {
        setTransfer({ ...transfer, [e.target.name]: toUpperCase(e.target.value) })
     }

    
     function voltarTranferencia(animalId){
        history('/transferencias')
    }


    const renderContent = () => {

        if (lista){
                
             return (
                <>
                <Container className='p-4 shadow-lg text-dark'>
                    <fieldset >
                        <div  className={formStyles.form_container_Row}>
                            <Row>  
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CHIP</Col>  
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={1} ></Col>
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={8} >Nome do Animal</Col>
                            </Row>
    
                            <Row>
                                <Col className="bg-info p-1 border-bottom border-dark font-weight-bold text-center" md={3} >
                                    {lista.chip}
                                </Col>  
                                <Col className="bg-info p-1 border-bottom border-dark text-white font-weight-bold" md={1} />
                                <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={8} >
                                    {lista.nome}
                                </Col>
                            </Row>

                            <Row>
                                <Col className="bg-white p-4 font-weight-bold text-center" md={12} /> 
                            </Row>
                                    
                            <Row>
                                <Col className="bg-white p-1 font-weight-bold text-center" md={2}> 
                                    <Button  variant="outline-primary" size="small" onClick={mostraCpf}>
                                        Declarar Tutor
                                    </Button>
                                </Col>

                                { showElement ? 
                                    <>
                                    <Col className="bg-white pt-2 pb-0 font-weight-bold text-center" md={2}>CPF do Tutor:</Col>

                                    <Col className="bg-white pt-2 pb-0 font-weight-bold text-left" md={2}>
                                        <Input
                                        name="docto_tutor"
                                        type="text"
                                        placeholder="Digite o CPF do Tutor"
                                        handleOnChange={handleChangeCpfTutor}
                                        value={ cpfTmp.docto_tutor || ''}
                                        />
                                    </Col>

                                    <Col className="bg-white pt-0 pb-2 text-black font-weight-bold text-center" md={1} >
                                        <FcOk className={Styles.icon} onClick={() => gravarTutorChip( lista.id ) } />
                                    </Col>

                                    <Col className="bg-white pt-0 pb-2 text-black font-weight-bold text-center" md={1} >
                                        <FcCancel className={Styles.icon} onClick={() => cancelCpf() } />
                                    </Col>

                                    <Col className="bg-white p-1 text-black font-weight-bold text-center" md={4}>{showMessage}</Col>

                                    </>
                                    :
                                     <Col className="bg-white p-1 text-black font-weight-bold text-center" md={10} />
                                }
                            </Row>

                            <Row>  
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CPF</Col>
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={7} >Nome do Tutor</Col>
                                <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={2} >Remover</Col>
                            </Row>  

                            { (lista.tutors)?
                                <>    
                                { lista.tutors.map((itemTutor, index) => {
                                    return ([
                                        <div key={index}>
                                        <Row>
                                            <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={3} >
                                                {itemTutor.tutor_docto}
                                            </Col>
                                            <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold" md={8} >
                                                <Row>
                                                    <Col>
                                                        {itemTutor.tutor_nome}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Modal 
                                                            size="lg"
                                                            ref={ (ref) => {itensRef.current[itemTutor.tutorId] = itensRef.current[itemTutor.tutorId] || []
                                                                            itensRef.current[itemTutor.tutorId][0] = ref } } 
                                                            show={ isOpen.show === itemTutor.tutorId} 
>
                                                        <Modal.Header  className='p-3 shadow-lg text-dark' />
                                                        <Modal.Body>
                                                            <section>
                                                                <div className={Styles.lista_header} >
                                                                    <div >
                                                                        <h1>Remover Tutor</h1>
                                                                    </div>

                                                                </div>

                                                                <Container className='p-4 shadow-lg text-dark'>
                                                                <fieldset className={Styles.form_container}>
                                                                    <div  className={formStyles.form_center}>
                                                                        <label> Dados da Transferência </label>
                                                                    </div>

                                                                    <div className={formStyles.format}>
                                                                        <div className={formStyles.format70}></div>

                                                                        <div className={formStyles.format20} ref={ (ref) => { itensRef.current[itemTutor.tutorId][1] = ref }} >
                                                                            <Input
                                                                            text="Data da Remoção"
                                                                            name="dtremocao"
                                                                            type="date"
                                                                            defaultValue={Moment(transfer.dtremocao).format('YYYY-MM-DD') || ''}
                                                                            readOnly="readonly"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className={formStyles.format}>
                                                                        <div className={formStyles.format20} ref={ (ref) => { itensRef.current[itemTutor.tutorId][2] = ref }} >
                                                                            <Input
                                                                            text="CPF"
                                                                            name="tutor_docto"
                                                                            type="text"
                                                                            defaultValue={cpfMask(itemTutor.tutor_docto)}   
                                                                            readOnly="readonly" 
                                                                            />
                                                                        </div>

                                                                        <div className={formStyles.format70} ref={ (ref) => { itensRef.current[itemTutor.tutorId][3] = ref }}>
                                                                            <Input
                                                                            text="Nome Completo do Tutor"
                                                                            name="tutor_nome"
                                                                            type="text"
                                                                            defaultValue={toUpperCase(itemTutor.tutor_nome)}
                                                                            readOnly="readonly"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className={formStyles.format}> 
                                                                        <div className={formStyles.format60} ref={ (ref) => { itensRef.current[itemTutor.tutorId][4] = ref }}>
                                                                            <Input
                                                                            text="Nome Completo do Animal"
                                                                            name="animal_nome"
                                                                            type="text"
                                                                            defaultValue={toUpperCase(lista.nome)}
                                                                            readOnly="readonly"
                                                                            />
                                                                        </div>

                                                                        <div className={formStyles.format30} ref={ (ref) => { itensRef.current[itemTutor.tutorId][5] = ref }}>
                                                                            <Input
                                                                            text="Nº do CHIP"
                                                                            name="animal_chip"
                                                                            type="text"
                                                                            defaultValue={ toNumeric( lista.chip )}
                                                                            readOnly="readonly"
                                                                            />  
                                                                        </div>
                                                                    </div>

                                                                    <div className={formStyles.format100} ref={ (ref) => { itensRef.current[itemTutor.tutorId][6] = ref }}>
                                                                        <TextArea
                                                                        text="Justificativa"
                                                                        name="justificativa"
                                                                        type="text"
                                                                        placeholder="Digite a justificativa"
                                                                        maxLength="255"
                                                                        rows="4"
                                                                        handleOnChange={handleChange}
                                                                        value={toUpperCase(transfer.justificativa) || ''}
                                                                        />
                                                                    </div>

                                                                    <div className={formStyles.format}>
                                                                        <div className={formStyles.format0} ref={ (ref) => { itensRef.current[itemTutor.tutorId][7] = ref }} >
                                                                            <Input
                                                                            name="tutorId"
                                                                            type="hidden"
                                                                            defaultValue={itemTutor.tutorId}   
                                                                            readOnly="readonly" 
                                                                            />
                                                                        </div>   
                                                                        
                                                                        <div className={formStyles.format0} ref={ (ref) => { itensRef.current[itemTutor.tutorId][8] = ref }}>
                                                                            <Input
                                                                            name="animalId"
                                                                            type="hidden"
                                                                            defaultValue={lista.id}
                                                                            readOnly="readonly"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <p/>

                                                                    <div className={formStyles.format}>
                                                                        <div className={formStyles.format30}/>
                                                                            <div className={formStyles.format10}>
                                                                                <div>
                                                                                    <Button variant="primary" onClick={() => removerTutor( itemTutor.tutorId )}>Remover</Button>
                                                                                </div>
                                                                            </div>

                                                                            <div className={formStyles.format10}/>

                                                                            <div className={formStyles.format10}>
                                                                                <div>
                                                                                    <Button variant="danger" onClick={() => hideModal(0) } >Cancelar</Button>
                                                                                </div>
                                                                            </div>

                                                                        <div className={formStyles.format30}/>
                                                                    </div>

                                                                </fieldset>
                                                                </Container>
                                                            </section>

                                                        </Modal.Body>
                                                        <Modal.Footer  className='p-3 shadow-lg text-dark'/>
                                                        </Modal>

                                                    </Col>
                                                </Row>                                               
                                            </Col>
                                            <Col className="bg-white p-1 border-bottom border-dark  text-black font-weight-bold" md={1} >
                                                <FcDeleteDatabase  className={Styles.icon_grid2} onClick={() => formRemoverTutor(itemTutor.tutorId)} />
                                            </Col>

                                        </Row>
                                        </div>     
                                    ])
                                })}
                                </>
                                : 
                                <Row>
                                    <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={12} >
                                        <span>Este Animal Não Possui Tutor</span> 
                                    </Col>
                                </Row>
                            }

                        </div>
                    </fieldset>
                </Container>
                </>
            )

    }
}


return (
    <section>
        <div className={Styles.lista_header}>
            <div className={formStyles.format30}>
                <label className={formControl.form_control}>&nbsp;</label>
                <h1>Transferência de Tutor</h1>
            </div>
            <div className={formStyles.format10}>
                <Button className="mt-3" variant="outline-primary" onClick={() => { voltarTranferencia(0) }} >&nbsp;Voltar&nbsp;</Button>
            </div>
        </div>

        <p/>

        { changePesquisa ? renderContent() : null }
        
        <p/>

     </section>

    )
}

export default TransferAnimalId





//          <Toast ref={ref => (itensRef.current = [...itensRef.current, ref])} show={false}>

/*

<ToastContainer className="p-3" position={'middle-center'}>
        <Toast 
        max-width=350px
        show={true} onClose={() => setHide(itemTutor.tutorId)}>
          <Toast.Header>
            <strong className="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
</ToastContainer> 

*/

/*

<Modal
    show={true}
    onHide={() => setHide(itemTutor.tutorId)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
<Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button>Close</Button>
      </Modal.Footer>
    </Modal>   

*/

/*

    <Modal 
    size="xl"
    ref={ (ref) => {itensRef.current[itemTutor.tutorId] = ref}} 
    show={isOpen} 
>
<Modal.Header>Hi</Modal.Header>
<Modal.Body>
<section>
    <div className={Styles.lista_header} >
        <div >
            <h1>Tutores Cadastrados</h1>
        </div>
        <div className={Styles.actions}>
            <button onClick={hideModal}>Display Modal</button>
        </div>
   </div>
</section>

</Modal.Body>
<Modal.Footer>This is the footer</Modal.Footer>
</Modal>

*/
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import {
  CChartDoughnut,
  CChartPie,
} from '@coreui/react-chartjs'

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CWidgetStatsB,
} from '@coreui/react'

import { TfiReload } from "react-icons/tfi"

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'
import galeryStyles from '../../layout/estilos/Galery.module.css'

import toastMessage from './../../../hooks/toastModel'

function DashBoard(){
  // const random = () => Math.round(Math.random() * 100)
  const [token] = useState(localStorage.getItem('token') || '')
  const siteApi = process.env.REACT_APP_API

  const [numUser, setNumUser] = useState(0)

  /*  Tutores */
  const [tutors, setTutors] = useState({})
  const [numTutors, setNumTutors] = useState(0)
  const [tutorsGlobal, setTutorsGlobal] = useState([])

  const [tutorsBairro, setTutorsBairro] = useState(null)
  const [tutorsByBairro, setTutorsByBairro] = useState([])
  const [qtdTutorsBairro, setQtdTutorsBairro] = useState([])

  const [tutorsLogradouro, setTutorsLogradouro] = useState(null) 
  const [tutorsByLogradouro, setTutorsByLogradouro] = useState([])
  const [qtdTutorsLogradouro, setQtdTutorsLogradouro] = useState([])


  /* Animais */
  const [animals, setAnimals] = useState({})
  const [numAnimals, setNumAnimals] = useState(0)
  const [animalsGlobal, setAnimalsGlobal] = useState([])

  const [animalsBairro, setAnimalsBairro] = useState(null)
  const [animalsByBairro, setAnimalsByBairro] = useState([])
  const [qtdAnimalsBairro, setQtdAnimalsBairro] = useState([])

  const [animalsLogradouro, setAnimalsLogradouro] = useState(null)
  const [animalsByLogradouro, setAnimalsByLogradouro] = useState([]) 
  const [qtdAnimalsLogradouro, setQtdAnimalsLogradouro] = useState([])


  async function fetchTutores(){
      let msgType = 'success'
      let msgText = ''
      let msgError = false

      const result = await fetch(`${siteApi}/relatorios/count_tutores` ,{
            method: 'POST',
            headers: { 
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tutors),}
        )
        .then((result) => result.json())
        .catch((error) => {
          msgText = error.response.data.message
          msgType = 'error'
          msgError = true
        })
        setTutorsGlobal(result)

        if (msgError === true){
          // toastMessage(msgText, msgType)
        }
  }


  async function fetchUsuarios(){
      let msgType = 'success'
      let msgText = ''
      let msgError = false

      const result = await fetch(`${siteApi}/relatorios/count_usuarios` ,{
          method: 'POST',
          headers: { 
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
          },}
      )
    .then((result) => result.json())
    .catch((error) => {
      msgText = error.response.data.message
      msgType = 'error'
      msgError = true
    })
    setNumUser(result[0].quantidade)

    if (msgError === true){
      // toastMessage(msgText, msgType)
    }

  }


  async function fetchAnimais(){
      let msgType = 'success'
      let msgText = ''
      let msgError = true

      const result = await fetch(`${siteApi}/relatorios/count_animais` ,{
          method: 'POST',
          headers: { 
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(animals),}
      )
      .then((result) => result.json())
      .catch((error) => {
        msgText = error.response.data.message
        msgType = 'error'
        msgError = true
    })
    setAnimalsGlobal(result)

    if (msgError === true){
      // toastMessage(msgText, msgType)
    }

  }


  useEffect(() => {
    fetchUsuarios()
    fetchTutores()
    fetchAnimais()
  }, [] )


/* *********** Tutores *********** */

  useEffect(() => {
    if ( tutorsGlobal !== null && tutorsGlobal!== undefined) {
        setTutorsBairro(tutorsGlobal.bairros)
        setTutorsLogradouro(tutorsGlobal.logradouros)
    }
  }, [tutorsGlobal] )


  useEffect(() => {
    let nome = []
    let qtd = []
    let contagem = 0

    if ( tutorsBairro !== null && tutorsBairro !== undefined) {
       tutorsBairro.forEach((valor, index) => {
            nome.push(valor.bairro)
            qtd.push(valor.quantidade)
            contagem = contagem + valor.quantidade
        })
        setTutorsByBairro(nome)
        setQtdTutorsBairro(qtd)
        setNumTutors(contagem)
    }

  }, [tutorsBairro] )


  useEffect(() => {
    let nome = []
    let qtd = []
    let contagem = 0

    if ( tutorsLogradouro !== null && tutorsLogradouro !== undefined) {
        tutorsLogradouro.forEach((valor, index) =>{
            nome.push(valor.logradouro)
            qtd.push(valor.quantidade)
            contagem = contagem + valor.quantidade
        })
        setTutorsByLogradouro(nome)
        setQtdTutorsLogradouro(qtd)
        setNumTutors(contagem)
    }

  }, [tutorsLogradouro] )

/* *********** Fim Tutores *********** */



/* *********** Animais *********** */

  useEffect(() => {
    if ( animalsGlobal !== null && animalsGlobal !== undefined) {
        setAnimalsBairro(animalsGlobal.bairros)
        setAnimalsLogradouro(animalsGlobal.logradouros)
    }
  }, [animalsGlobal] )



  useEffect(() => {
    let nome = []
    let qtd = []
    let contagem = 0

    if ( animalsBairro !== null && animalsBairro !== undefined) {
       animalsBairro.forEach((valor, index) =>{
            nome.push(valor.bairro)
            qtd.push(valor.quantidade)
            contagem = contagem + valor.quantidade
        })
        setAnimalsByBairro(nome)
        setQtdAnimalsBairro(qtd)
        setNumAnimals(contagem)
    }

  }, [animalsBairro] )


  useEffect(() => {
    let nome = []
    let qtd = []
    let contagem = 0

    if ( animalsLogradouro !== null && animalsLogradouro !== undefined) {
        animalsLogradouro.forEach((valor, index) =>{
            nome.push(valor.logradouro)
            qtd.push(valor.quantidade)
            contagem = contagem + valor.quantidade
        })
        setAnimalsByLogradouro(nome)
        setQtdAnimalsLogradouro(qtd)
        setNumAnimals(contagem)
    }

  }, [animalsLogradouro] )


/* *********** Fim Animais *********** */

function PesqTutorClick( element ){
    setTutors({bairro: tutorsByBairro[element[0].index]})
}

useEffect(() => {
    fetchTutores()
}, [tutors] )


function PesqAnimalsClick( element ){
  setAnimals({bairro: animalsByBairro[element[0].index]})
}

useEffect(() => {
  fetchAnimais()
}, [animals] )

function reload(){
  setTutors({})
  setAnimals({})
}

  return (
    <section  className='p-0 shadow-lg'>
    <div className={galeryStyles.container}>

    <div className={Styles.lista_header} >
        <div className={formStyles.format60}>
          <h1>Relatórios - Visão Geral</h1>
        </div>
        <div className={Styles.actions}>
        <TfiReload className={Styles.icon_grid2} onClick={() => { reload() }}
                        data-tip="Editar"
                        data-for="btn_Editar" />
        </div>
    </div>
<CRow>

<CCol xs={4}>
  <CWidgetStatsB
    className="mb-3"
    color="warning"
    progress={{ color: 'success', value: 50 }}
    text="Quantidade de Usuários Cadastrados"
    title="Usuários"
    value={numUser}
  />
</CCol>

<CCol xs={4}>
  <CWidgetStatsB
    className="mb-3"
    color="info"
    inverse
    progress={{ value: 50 }}
    text="Quantidade de Tutores Cadastrados"
    title="Tutores"
    value={numTutors}
  />
</CCol>

<CCol sm={4}>
  <CWidgetStatsB
    className="mb-3"
    color="danger"
    progress={{ value: 50 }}
    text="Quantidade de Animais Cadastrados"
    title="Animais"
    value={numAnimals}
  />
</CCol>

</CRow>



<CRow>
      <CCol xs={6}>
        <CCard className="mb-4">
          <CCardHeader>Tutores por Bairro</CCardHeader>
          <CCardBody>
            <CChartDoughnut
              data={{
                labels: tutorsByBairro, 
                datasets: [
                  {
                    backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#00FF00', '#800080', '#0000FF'],
                    data: qtdTutorsBairro,
                  },
                ],
              }}

              options={{
                  onClick: function(evt, element) {
                      // onClickNot working element null
                      // console.log(evt, element);
                      if (element.length > 0) {
                          PesqTutorClick(element);
                      }
                  }}
              }              
              redraw={false}
            />
          </CCardBody>
        </CCard>
      </CCol>


      <CCol xs={6}>
        <CCard className="mb-4">
          <CCardHeader>Tutores por Logradouro</CCardHeader>
          <CCardBody>
            <CChartPie
              data={{
                labels: tutorsByLogradouro,
                datasets: [
                  {
                    data: qtdTutorsLogradouro,
                    backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#FF8C00', '#FFFF00', '#00FF00', '#800080', '#0000FF'],
                  },
                ],
              }}
              redraw={false}
            />
          </CCardBody>
        </CCard>
      </CCol>
      </CRow>


      <CRow>
      <CCol xs={6}>
        <CCard className="mb-4">
          <CCardHeader>Animais por Bairro</CCardHeader>
          <CCardBody>
            <CChartDoughnut
              data={{
                labels: animalsByBairro, 
                datasets: [
                  {
                    backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#008000', '#36A2EB', '#00FF00', '#800080', '#0000FF'],
                    data: qtdAnimalsBairro,
                  },
                ],
              }}
              options={{
                onClick: function(evt, element) {
                    // onClickNot working element null
                    // console.log(evt, element);
                    if (element.length > 0) {
                        PesqAnimalsClick(element);
                    }
                }}
              }
              redraw={false}
            />
          </CCardBody>
        </CCard>
      </CCol>


      <CCol xs={6}>
        <CCard className="mb-4">
          <CCardHeader>Animais por Logradouro</CCardHeader>
          <CCardBody>
            <CChartPie
              data={{
                labels: animalsByLogradouro,
                datasets: [
                  {
                    data: qtdAnimalsLogradouro,
                    backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB','#FF8C00', '#FFFF00', '#00FF00', '#800080', '#0000FF'],
                  },
                ],
              }}
              redraw={false}
            />
          </CCardBody>
        </CCard>
      </CCol>
      </CRow>


       </div>
    </section>
  )
}

export default DashBoard

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
// import { useRef } from 'react'
// import { useMemo } from 'react'

// import Alert from 'react-bootstrap/Alert'
import toastMessage from './../../../hooks/toastModel'
import { useNavigate } from 'react-router-dom'

import api from '../../../utils/Api'

import Input from '../../formularios/Input'
import Select from '../../formularios/Select'
import { toUpperCase } from '../../../utils/toUpperCase'

import Styles from '../../layout/estilos/Dashboard.module.css'
import formStyles from '../../formularios/FormPaginas.module.css'
import formControl from '../../formularios/Input.module.css'

import { Button } from 'react-bootstrap'  

import ReactPaginate from 'react-paginate'

import PesqTutorCpf  from '../tutores/PesqTutorCpf'
import PesqTutorNome  from '../tutores/PesqTutorNome'
import PesqTutorEmail  from '../tutores/PesqTutorEmail'
import PesqTutorLogradouro  from '../tutores/PesqTutorLogradouro'
import PesqTutorBairro  from '../tutores/PesqTutorBairro'

function ListarTutores() {
    const siteApi = process.env.REACT_APP_API

    const [token] = useState(localStorage.getItem('token') || '')
    const [savedPesqTutor, setSavedPesqTutor] = useState( JSON.parse(localStorage.getItem('savePesqTutor')) || {})
    const [reloadPesq, setReloadPesq]= useState(false)

    const [tutorForm, setTutorForm] = useState({})
    const [lista, setLista] = useState({});

    const pesqOpcoes = [
    {value:'CPF', label:'CPF'},
    {value:'NOME',label:'NOME'},
    {value:'EMAIL',label:'EMAIL'},
    {value:'LOGRADOURO',label:'LOGRADOURO'},
    {value:'BAIRRO',label:'BAIRRO'},
    ]

    const [pesquisa, setPesquisa] = useState(null);
    const [showElement, setShowElement] = useState(true)

    const history = useNavigate();



    /* **************************** */
    
    let pageCount = 0
    let offset = 1
    let currentPageData = []

    const PER_PAGE = 18;

    const [currentPage, setCurrentPage] = useState(0)

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    /* **************************** */   


    async function fetchData(){
        let msgText = 'Pesquisa feita com sucesso'
        let msgType = 'success'
        let msgError = false

        if (tutorForm.opcao && tutorForm.assunto){
            const result = await fetch(`${siteApi}/tutores/`,{
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${JSON.parse(token)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(tutorForm),
            })
            .then((result) => result.json(),)
            .then( setSavedPesqTutor(tutorForm) ) 
            .catch((error) => {
                msgText = error.response.data.message
                msgType = 'error'
                msgError = true
            })
            setLista( result.tutor )

            if (msgError === true){
                toastMessage(msgText, msgType)
            }
        }
    }


    useEffect(() => {

        localStorage.setItem('savePesqTutor', JSON.stringify(savedPesqTutor))

        if ( savedPesqTutor && savedPesqTutor.assunto && savedPesqTutor.assunto !==  tutorForm.assunto ){
            setTutorForm( savedPesqTutor )
            setReloadPesq(true)
        } 

    }, [savedPesqTutor] )



    useEffect(() => {
        if (reloadPesq){
            fetchData()
        }
    }, [reloadPesq] )



    async function excluirTutorId( id ){
        let msgType = 'success'
        let msgText = ''
        let msgError = false

        await api.post('/tutores/remover', JSON.stringify({id: id}),{
          headers: {
          'Authorization': `Bearer ${JSON.parse(token)}`,
          'Content-Type': 'application/json',
          },
        })
        .then((response) => {
            msgText = response.data.message
            msgError = true
            const updatedTutores = lista.filter((lista) => lista.id !== id)
            setLista( updatedTutores )
        })
        .catch((error) => {
                msgText = error.response.data.message
                msgType = 'error'
                msgError = true
        })

        if (msgError !== null){
            toastMessage(msgText, msgType)
        }

    }



    function editarTutorId( tutorId ){
        history(`/tutores/editar/${tutorId}`)
    }



    useEffect(() => {
        setLista([])
    }, [tutorForm.opcao] )




    useEffect(() => {
        fetchData()
    }, [pesquisa] )



    useEffect(() => {
        renderContent()
    }, [ lista ] )


    function tutorPesquisar(){
        setPesquisa(!pesquisa)   
    }



    function showOrHide(){
        setShowElement(!showElement)
    }



    function adicionarTutor() {
        history('/tutores/cadastro')
    }



    function handleChange(e) {
        setTutorForm({ ...tutorForm, [e.target.name]: toUpperCase( e.target.value ) })
    }



    function handlePesquisa(e) {
        // setTutorForm({...tutorForm, opcao: e.target.options[e.target.selectedIndex].value, })
        setTutorForm( tutorForm => {
            return {...tutorForm, ...{opcao: e.target.options[e.target.selectedIndex].value, assunto: ' '}}
        })

    }


    function  renderContent() {

        if (lista && lista.length > 0){
            offset = currentPage * PER_PAGE
            currentPageData = lista.slice(offset, offset + PER_PAGE)
            pageCount = Math.ceil(lista.length / PER_PAGE)
        } 

    
        switch (tutorForm.opcao) {
        case 'CPF':
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorCpf 
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}
                        
                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        case 'NOME':
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorNome 
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}
                        
                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        case 'EMAIL':
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorEmail 
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}
                        
                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        case 'LOGRADOURO':
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorLogradouro
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}
                        
                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        case 'BAIRRO':
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorBairro 
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                                        
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}
                        
                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        default:
            return (
                <section className='p-2 shadow-lg'>
                    <div>
                    <PesqTutorNome 
                        showOrHidePesq={showOrHide}  
                        adicionarTutorPesq={adicionarTutor} 
                        editarTutorPesq={editarTutorId} 
                        excluirTutorPesq={excluirTutorId} 
                        listaData={currentPageData} 
                    />
                    </div>
                    <p/>
                    <div>
                    <ReactPaginate
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        initialPage={0}

                        containerClassName="pagination"

                        previousLabel="← Anterior"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"

                        nextLabel="Próxima →"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"

                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"

                        pageClassName="page-item"
                        pageLinkClassName="page-link"

                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                    />
                    </div>
                </section>
            )

        }

    }


return (

    <section>
        <div className={Styles.lista_header} >
            <div className={formStyles.format30}>
                <label className={formControl.form_control}>&nbsp;</label>
                <h1>Tutores Cadastrados</h1>
            </div>

            { showElement ? 
                <div className={formStyles.format60}>
                        <div className={formStyles.format}>
                            <div className={formStyles.format30}>
                                <Select
                                    text="Campo Para Pesquisa"
                                    name="pesquisa"
                                    options={pesqOpcoes}
                                    handleOnChange={handlePesquisa}
                                    value={tutorForm.opcao || ''}
                                />  
                            </div>

                            <div className={formStyles.format40}>
                                <Input
                                    text="Digite o Valor a ser Pesquisado"
                                    name="assunto"
                                    type="text"
                                    placeholder="Digite o Valor a ser Pesquisado"
                                    handleOnChange={handleChange}
                                    value={tutorForm.assunto || ''}
                                />
                            </div>

                            <div className={formStyles.format10}>
                                <Button variant="outline-danger" onClick={() => tutorPesquisar() } >Pesquisar</Button>
                            </div>
                        </div>

                </div>
            : null }
            <div className={formStyles.format10} />
        </div>

        <>

        { renderContent() }

        </>

  </section>

  )

}

export default ListarTutores


/*



*/

/*
    const renderNull = () => {
    return (
        <Container className='p-2 shadow-lg'> 
            <Row className="bg-secondary p-0 text-white font-weight-bold">
                <div className={Styles.lista_clear}>
                    <div className={formStyles.format90}>
                        <label>&nbsp;</label>
                    </div>

                    <div className={formStyles.format05}>
                        <BsSearch onClick={showOrHide} className={Styles.icon_grid2}
                        data-tip="Pesquisar"
                        data-for="btn_Pesquisa" />
                        <ReactTooltip id="btn_Pesquisa" type="warning" effect="solid" borderColor="black" />
                    </div>

                    <div className={formStyles.format05}>
                        <BsPlusCircle onClick={adicionarTutor} className={Styles.icon_grid2}
                        data-tip="Cadastrar"
                        data-for="btn_Cadastro" />
                        <ReactTooltip id="btn_Cadastro" type="error" effect="solid" borderColor="black" />
                    </div>
                </div>
            </Row>
            <Row className="bg-white p-0 text-black font-weight-bold"> 
                <div className={Styles.lista_row}>

                    <div className={formStyles.format30}>
                        <label>&nbsp;</label>
                    </div>
                    <div className={formStyles.format30}>
                        <label>&nbsp;</label>
                    </div>
                    <div className={formStyles.format10}>
                        <label>&nbsp;</label>
                    </div>
                    <div className={formStyles.format20}>
                        <label>&nbsp;</label>
                    </div>
                </div>
            </Row>
            <Row className="bg-white p-0 text-black font-weight-bold"> 
                <div className={Styles.lista_row}>
                    <div className={formStyles.format100}>
                        <label>&nbsp;</label>
                    </div>
                </div>
            </Row>
            <Row className="bg-white p-0 text-black font-weight-bold"> 
                <div className={Styles.lista_row}>
                    <div className={formStyles.format100}>
                        <label>&nbsp;</label>
                    </div>
                </div>
            </Row>
            <Row>
                <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                    <Alert variant='danger'>Faça uma Opção de Pesquisa de Tutor antes de prosseguir</Alert>
                </Col>  
            </Row>
        </Container>
    )
    }
*/


import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import api from '../../../utils/Api'

import Input from '../../formularios/Input'
import Select from '../../formularios/Select'
import { toUpperCase } from '../../../utils/toUpperCase'

import Styles from '../../layout/estilos/Dashboard.module.css'
import formStyles from '../../formularios/FormPaginas.module.css'
import formControl from '../../formularios/Input.module.css'

import {Button} from 'react-bootstrap'  

import ReactPaginate from 'react-paginate'

import toastMessage from './../../../hooks/toastModel'

import PesqAnimalChip  from '../animais/PesqAnimalChip'
import PesqAnimalNome  from '../animais/PesqAnimalNome'
import PesqAnimalTutor  from '../animais/PesqAnimalTutor'
import PesqAnimalLogradouro  from '../animais/PesqAnimalLogradouro'
import PesqAnimalBairro  from '../animais/PesqAnimalBairro'


function ListarAnimais() {

  const siteApi = process.env.REACT_APP_API
  const [token] = useState(localStorage.getItem('token') || '')
  const [savedPesqAnimal, setSavedPesqAnimal] = useState( JSON.parse(localStorage.getItem('savePesqAnimal')) || {})
  const [reloadPesq, setReloadPesq]= useState(false)
  
  const history = useNavigate()

  const [lista, setLista] = useState({})

  const [animalForm, setAnimalForm] = useState({})

  const [pesquisa, setPesquisa] = useState(true)
  const [showElement, setShowElement] = useState(true)

  const pesqOpcoes = [
    {value:'CHIP', label:'CHIP'},
    {value:'NOME',label:'NOME'},
    {value:'LOGRADOURO',label:'LOGRADOURO'},
    {value:'BAIRRO',label:'BAIRRO'},
    {value:'TUTOR',label:'TUTOR'},
    ]

    /* **************************** */

    let pageCount = 0
    let offset = 1
    let currentPageData = []

    const PER_PAGE = 18;

    const [currentPage, setCurrentPage] = useState(0)

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    /* **************************** */  

    
    async function fetchData(){

        let msgType = 'success'
        let msgText = ''
        let msgError = false

        if (animalForm.opcao && animalForm.assunto){
            const result = await fetch(`${siteApi}/animais/` ,{
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${JSON.parse(token)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(animalForm),}
            )
            .then((result) => result.json())
            .then( setSavedPesqAnimal(animalForm) ) 
            .catch((error) => {
                console.log(error)
                msgType = 'error'
                // return error.response.data
                msgText = error.response.data.message
                msgError = true
            });
            setLista( result.animal );
        }

        if (msgError === true){
            toastMessage(msgText, msgType)
        }
    }


  async function fetchHistoric( animalId ){
    let msgType = 'success'
    let msgText = ''
    let msgError = false

    const result = await fetch(`${siteApi}/animais/historico` ,{
      method: 'POST',
      headers: { 
          'Authorization': `Bearer ${JSON.parse(token)}`,
          'Content-Type': 'application/json',
       },
       body: JSON.stringify({animalId: animalId}),}
    )
    .then((result) => result.json())
    .catch((error) => {
                console.log(error)
                msgType = 'error'
                // return error.response.data
                msgText = error.response.data.message
                msgError = true
    });
    // setHistoric( result.histAnimal );
    if (msgError === true){
        toastMessage(msgText, msgType)
    }
    return result
  }



    useEffect(() => {

        localStorage.setItem('savePesqAnimal', JSON.stringify(savedPesqAnimal))

        if ( savedPesqAnimal && savedPesqAnimal.assunto && savedPesqAnimal.assunto !==  animalForm.assunto ){
            setAnimalForm( savedPesqAnimal )
            setReloadPesq(true)
        } 

    }, [savedPesqAnimal] )



    useEffect(() => {
        if (reloadPesq){
            fetchData()
        }
    }, [reloadPesq] )



    useEffect(() => {
        setLista([])
    }, [animalForm.opcao] )



    async function excluirAnimalId( id ){
    let msgType = 'success'
    let msgText = ''

    await api.post('/animais/remover', JSON.stringify({id: id}),{
        headers: {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json',
        },
        })
        .then((response) => {
            const updatedAnimais = lista.filter((lista) => lista.id !== id)
            setLista( updatedAnimais )
            msgText = response.data.message
          // return response.data
        })
      .catch((err) => {
            msgText = err.response.data.message
            msgType = 'error'
            // return err.response.data
        })

        toastMessage(msgText, msgType)
    
    }



    useEffect(() => {
        fetchData()
    }, [pesquisa] )




    useEffect(() => {
        setLista([])
    }, [] )



    function animalPesquisar(){
        setPesquisa(!pesquisa)   
    }


  
    function handleChange(e) {
        setAnimalForm({ ...animalForm, [e.target.name]: toUpperCase( e.target.value ) })


    } 



    function handlePesquisa(e) {
        // setAnimalForm({...animalForm, opcao: e.target.options[e.target.selectedIndex].value })
        setAnimalForm( animalForm => {
            return {...animalForm, ...{opcao: e.target.options[e.target.selectedIndex].value, assunto: ' '}}
        })

    }



    function editarAnimalId( animalId ){
        history(`/animais/editar/${animalId}`)
    }



    function adicionarAnimal(){
        history('/animais/cadastro/')
    }



    function galeriaAnimal( animalId ){
        history(`/animais/galeria/${animalId}`)
    }


 
    function showOrHide(){
        setShowElement( !showElement)
    }
 

    function  renderContent() {

        if (lista && lista.length > 0){
            offset = currentPage * PER_PAGE
            currentPageData = lista.slice(offset, offset + PER_PAGE)
            pageCount = Math.ceil(lista.length / PER_PAGE)
        } 

        if (animalForm.opcao === 'Selecione'){
            animalForm.assunto = ''
        }

        switch (animalForm.opcao) {
            case 'CHIP':
                return (
                    <section className='p-2 shadow-lg'>
                        <div>
                        <PesqAnimalChip
                            adicionarAnimalPesq={adicionarAnimal} 
                            editarAnimalPesq={editarAnimalId} 
                            excluirAnimalPesq={excluirAnimalId} 
                            historicAnimalPesq={fetchHistoric}
                            galeriaAnimalPesq={galeriaAnimal}
                            showOrHidePesq={showOrHide}
                            listaData={currentPageData} 
                        />
                        </div>
                        <p/>
                        <div>
                        <ReactPaginate
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            initialPage={0}
                            
                            containerClassName="pagination"
    
                            previousLabel="← Anterior"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
    
                            nextLabel="Próxima →"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
    
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
    
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
    
                            disabledClassName="pagination__link--disabled"
                            activeClassName="pagination__link--active"
                        />
                        </div>
                    </section>
                )

            case 'TUTOR':
                return (
                    <section className='p-2 shadow-lg'>
                        <div>
                            <PesqAnimalTutor
                                adicionarAnimalPesq={adicionarAnimal} 
                                editarAnimalPesq={editarAnimalId} 
                                excluirAnimalPesq={excluirAnimalId} 
                                historicAnimalPesq={fetchHistoric}
                                galeriaAnimalPesq={galeriaAnimal}
                                showOrHidePesq={showOrHide}
                                listaData={currentPageData} 
                            />
                        </div>
                        <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
                                
                                containerClassName="pagination"
        
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
        
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
        
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
        
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
        
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </section>
                )

            case 'LOGRADOURO':
                return (
                    <section className='p-2 shadow-lg'>
                         <div>
                            <PesqAnimalLogradouro
                                 adicionarAnimalPesq={adicionarAnimal} 
                                editarAnimalPesq={editarAnimalId} 
                                excluirAnimalPesq={excluirAnimalId} 
                                historicAnimalPesq={fetchHistoric}
                                galeriaAnimalPesq={galeriaAnimal}
                                showOrHidePesq={showOrHide}
                                listaData={currentPageData} 
                            />
                        </div>
                        <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
                                    
                                containerClassName="pagination"
            
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
            
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
            
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
            
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
            
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </section>
                )

            case 'BAIRRO':
                return (
                    <section className='p-2 shadow-lg'>
                        <div>
                            <PesqAnimalBairro
                                adicionarAnimalPesq={adicionarAnimal} 
                                editarAnimalPesq={editarAnimalId} 
                                excluirAnimalPesq={excluirAnimalId} 
                                historicAnimalPesq={fetchHistoric}
                                galeriaAnimalPesq={galeriaAnimal}
                                showOrHidePesq={showOrHide}
                                listaData={currentPageData} 
                            />
                        </div>
                        <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
                                        
                                containerClassName="pagination"
                
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </section>
                )

                 case 'NOME':
                    return (
                        <section className='p-2 shadow-lg'>
                            <div>
                                <PesqAnimalNome
                                    adicionarAnimalPesq={adicionarAnimal} 
                                    editarAnimalPesq={editarAnimalId} 
                                    excluirAnimalPesq={excluirAnimalId} 
                                    historicAnimalPesq={fetchHistoric}
                                    galeriaAnimalPesq={galeriaAnimal}
                                    showOrHidePesq={showOrHide}
                                    listaData={currentPageData} 
                                />
                            </div>
                            <p/>
                            <div>
                                <ReactPaginate
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    initialPage={0}
            
                                    containerClassName="pagination"
            
                                    previousLabel="← Anterior"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
            
                                    nextLabel="Próxima →"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
            
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
            
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
            
                                    disabledClassName="pagination__link--disabled"
                                    activeClassName="pagination__link--active"
                                />
                            </div>
                        </section>
                    )

            case 'Selecione':
                return (
                    <section className='p-2 shadow-lg'>
                        <div>
                            <PesqAnimalNome
                                adicionarAnimalPesq={adicionarAnimal} 
                                editarAnimalPesq={editarAnimalId} 
                                excluirAnimalPesq={excluirAnimalId} 
                                historicAnimalPesq={fetchHistoric}
                                galeriaAnimalPesq={galeriaAnimal}
                                showOrHidePesq={showOrHide}
                                listaData={currentPageData} 
                            />
                        </div>
                        <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
        
                                containerClassName="pagination"
        
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
        
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
        
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
        
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
        
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </section>
                )
                   
            default:
                return (
                    <section className='p-2 shadow-lg'>
                        <div>
                            <PesqAnimalNome
                                adicionarAnimalPesq={adicionarAnimal} 
                                editarAnimalPesq={editarAnimalId} 
                                excluirAnimalPesq={excluirAnimalId} 
                                historicAnimalPesq={fetchHistoric}
                                galeriaAnimalPesq={galeriaAnimal}
                                showOrHidePesq={showOrHide}
                                listaData={[]} 
                            />
                        </div>
                        <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
            
                                containerClassName="pagination"
            
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
            
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
            
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
            
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
            
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </section>
                )

        }
    }



return (
    
  <section>
      <div className={Styles.lista_header} >
            <div className={formStyles.format30}>
                <label className={formControl.form_control}>&nbsp;</label>
                <h1>Animais Cadastrados</h1>
            </div>

            { showElement ?  
                <div className={formStyles.format60}>
                    <div className={formStyles.format}>
                        <div className={formStyles.format30}>
                            <Select
                                text="Campo Para Pesquisa"
                                name="pesquisa"
                                options={pesqOpcoes}
                                handleOnChange={handlePesquisa}
                                value={animalForm.opcao || ''}
                            />  
                        </div>

                        <div className={formStyles.format40}>
                            <Input
                                text="Digite o Valor a ser Pesquisado"
                                name="assunto"
                                type="text"
                                placeholder="Digite o Valor a ser Pesquisado"
                                handleOnChange={handleChange}
                                value={toUpperCase(animalForm.assunto) || ''}
                            />
                        </div>

                        <div className={formStyles.format10}>
                            <Button variant="outline-danger" onClick={() => animalPesquisar() } >Pesquisar</Button>
                        </div>
                    </div>
                </div>
            : null  }
                <div className={formStyles.format10} />
        </div>
        <>

        { renderContent() }

        </>

  </section>

  )

}

export default ListarAnimais



/*


        
        <Container className='p-2 shadow-lg'> 
            <Row  className="bg-secondary p-0 text-white font-weight-bold">  
                <div className={Styles.lista_clear}>
                    <div className={formStyles.format05} />

                    <div className={formStyles.format10}>
                        Nº CHIP
                    </div>
                    <div className={formStyles.format05} />

                    <div className={formStyles.format70}>
                         Nome do Animal
                    </div>

                    <div className={formStyles.format05}>
                        <BsSearch onClick={showOrHide} className={Styles.icon_grid2}
                        data-tip="Pesquisar"
                        data-for="btn_Pesquisa" />
                        <ReactTooltip id="btn_Pesquisa" type="warning" effect="solid" borderColor="black" />
                    </div>
                    <div className={formStyles.format05}>
                        <BsPlusCircle onClick={cadastrarAnimal} className={Styles.icon_grid2}
                        data-tip="Cadastrar"
                        data-for="btn_Cadastro" />
                        <ReactTooltip id="btn_Cadastro" type="error" effect="solid" borderColor="black" />
                    </div>
                </div>
          </Row>

      <>   
      {
      lista.length > 0 &&
      lista.map((item, index) => {
      return (
          <Row key={index}  className="bg-white p-0 text-black font-weight-bold">  
              <div key={item.id}  className={Styles.lista_row} >       
                  <div className={formStyles.format05} />
            
                  <div className={formStyles.format10}>
                      <span className={Styles.linkCol}>{item.chip}</span>
                  </div>

                  <div className={formStyles.format05}>
                      <span className="bold"> </span>
                  </div>

                  <div className={formStyles.format60}>
                      <span className={Styles.linkCol}>{item.nome}</span>
                  </div>

                  <div>

                  <Modal  ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                      itensRef.current[item.id][0] = ref } } 
                      show={ isOpen.show === item.id}
                      onHide={() => hideModal(0) }
                      className='p-1 shadow-lg text-dark'
                      >

                      <Modal.Header closeButton>
                          <Modal.Title>
                              Exclusão de Animal
                          </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                          <Container>
                              <div className={formStyles.form_textcenter}>
                                  <span>Confirma a Exclusão de: {item.nome} ?</span>
                              </div>
                          </Container>
                      </Modal.Body>

                      <Modal.Footer>
                          <Container>
                              <div className={formStyles.format}>
                                  <div className={formStyles.format30}/>
                                  <div className={formStyles.format20}>
                                      <Button variant="outline-primary" onClick={() => excluirAnimalId( item.id )}>&nbsp;Excluir&nbsp;</Button>
                                  </div>
                                  <div className={formStyles.format10}/>
                                  <div className={formStyles.format20}>
                                      <Button variant="outline-danger" onClick={() => hideModal(0) } >Cancelar</Button>
                                  </div>
                                  <div className={formStyles.format30}/>
                              </div>
                          </Container>
                      </Modal.Footer>
                  </Modal>

                  <Modal  ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                      itensRef.current[item.id][1] = ref } } 
                      show={ isHistoric.show === item.id} 
                      onHide={() => hideModalHistoric(0)} 
                      size="lg"
                      className='p-4 shadow-lg text-dark'
                      >

                      <Modal.Header closeButton>
                          <Modal.Title>
                              Histórico de Movimentação: {item.nome}
                          </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                          <Container>
                              <Row  className="bg-secondary p-2 text-white font-weight-bold">      
                                  <div  className={Styles.lista_clear}>
                                      <div className={formStyles.format05} />
                                      <div className={formStyles.format10}>
                                          <span className="text-center">Data</span>
                                      </div>
                                      <div className={formStyles.format05}/>
                                      <div className={formStyles.format60}>
                                          <span className="text-center">Movimento</span>
                                      </div>
                                      <div className={formStyles.format20}>
                                          <span className="text-center">Usuário</span>
                                      </div>
                                  </div>
                              </Row>

                              {

                              historic &&
                              historic.map((item, index) => {
                              return (
                                  <Row key={index} className="bg-white p-0 text-black font-weight-bold">
                                      <div  key={item.id} className={Styles.lista_clear}>
                                          <div className={formStyles.format05} />
                                          <div className={formStyles.format10}>
                                              <span className={Styles.linkCol}>{Moment( item.dt_action ).format('DD-MM-YYYY')}</span>
                                          </div>
          
                                          <div className={formStyles.format05}>
                                              <span className="bold"> </span>
                                          </div>
          
                                          <div className={formStyles.format60}>
                                              <span className={Styles.linkCol}>{item.action}</span>
                                          </div>

                                          <div className={formStyles.format20}>
                                              <span className={Styles.linkCol}>{item.userUsuario}</span>
                                          </div>

                                      </div>
                                  </Row>
                                )}
                              )}

                              <Row  className="bg-white p-0 text-white font-weight-bold">&nbsp;</Row>
                              <Row  className="bg-secondary p-1 text-white font-weight-bold" />
                          </Container>
                      </Modal.Body>

                      <Modal.Footer>
                          <Button variant="outline-danger" onClick={() => hideModalHistoric(0)} >&nbsp;Fechar&nbsp;</Button>
                      </Modal.Footer>
                  </Modal>

                  </div>

                  <div className={Styles.actions}>

                        <BiEdit  className={Styles.icon_grid2} onClick={() => { editarAnimal( item.id ) }} 
                        data-tip="Editar"
                        data-for="btn_Editar" />
                        <ReactTooltip id="btn_Editar" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiCamera className={Styles.icon_grid2} onClick={() => { galeriaAnimal( item.id ) }} 
                        data-tip="Galeria"
                        data-for="btn_Galeria" />
                        <ReactTooltip id="btn_Galeria" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiListUl className={Styles.icon_grid2} onClick={() => { showHistoric( item.id ) }} 
                        data-tip="Histórico"
                        data-for="btn_Historico" />
                        <ReactTooltip id="btn_Historico" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiTrash className={Styles.icon_grid2} onClick={() => { excluirAnimal( item.id ) }}
                        data-tip="Excluir"
                        data-for="btn_Excluir" />
                        <ReactTooltip id="btn_Excluir" type="error" effect="solid" borderColor="black" />

                        </div>
              </div>
          </Row>
      )})

      }
      </>

      <>
      {  
          lista.length === 0 && <p>Ainda não há Animais cadastrados!</p> 
      }
      </>

      </Container>


    */

/*

    <div>
        <div>
        <a ref={ (ref) => { toolTipRef.current[0] = toolTipRef.current[0] || []
                        toolTipRef.current[0] = ref } } 
          data-tip
          data-for="sad_Face10"
        > Teste 10</a>
        
        <ReactTooltip id="sad_Face10" type="warning" effect="solid">
            <span>Alerta 10</span>
        </ReactTooltip>

        </div>
    </div>


    <div>
        <div>
            <a data-tip data-for="sad_Face2"> Teste 2 </a>
            <ReactTooltip id="sad_Face2" type="warning" effect="solid">
                <span>Alerta 2</span>
            </ReactTooltip>
        </div>
    </div>
    

    <div>
        <div>
            <a data-tip data-for="sad_Face3"> Teste 3 </a>
            <ReactTooltip id="sad_Face3" type="dark" effect="solid">
                <span>Alerta 3</span>
            </ReactTooltip>
        </div>
    </div>

*/

/*

                  <button className={Styles.success_btn} onClick={() => { galeriaAnimal(item.id) }} > Galeria </button>
                  <button className={Styles.exclude_btn} onClick={() => { excluirAnimal(item.id) }} > Excluir </button>

*/

/*

    <div>
        <p ref={ (ref) => { toolTipRef.current[0] = toolTipRef.current[0] || []
                        toolTipRef.current[0] = ref } } 

          data-tip="hover on me will keep the tooltip"
          data-for="exemplo"
        />
    </div>

*/

/*
                onMouseEnter={() => { showToolTip( item.id ) }}
                onMouseLeave={() => { hideToolTip( item.id ) }}
*/

/*
                  <Col className="bg-secondary p-2 text-white font-weight-bold text-center" md={1} /> 
                  <Col className="bg-secondary p-2 text-white font-weight-bold text-center" md={1} >Nº CHIP</Col>  
                  <Col className="bg-secondary p-2" md={1}></Col>  
                  <Col className="bg-secondary p-2 text-white font-weight-bold" md={7} >Nome do Animal</Col>
                  <Col className="bg-secondary p-2"></Col> 
*/


/*
<Link to={`/animais/editar/${item.id}`}>Editar</Link>
<Link to={`/animais/galeria/${item.id}`} className={Styles.galery_btn }>Galeria</Link>


    <div className={Styles.lista_container}>
        <div className={formStyles.format}>
            <div className={formStyles.format10}>
                  <span className="bold">CHIP</span>
            </div>
            <div className={formStyles.format10}>
                  <span className="bold"> </span>
            </div>
            <div className={formStyles.format50}>
                  <span className="bold">NOME</span>
            </div>
    </div>

    */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Moment from 'moment'
import { useNavigate } from 'react-router-dom'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'

import {Container} from 'react-bootstrap'

import Input from '../../formularios/Input'
import InputMask from '../../formularios/InputMask'
import Select from '../../formularios/Select'
import TextArea from '../../formularios/TextArea'

import { cpfMask } from '../../../utils/cpfMask'
import { cepMask } from '../../../utils/cepMask'
import { celularMask } from '../../../utils/celularMask'
import { telefoneMask } from '../../../utils/telefoneMask'
import { toUpperCase } from '../../../utils/toUpperCase'
import { toNumeric } from '../../../utils/toNumeric'
import  ConvertToUpper  from '../../../utils/ConvertToUpper'

import Button from '@mui/material/Button';

/// import {Container , Button, Row, Col} from 'react-bootstrap'

// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { FcQuestions } from "react-icons/fc"
// import { VscTrash } from "react-icons/vsc"
import { FcCheckmark } from "react-icons/fc"

import { DataGrid } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { BsSearch } from "react-icons/bs"
import { BsXSquare } from "react-icons/bs"
import { BsCameraFill } from "react-icons/bs"

import { Icon } from '@iconify/react';
// import questionMarkRounded from '@iconify/icons-material-symbols/question-mark-rounded'
import questionCircleFill from '@iconify/icons-bi/question-circle-fill';


function TutorForm({ handleSubmit, tutorData, btnText }) {
    const siteApi = process.env.REACT_APP_API

    const history = useNavigate();

    const [tutor, setTutor] = useState(tutorData || {})

    const [ceplog, setCepLog] = useState([])
    const [ceplogTmp1, setCepLogTmp1] = useState([])

    const [linTmp1, setLinTmp1] = useState([]) 
    const [linTmp2, setLinTmp2] = useState([])

    const [nomeLog, setNomeLog] = useState({ pesqUf:"", pesqLogradouro: "", pesqLocalidade: "" })
    const [open, setOpen] = useState(false);

    const [preview1, setPreview1] = useState([0])
    const [preview2, setPreview2] = useState([0])

    const[newSearch, setNewSearch] = useState(false)
    
    const escolaridadeOptions =[
        {value:'ENSINO FUNDAMENTAL COMPLETO',label:'ENSINO FUNDAMENTAL COMPLETO'},
        {value:'ENSINO FUNDAMENTO INCOMPLETO',label:'ENSINO FUNDAMENTO INCOMPLETO'},
        {value:'ENSINO MÉDIO COMPLETO',label:'ENSINO MÉDIO COMPLETO'},
        {value:'ENSINO MÉDIO INCOMPLETO',label:'ENSINO MÉDIO INCOMPLETO'},
        {value:'ENSINO SUPERIOR COMPLETO',label:'ENSINO SUPERIOR COMPLETO'},
        {value:'ENSINO SUPERIOR INCOMPLETO',label:'ENSINO SUPERIOR INCOMPLETO'},
        {value:'PÓS-GRADUAÇÃO COMPLETA',label:'PÓS-GRADUAÇÃO COMPLETA'},
        {value:'PÓS-GRADUAÇÃO INCOMPLETA',label:'PÓS-GRADUAÇÃO INCOMPLETA'},
        {value:'MESTRADO COMPLETO',label:'MESTRADO COMPLETO'},
        {value:'MESTRADO INCOMPLETO',label:'MESTRADO INCOMPLETO'},
        {value:'DOUTORADO COMPLETO',label:'DOUTORADO COMPLETO'},
        {value:'DOUTORADO INCOMPLETO',label:'DOUTORADO INCOMPLETO'}
    ]

    const ufOpcoes = [  {value:'AC', label:'AC'},{value:'AL', label:'AL'},{value:'AP', label:'AP'},{value:'AM', label:'AM'},
                        {value:'BA', label:'BA'},{value:'CE', label:'CE'},{value:'DF', label:'DF'},{value:'ES', label:'ES'},
                        {value:'GO', label:'GO'},{value:'MA', label:'MA'},{value:'MG', label:'MG'},{value:'MS', label:'MS'},
                        {value:'MT', label:'MT'},{value:'PA', label:'PA'},{value:'PE', label:'PE'},{value:'PI', label:'PI'},
                        {value:'PB', label:'PB'},{value:'PR', label:'PR'},{value:'RJ', label:'RJ'},{value:'RN', label:'RN'},
                        {value:'RS', label:'RS'},{value:'RO', label:'RO'},{value:'RR', label:'RR'},{value:'SC', label:'SC'},
                        {value:'SE', label:'SE'},{value:'SP', label:'SP'},{value:'TO', label:'TO'}]

    
    const [locTmp, setLocTmp] = useState([])
    const [logOptions, setLogOptions] = useState([])


    const generoOpcoes = [
        {value:'MASCULINO', label:'MASCULINO'}, 
        {value:'FEMININO', label:'FEMININO'},
        {value:'OUTRO', label:'OUTRO (QUAL?)'},
        {value:'NAO_DIZER', label:'PREFIRO NÃO DIZER'}]

    const colunas = [ { field: 'id', headerName: 'ID', hideable: true, sortable: false, width: 1, align: 'center', headerAlign: 'center' },
            { field: 'cep', headerName: 'CEP', hideable: true, sortable: false, width: 100, align: 'center', headerAlign: 'center' },
            { field: 'logradouro', headerName: 'Logradouro', hideable: false, sortable: false, width: 250, align: 'left', headerAlign: 'center' },
            { field: 'complemento', headerName: 'Complemento', hideable: false, sortable: false, width: 150, align: 'left', headerAlign: 'left'  }, 
            { field: 'localidade', headerName: 'Localidade', hideable: false, sortable: false, width: 160, align: 'left', headerAlign: 'center'  },   
            { field: 'uf', headerName: 'UF', hideable: false, sortable: false, width: 50, align: 'center', headerAlign: 'center'  },  
            { field: 'actions', type: 'actions', headerName: 'Selecione', width: 90, cellClassName: 'actions',getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<FcCheckmark/>}
                        label="Selecione"
                        onClick={handleSelectClick(id)}
                        color="inherit"
                    />,
                    ];}
                }]
            
    const [linhas, setLinhas] = useState([])


    /* ********** CEP por Logradouro ************** */


    const handleSelectClick = (id) => () => {
        setLinTmp1(linhas.filter((row) => row.id === id));
        setOpen(false);
    };



    useEffect(() => {
        linTmp2.forEach((item, index)=>{
           item.id = index+1
       })
       setLinhas(linTmp2)      
    }, [linTmp2]);



    useEffect(() => {
       if (linTmp1.length !== 0){
            delete(linTmp1[0].id)
            setTutor(state => {
                return { ...state, ...linTmp1[0] };
            });
       }
    }, [linTmp1])

    
   /* ********** Fim  CEP por Logradouro ************** */


   /* ********** Logradouro por CEP ************** */


    useEffect(() => {
        setCepLog(ceplogTmp1);
    }, [ceplogTmp1]);



    useEffect(() => {
        pesquisaCep();
    }, [tutor.cep])



    useEffect(() => {
        setTutor(state => {
            return { ...state, ...ceplog };
        });
    }, [ceplog])


    /* ********** Fim Logradouro por CEP ************** */

    const handleClickOpen = () => {
        setLinhas([])
        setOpen(true)
    };
  


    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        setTutor({ ...tutor, images: [preview1[0],preview2[0]] });
    }, [preview1, preview2])



    const pesquisaCep = async () => {

        const cepTmp = tutor.cep.replace(/[^\d]+/g,'');
        const urlTmp = `https://viacep.com.br/ws/${cepTmp}/json/`
            
        if(cepTmp === '') return false;

        if (cepTmp.length !== 8 ){ return false; }

        if (newSearch){
            const result = await fetch(urlTmp,{
                method: 'GET',
                headers: { 
                'Content-Type': 'application/json',
                }}).then((result) => result.json()).catch((err) => {
                return err.response
            });
            
            setCepLogTmp1( result )
            setNewSearch( false )
        }
    }



    const pesquisaLogradouro = async () => {
        
        const logTmp = nomeLog.pesqLogradouro;
        const locTmp = nomeLog.pesqLocalidade;
        const ufTmp = nomeLog.pesqUf;

        const urlTmp = `https://viacep.com.br/ws/${ufTmp}/${locTmp}/${logTmp}/json/`


        if(logTmp === '') return false;
        if (logTmp.length < 3 ){ return false; }

        if(locTmp === '') return false;
        if (locTmp.length < 5 ){ return false; }

        if(ufTmp === '') return false;
        if (ufTmp.length < 2 ){ return false; }

        const result = await fetch(urlTmp,{
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }}).then((result) => result.json()).catch((err) => {
                return err.response
            });        
            setLinTmp2( result )
    }



    const PesquisaLocalidade = async () => {
        
        const ufTmp = tutor.uf;
        const urlTmp = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufTmp}/municipios`

        if(ufTmp === '') return false;

        if (ufTmp.length < 2 ){ return false; }

        const result = await fetch(urlTmp,{
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }}).then((result) => result.json()).catch((err) => {
                return err.response
            });        
            setLocTmp( result )
    }



    const fetchImage1 = async () => {
        const res = await fetch( `${siteApi}/images/tutores/${tutor.img_docto}`);
        if (res.status === 200) {
            const imageBlob = await res.blob();
            setPreview1([imageBlob])
        } else {
            setPreview1([0]) 
        }
    };



    const fetchImage2 = async () => {
        const res = await fetch( `${siteApi}/images/tutores/${tutor.img_comprovante}`);
        if (res.status === 200) {
            const imageBlob = await res.blob();
            setPreview2([imageBlob])
        } else {
            setPreview2([0])  
        }
    };


           
    useEffect(() => {
        fetchImage1();
    }, []);



    useEffect(() => {
        fetchImage2();
    }, []);



    function handleChange(e) {
        setTutor({ ...tutor, [e.target.name]: e.target.value })
    }
 

    /*
    function handleChangeMail(e) {
        setTutor({ ...tutor, [e.target.name]: e.target.value })
    }
    */


    const submit = (e) => {
        e.preventDefault()
        ConvertToUpper( tutor )
        handleSubmit( tutor )
    }



    function handleUf(e) {
        setTutor({...tutor, uf: e.target.options[e.target.selectedIndex].value})
    }



    function handlePesqUf(e) {
        setNomeLog({ ...nomeLog, pesqUf: e.target.options[e.target.selectedIndex].value})
    }



    function handleChangeData(e) {
        setTutor({...tutor, [e.target.name]: e.target.value })
    }



    function handleChangeCep(e) {
        setTutor({...tutor, [e.target.name]: e.target.value })
        setNewSearch( true )
    }



    function handleGenero(e) {
        setTutor({...tutor, genero: e.target.options[e.target.selectedIndex].value, })
    }



    function onFileChange1(e) {
        setPreview1(Array.from(e.target.files))
    }



    function onFileChange2(e) {
        setPreview2(Array.from(e.target.files))
    }



    useEffect(() => {
       PesquisaLocalidade()
    }, [nomeLog.pesqUf])



    useEffect(() => {
       let locOpcoes = []
       if ( locTmp && locTmp.length >0 ){
          locTmp.forEach((item,index)=>{
                locOpcoes.push({value:toUpperCase(item.nome), label:toUpperCase(item.nome)})
            })
           setLogOptions(locOpcoes)
     }
    }, [locTmp])



    function handleChangePesqLogradouro(e) {
        if ((e.target.value !== '') && (e.target.value !== undefined )){
            e.target.value = toUpperCase(e.target.value)
            setNomeLog({ ...nomeLog, [e.target.name]: e.target.value })
        }
    } 



    function handleChangePesqLocalidade(e) {
        if ((e.target.value !== '') && (e.target.value !== undefined )){
            e.target.value = toUpperCase(e.target.value)
            setNomeLog({ ...nomeLog, [e.target.name]: e.target.value })
        }
    }



    function homeTutores(){
        history('/tutores')
    }


    /*
    function iterate(obj) {
        
        for (var key in obj) {
            if (obj[key] !== null && typeof obj[key] === "object") {
                iterate(obj[key]);
            } else if ( obj[key] !== null && 
                        obj[key] !== '' &&  
                        obj[key] !== undefined && 
                        typeof obj[key] === "string" && 
                        key !== "type" && 
                        key !== "name" )
            {
                obj[key] = obj[key].toUpperCase();
            }
        }
    }
    */

    return (
        <form onSubmit={submit} className={formStyles.form_container}>

        <Container className='p-4 shadow-lg text-dark'>
        <fieldset>
            <div  className={formStyles.form_center}>
                <label> Dados do Tutor </label>
            </div>
            
            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                    &nbsp;
                </div>
                <div className={formStyles.format20}>
                <Input
                    text='&nbsp;'
                    name='null'
                    type={'hidden'}
                />
                </div>
            </div>

            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                    <Input
                    text="Nome Completo do Tutor"
                    name="nome"
                    type="text"
                    placeholder="Digite o nome do Tutor"
                    handleOnChange={handleChange}
                    value={toUpperCase(tutor.nome)}
                    maxLength={120}
                    />
                </div>

                <div className={formStyles.format20}>
                <Input
                    text="Data de Nascimento"
                    name="nascimento"
                    type="date"
                    handleOnChange={handleChange} 
                    value={Moment(tutor.nascimento).format('YYYY-MM-DD')}
                    />
                </div>
            </div>
            <div className={formStyles.format}>
                <div className={formStyles.format50}>
                    <Input
                    text="Nome Social"
                    name="nm_social"
                    type="text"
                    placeholder="Digite o nome Social"
                    handleOnChange={handleChange}
                    value={toUpperCase(tutor.nm_social) || ''}
                    maxLength={120}
                    />
                </div>

                <div className={formStyles.format20}>
                <Select
                    text="Genero"
                    name="genero"
                    options={generoOpcoes}
                    handleOnChange={handleGenero}
                    value={tutor.genero || ''}
                    />
                </div>

                <div className={formStyles.format20}>
                    <Input
                        text="Outro Genero"
                        name="ou_genero"
                        type="text"
                        placeholder="Digite o Genero Informado"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.ou_genero) || ''}
                        maxLength={20}
                    />  
                </div>

            </div>
            </fieldset>

            <p/>

            <fieldset>
            <div className={formStyles.format}>
                <div className={formStyles.format20}>
                    <Input
                        text="CPF"
                        name="docto1"
                        type="text"
                        placeholder="000.000.000-00"
                        handleOnChange={handleChange}
                        value={cpfMask(tutor.docto1)}    
                    />
                </div>

                <div className={formStyles.format20}>
                    <Input
                        text="RG"
                        name="docto2"
                        type="text"
                        placeholder="Digite o RG"
                        handleOnChange={handleChange}
                        value={tutor.docto2}
                        maxLength={15}
                    />  
                </div>

                <div className={formStyles.format20}>
                    <Input
                        text="Data Emissão"
                        name="data_exp"
                        type="date"
                        placeholder="Digite a Data Emissão"
                        handleOnChange={handleChangeData}
                        value={Moment(tutor.data_exp).format('YYYY-MM-DD')}
                    />  
                </div>

                <div className={formStyles.format30}>
                    <Input
                        text="Orgão Emissor"
                        name="orgao_exp"
                        type="text"
                        placeholder="Digite o Orgão Emissor"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.orgao_exp)}
                        maxLength={6}
                    />  
                </div>
            </div>
            </fieldset>

            <fieldset>
            <div className={formStyles.format}>

            <div className={formStyles.format40}>
                    <Select
                        text="Escolaridade"
                        name="escolaridade"
                        options={escolaridadeOptions}
                        placeholder="Digite a Escolaridade"
                        handleOnChange={handleChange}
                        value={tutor.escolaridade || ''}
                    /> 
                </div>

                <div className={formStyles.format50}>
                    <Input
                        text="E-mail"
                        name="email"
                        type="text"
                        placeholder="Digite o Email"
                        handleOnChange={handleChange}
                        value={tutor.email || ''}
                        maxLength={120}
                    />  
                </div>

            </div>

            <div className={formStyles.format}>
                <div className={formStyles.format30}>
                    <InputMask
                        text="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Digite o Telefone"
                        handleOnChange={handleChange}
                        value={telefoneMask(tutor.telefone)}
                        maxLength={14}
                    />
                </div>
                <div className={formStyles.format30}>
                    <InputMask
                        text="Celular 1"
                        name="celular1"
                        type="text"
                        placeholder="(00) 00000-0000"
                        handleOnChange={handleChange}
                        value={celularMask(tutor.celular1)}
                        maxLength={15}
                    />
                </div>

                <div className={formStyles.format30}>
                    <InputMask
                        text="Celular 2"
                        name="celular2"
                        type="text"
                        placeholder="(00) 00000-0000"
                        handleOnChange={handleChange}
                        value={celularMask(tutor.celular2)}
                        maxLength={15}
                    />
                </div>

            </div>

            <div className={formStyles.format100}>
                      <TextArea
                        text="Outras Informações"
                        name="oinfo"
                        type="text"
                        placeholder="Digite Outra Informação"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.oinfo) || ''}
                        maxLength={255}
                        rows={3}
                    />  
            </div>
            </fieldset>

            <p/>

            <fieldset>
            <div className={formStyles.format}>
            
                <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: 3,
                            border: "solid 1px black",
                            boxShadow: 6,
                        }
                      }}>

                    <DialogTitle className={formStyles.form_textcenter}>Pesquisa de CEP por Logradouro</DialogTitle>
                    <DialogContentText className={formStyles.form_textcenter}>
                            Entre com os Dados para Pesquisa
                    </DialogContentText>
                    <DialogContent sx={{ 
                        border: "solid 1px",
                        borderRadius: 2,
                        borderColor: 'gray',
                        boxShadow: 3,
                        mt: 5, // margin-top
                        ml: 3, // margin-left
                        mr: 3  // margin-right
                        }}>

                       <div className={formStyles.format}>
                            <div className={formStyles.format20}>
                            <Select
                                text="UF"
                                name="pesqUf"
                                options={ufOpcoes}
                                handleOnChange={handlePesqUf}
                                value={nomeLog.pesqUf || ''}
                            />  
                            </div>

                            <div className={formStyles.format50}>
                            <Select
                                text="Localidade"
                                name="pesqLocalidade"
                                options={logOptions}
                                placeholder="Digite o Logradouro"
                                handleOnChange={handleChangePesqLocalidade}
                                value={nomeLog.pesqLocalidade || ''}
                            />
                            </div>

                            <div className={formStyles.format30}>
                                <DialogActions>
                                    <Button variant="outlined" color="error" endIcon={<BsXSquare />} onClick={handleClose}>Cancelar&nbsp;</Button>
                                </DialogActions>
                            </div>
                        </div>

                        <div className={formStyles.format}>
                            <div className={formStyles.format70}>
                            <Input
                                text="Logradouro"
                                name="pesqLogradouro"
                                type="text"
                                placeholder="Digite o Logradouro"
                                handleOnChange={handleChangePesqLogradouro}
                                value={nomeLog.pesqLogradouro || ''}
                            />
                            </div>
                            <div className={formStyles.format30}>
                                <DialogActions>
                                    <Button variant="outlined" color="success" endIcon={<BsSearch />} onClick={pesquisaLogradouro}>Pesquisar</Button>
                                </DialogActions>
                            </div>

                        </div>

                    </DialogContent>

                    <DialogContent style={{height:'250px'}}>
                        <DataGrid 
                            getRowId={(row) => row.id}
                            rows={linhas}
                            columns={colunas}
                            editMode="row"
                            pageSize={3}
                            rowsPerPageOptions={[3]}
                            disableMultipleSelection={true}
                            density="compact"
                            // checkboxSelection
                            disableColumnFilter
                            disableHideColumn
                            disableColumnSelector
                            disableColumnMenu
                            initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    id: false,
                                    },
                                },
                            }}
                            sx={{
                                boxShadow: 3,
                                border: 1,
                                borderRadius: 2,
                                borderColor: 'gray',
                            }}
                        />

                    </DialogContent>

                </Dialog>

                </div>
                <div className={formStyles.format}>
                <div className={formStyles.format20}>
                    <Input
                        text="CEP"
                        name="cep"
                        type="text"
                        placeholder="00000-000"
                        handleOnChange={handleChangeCep}
                        value={cepMask(tutor.cep)}
                    />
                </div>

                <div className={formStyles.format10}>
                    <Icon className={Styles.iconGrid3} icon={questionCircleFill} color="gray" onClick={handleClickOpen} />
                </div>

                <div className={formStyles.format70}>
                    <Input
                        text="Logradouro"
                        name="logradouro"
                        type="text"
                        placeholder="Digite o Logradouro"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.logradouro)}
                        maxLength={100}
                    />  
                </div>
                </div>

            <div className={formStyles.format}>
                <div className={formStyles.format10}>
                    <Input
                        text="Numero"
                        name="numero"
                        type="text"
                        placeholder="Digite o Numero"
                        handleOnChange={handleChange}
                        value={toNumeric(tutor.numero)}
                        maxLength={6}
                    />  
                </div>
                <div className={formStyles.format30}>
                    <Input
                        text="Complemento"
                        name="complemento"
                        type="text"
                        placeholder="Digite o Complemento"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.complemento)}
                        maxLength={20}
                    />  
                </div>

                <div className={formStyles.format50}>
                    <Input
                        text="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Digite o Bairro"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.bairro)}
                        maxLength={100}
                    />  
                </div>
            </div>

            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                    <Input
                        text="Localidade"
                        name="localidade"
                        type="text"
                        placeholder="Digite a Localidade"
                        handleOnChange={handleChange}
                        value={toUpperCase(tutor.localidade)}
                        maxLength={120}
                    />  
                </div>

                <div className={formStyles.format20}>
                    <Select
                        text="UF"
                        name="uf"
                        options={ufOpcoes}
                        handleOnChange={handleUf}
                        value={tutor.uf || ''}
                    />  
                </div>
            </div>

            </fieldset>
            </Container>

            <p/>

            <Container className='p-4 shadow-lg text-dark'>
            <fieldset>

            <div  className={formStyles.form_center}>
                <label> Documento Digitalizados </label>
            </div>
            <div className={formStyles.format}>
                <div className={formStyles.format40}>
                    <div className={formStyles.form_textcenter}>
                        <label>Documento com Foto</label>
                    </div>
                    <div className={formStyles.form_textcenter}>
                            <Button variant="contained" size="small" component="label" startIcon={<BsCameraFill />}>
                                Upload
                            <input
                                name="img_docto2"
                                type="file"
                                onChange={onFileChange1}
                                hidden
                            />
                            </Button>
                    </div>
                    <p/>
                    <div className={formStyles.preview_tutor_images}>
                        { preview1[0] !== 0
                            ? preview1.map((image, index) => (
                            <img
                                src={URL.createObjectURL(image)}
                                alt={tutor.name}
                                key={`${tutor.name}+${'1'}`}
                            />
                            )
                            ):(
                                <label>Arquivo não Carregado</label>
                            )  
                        }
                    </div>
                </div>

                <div className={formStyles.format40}>
                    <div className={formStyles.form_textcenter}>
                        <label>Comprovante de Endereço</label>
                    </div>
                    <div className={formStyles.form_textcenter}>
                        <Button variant="contained" size="small" component="label" startIcon={<BsCameraFill />}>
                            Upload
                        <input
                            name="img_docto2"
                            type="file"
                            onChange={onFileChange2}
                            hidden
                        />
                        </Button>
                    </div>
                    <p/>
                    <div className={formStyles.preview_tutor_images}>
                        { preview2[0] !== 0
                            ? preview2.map((image, index) => (
                            <img
                                src={URL.createObjectURL(image)}
                                alt={tutor.name}
                                key={`${tutor.name}+${'2'}`}
                            />
                            )
                            ):(
                                    <label>Arquivo não Carregado</label>
                            )  
                        }
                    </div>           
                </div>
            </div>
            </fieldset>
            </Container>

            <fieldset>
                <div className={Styles.lista_row}/>
            </fieldset>

            <div className={formStyles.format}>
                <div className={formStyles.format20}/>
                <div className={formStyles.format60}>
                    <div className={formStyles.actions}>
                        <div> 
                            <input type="submit" value={btnText} />
                        </div>  
                        <div> 
                            <input type="button" value="Voltar" onClick={homeTutores} />
                        </div>
                    </div>
                </div>
                <div className={formStyles.format20}/>
            </div>

        </form>
    )
}

export default TutorForm

/*
                              src={URL.createObjectURL(image)}                    

*/
import api from '../utils/Api'
import { useState, useEffect } from 'react'
// import useFlashMessage from './useFlashMessage'

import { useNavigate } from 'react-router-dom'

import toastMessage from './../hooks/toastModel'

export default function useAuth() {

  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  const history = useNavigate();
  
  // const { setFlashMessage } = useFlashMessage()



  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
      setAuthenticated(true)
    }
    setLoading(false)
  }, [])

  /*
  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin')
  }, [])
  */

  async function login(user) {
      let msgText = 'Login realizado com sucesso!'
      let msgType = 'success'
      let confirme = ''

      try {

        // const data = await api.post('usuarios/login', user)
        
        const data = await api.post('https://info.franciscomorato.sp.gov.br/usuarios/login', user)
          .then((response) => {
              confirme = response.status
              return response.data      
          })
          .catch((error) => {
              confirme = error.response.status
              msgType = 'error'
              msgText = error.response.data.message
          })

    
          if (confirme === 200){
              await authUser(data)
          } 

    } catch (error) {
        msgText = error
        msgType = 'error'

        setAuthenticated( false )
        localStorage.setItem('isAdmin', false )
    }
 
    toastMessage(msgText, msgType)

  }



  async function authUser(data) {

    setAuthenticated( true ) 

    data.isAdmin?
      localStorage.setItem('isAdmin', true )
    :
      localStorage.setItem('isAdmin', false )
    
    localStorage.setItem('token', JSON.stringify(data.token))

    history("/")
    
  }



  function logout() {
    const msgText = 'Logout realizado com sucesso!'
    const msgType = 'success'

    setAuthenticated(false)
    localStorage.removeItem('token')
    localStorage.removeItem('isAdmin')
    localStorage.removeItem('savePesqAnimal') 
    localStorage.removeItem('savePesqTutor') 
    localStorage.removeItem('savePesqTransf') 
    api.defaults.headers.Authorization = undefined

    history('/login')

    toastMessage(msgText, msgType)
    
  }


  return { authenticated, loading, login, logout }
}



const ConvertToUpper = async ( Object ) => {
		let stringTmp = ''

        for (var key in Object) {
            if (Object[key] !== null && typeof Object[key] === "object") {
                ConvertToUpper(Object[key]);
            } else if ( Object[key] !== null && 
                        Object[key] !== '' &&  
                        Object[key] !== undefined && 
                        typeof Object[key] === "string" && 
                        key !== "type" && 
                        key !== "name" )
            {
                stringTmp = Object[key].trim()
                Object[key] = stringTmp.toUpperCase()
            }
        }
	
  return Object;
};

export default ConvertToUpper


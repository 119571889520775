import React from "react"
import { useEffect } from "react";
// import { useRef } from 'react';

import { RotatingLines } from  'react-loader-spinner'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'
import galeryStyles from '../../layout/estilos/Galery.module.css'

import { useState } from 'react';

// import { ReactPDF, Document, Page, View, pdfjs } from 'react-pdf'
// import { fs } from 'fs' 

import  PaginaDefault from  '../../../utils/paginaDefault'

import {Container , Row, Col} from 'react-bootstrap' 

import { Button } from 'react-bootstrap'

// import { BsFillArrowLeftCircleFill } from "react-icons/bs"
// import { BsFillArrowRightCircleFill } from "react-icons/bs"
// import { BsDownload } from "react-icons/bs"
// import { BsCheckCircle } from "react-icons/bs"

import toastMessage from './../../../hooks/toastModel'


// Importing modules

function TutorAnimaisPdf() {

  //  pdfjs.GlobalWorkerOptions.workerSrc = "../../js/pdf.worker/pdfjs-2.12.313/pdf.worker.js"
  //  workerSrc  baixado do site: https://cdnjs.com/libraries/pdf.js

  const [token] = useState(localStorage.getItem('token') || '')
  const siteApi = process.env.REACT_APP_API
  const [blobURL, setBlobURL] = useState()

  const [showSpinner, setShowSpinner] = useState(false)

  // const docRef = useRef()

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
  });
      
  useEffect( () => {
      PageDefault() 
  },[])



  useEffect( () => {
      if ( showSpinner ){
          Pesquisa()
      }
  },[showSpinner])


async function Pesquisa(){
  const url = `${siteApi}/relatorios/animais_bairro`

  const result = await fetch(url,{
      method: 'POST',
      headers: { 
          'Authorization': `Bearer ${JSON.parse(token)}`,
          'Content-Type': 'application/json',
      }})

    if (result.status === 200) {
        const docBlob = await result.blob();
        setBlobURL( URL.createObjectURL(docBlob) )
    } else {
        PageDefault() 
    }
    setShowSpinner(false)
} 


function gerarRelatorio(){
    setShowSpinner(true)
}

useEffect( () => {
    PageDefault()
},[])


const PageDefault = async () => {
  
    let msgText ='Arquivo Não Encontrado'
    let msgType = 'error'

    const docBlob = await PaginaDefault()

    try {
        setBlobURL( URL.createObjectURL(docBlob) )
    }
    catch {
        toastMessage(msgText, msgType)
    } 
}


return (
    <section  className='p-0 shadow-lg'>
      <div className={galeryStyles.container}>
        <Container>
            <Row>
              <Col md={10}>
                <div className={Styles.lista_row}>
                      <label><h3>Relatório de Animais - Agrupado por Bairro</h3></label>
                </div>
              </Col>
              <Col md={2}>
                   <Button variant="outline-danger" onClick={() => gerarRelatorio() } >Gerar Relatório</Button>
               </Col>
            </Row>

            <Row className="bg-white p-2 fs-3" />

            <Row>
                {
                    showSpinner ?
                    <div className={formStyles.form_report} >
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="6"
                      animationDuration="0.75"
                      visible={showSpinner}
                    />
                    </div>
                    : 
                    <object data={blobURL} type="application/pdf"
                        style={{ 
                        width:'1000px',
                        height: '650px' 
                        }} >
                    </object>
                }
            </Row>

            <Row className="bg-white p-2 fs-3" />

        </Container>
        </div>
    </section>
  )

}

export default TutorAnimaisPdf



/*


useEffect( () => {

  if (printOk === true){
    const iframeTmp =  domGet('print-file')
    iframeTmp.style.display = 'block'
    iframeTmp.src = blobURL
    iframeTmp.contentWindow.focus()
    iframeTmp.contentWindow.print()
    setPrintOk(false)
  }

},[blobURL])


function domGet( id , rootNode ) {
  if ( !id ) return null;

  if ( rootNode === undefined ) {

      // rel to doc base
      var o = document.getElementById( id );
      return o;

  } else {

      // rel to current node
      var nodes = [];
      nodes.push(rootNode);
      while ( nodes && nodes.length > 0 ) {
          var children = [];
          for ( var i = 0; i<nodes.length; i++ ) {
              var node = nodes[i];
              if ( node && node['id'] !== undefined ) {
                  if ( node.id === id ) {
                      return node; // found!
                  }
              }

              // else keep searching
              var childNodes = node.childNodes;
              if ( childNodes && childNodes.length > 0 ) {
                  for ( var j = 0 ; j < childNodes.length; j++ ) {
                      children.push( childNodes[j] );
                  }
              }
          }
          nodes = children;
      }

      // nothing found
      return null;
  }
}


*/

/*
async function createPdf() {
  const pdfDoc = await PDFDocument.create()
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

  // const page = pdfDoc.addPage()
  
  const page = pdfDoc.addPage([700, 450]);

  const { width, height } = page.getSize()
  const fontSize = 30
  page.drawText('Área de Visualização', {
    x: 220,
    y: height - 7 * fontSize,
    size: fontSize,
    font: timesRomanFont,
    color:  rgb(0.5, 0.5, 0.5),
  })

  let pdfBytes = await pdfDoc.save()
  let pdfBlob = new Blob( [pdfBytes])

  return pdfBlob

}
*/



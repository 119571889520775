import React from 'react'
import { useState } from 'react'

import formStyles from '../../formularios/FormPaginas.module.css'
import Style from '../../../componentes/layout/estilos/Dashboard.module.css'
import {Container} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import Input from '../../formularios/Input'
import CheckBox from '../../formularios/Checkbox'

function UsuarioForm({ handleSubmit, usuarioData, btnText }) {

    const [user, setUser] = useState(usuarioData || {})
    const history = useNavigate();

    function handleChange(e) {
        setUser({ ...user, [e.target.name]: e.target.value })
    }


    const submit = (e) => {
        e.preventDefault()
        handleSubmit(user)
    }

    console.log(usuarioData.nivel)


    function handleChangeAdmin(e) {
        if ( e.target.checked ) {
            setUser({ ...user, [e.target.name]: 1 })
        } else {
            setUser({ ...user, [e.target.name]: 0 } )
        }
     }

    function homeUsuarios(){
        history('/usuarios')
    }

    return (
        <form onSubmit={submit} className={formStyles.form_container_sm}>

        <div  className={formStyles.form_center}>
           <label> Dados do Usuário </label>
        </div>

        <Container className='p-4 shadow-lg text-dark'>
        <fieldset> 
        <div>
            <Input
            text="Nome"
            type="text"
            name="nome"
            placeholder="Digite o seu nome"
            handleOnChange={handleChange}
            value={user.nome }
            readOnly="readonly"
            />

            <Input
            text="Usuario"
            type="text"
            name="usuario"
            placeholder="Digite o usuario"
            handleOnChange={handleChange}
            value={user.usuario}
            readOnly="readonly"
            />  

            <Input
            text="E-mail"
            type="email"
            name="email"
            placeholder="Digite o seu e-mail"
            handleOnChange={handleChange}
            value={user.email || ''}
            />

            <CheckBox
            text="&nbsp;&nbsp;Usuário é Administrador"
            name="nivel"
            type="checkbox"
            handleOnChange={handleChangeAdmin}
            value={user.nivel }
            checked={ user.nivel }
            />               


            <Input
            text="Senha"
            type="password"
            name="password"
            placeholder="Digite a sua senha"
            handleOnChange={handleChange}
            />

            <Input
            text="Confirmação de senha"
            type="password"
            name="retpassword"
            placeholder="Confirme a sua senha"
            handleOnChange={handleChange}
            />
        </div>
        </fieldset>
        </Container>
                
        <fieldset>
        <div className={formStyles.format}>
                <div className={formStyles.format}>
                &nbsp;  
                </div>
            </div>
            <div className={Style.lista_row}/>
        </fieldset>

            <div className={formStyles.actions}>
                <div> 
                    <input type="submit" value={btnText} />
                </div>  
                <div> 
                    <input type="button" value="Voltar" onClick={homeUsuarios}/>
                </div>
            </div>
           
        </form>
    )
}

export default UsuarioForm


/*

            <div className={formStyle.preview_user_images}>
                {preview.length > 0
                ? preview.map((image, index) => (
                <img
                 src={URL.createObjectURL(image)}
                    alt={user.nome}
                    key={`${user.nome}+${index}`}
                />
                ))
            : user.images &&
                user.images.map((image, index) => (
                <img
                    src={`${process.env.REACT_APP_API}/images/usuario/${image}`}
                    alt={user.name}
                    key={`${user.name}+${index}`}
                />
                ))}
            </div>

                <Input
                text="Imagens do Usuario"
                type="file"
                name="images"
                handleOnChange={onFileChange}
                multiple={true}
            />


*/
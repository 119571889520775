// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputMask_form_control__FZL2s {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
  }
  
  .InputMask_form_control__FZL2s label {
    margin-bottom: 0.1em;
    font-weight: bold;
    font-size: 0.8em;
  }
  
 .InputMask_form_control__FZL2s input {
    padding: 0.1em 0 0 3em;
    border: 1px solid #777;
    border-radius: 5px;
  }

.InputMask_center__O3kib {
  padding: 0.1em;
  border: 1px solid #777;
  border-radius: 5px;
}

  .InputMask_form_control__FZL2s input::placeholder {
    color: #7b7b7b;
  }`, "",{"version":3,"sources":["webpack://./src/componentes/formularios/InputMask.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;EACtB;;EAEA;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;EAClB;;CAED;IACG,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;EACpB;;AAEF;EACE,cAAc;EACd,sBAAsB;EACtB,kBAAkB;AACpB;;EAEE;IACE,cAAc;EAChB","sourcesContent":[".form_control {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-bottom: 0.5em;\r\n  }\r\n  \r\n  .form_control label {\r\n    margin-bottom: 0.1em;\r\n    font-weight: bold;\r\n    font-size: 0.8em;\r\n  }\r\n  \r\n .form_control input {\r\n    padding: 0.1em 0 0 3em;\r\n    border: 1px solid #777;\r\n    border-radius: 5px;\r\n  }\r\n\r\n.center {\r\n  padding: 0.1em;\r\n  border: 1px solid #777;\r\n  border-radius: 5px;\r\n}\r\n\r\n  .form_control input::placeholder {\r\n    color: #7b7b7b;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `InputMask_form_control__FZL2s`,
	"center": `InputMask_center__O3kib`
};
export default ___CSS_LOADER_EXPORT___;

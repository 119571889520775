import React from 'react'
import styles from './Checkbox.module.css'

function CheckBox({
  type,
  text,
  name,
  placeholder,
  handleOnChange,
  value,
  checked,
}) {
  return (
    <div className={styles.form_control}>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={value}
        checked={checked} 
      />
      <label htmlFor={name}>{text}</label>
    </div>
  )
}

export default CheckBox


import React from 'react'
import styles from "../estilos/Container.module.css";

function Container({children}) {
  return <main className={styles.container}>
      {children}
  </main>
}

export default Container

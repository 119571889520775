import React from 'react'

import { useState } from 'react'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../utils/Api'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../../componentes/layout/estilos/Dashboard.module.css'
import {Container} from 'react-bootstrap'

import Select from '../../formularios/Select'
import Input from '../../formularios/Input'
import CheckBox from '../../formularios/Checkbox'
import TextArea from '../../formularios/TextArea'

import { DataGrid } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';


import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { cepMask } from '../../../utils/cepMask'
// import { cpfMask } from '../../../utils/cpfMask'
import { toUpperCase } from '../../../utils/toUpperCase'
import { toNumeric } from '../../../utils/toNumeric'
import Moment from 'moment'
import ConvertToUpper from '../../../utils/ConvertToUpper'

// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { FcCheckmark } from "react-icons/fc"
import { BsSearch } from "react-icons/bs"
import { BsXSquare } from "react-icons/bs"
// import { BsCameraFill } from "react-icons/bs"
import { FcCancel } from "react-icons/fc"
import { VscTrash } from "react-icons/vsc"
import { Icon } from '@iconify/react';
// import questionMarkRounded from '@iconify/icons-material-symbols/question-mark-rounded'
import questionCircleFill from '@iconify/icons-bi/question-circle-fill';

function CadastroAnimal() {

    const [tutor, setTutor] = useState([])
    const [tutorTmp, setTutorTmp] = useState([])
    const [cpfTmp, setCpfTmp] = useState({})

    const[newSearch, setNewSearch] = useState(false)

    const [nomeLog, setNomeLog] = useState({ pesqUf:"", pesqLogradouro: "", pesqLocalidade: "" })
    const [open, setOpen] = useState(false);
    const [ceplog, setCepLog] = useState([])
    const [ceplogTmp1, setCepLogTmp1] = useState([])
    const [locTmp, setLocTmp] = useState([])
    const [logOptions, setLogOptions] = useState([])


    const [linhasTutor, setLinhasTutor] = useState([])
    // const [linTutorTmp1, setLinTutorTmp1] = useState([]) 
    // const [linTutorTmp2, setLinTutorTmp2] = useState([])

    const [linhasLog, setLinhasLog] = useState([])
    const [linLogTmp1, setLinLogTmp1] = useState([]) 
    const [linLogTmp2, setLinLogTmp2] = useState([])

    const ufOpcoes = [  {value:'AC', label:'AC'},{value:'AL', label:'AL'},{value:'AP', label:'AP'},{value:'AM', label:'AM'},
    {value:'BA', label:'BA'},{value:'CE', label:'CE'},{value:'DF', label:'DF'},{value:'ES', label:'ES'},
    {value:'GO', label:'GO'},{value:'MA', label:'MA'},{value:'MG', label:'MG'},{value:'MS', label:'MS'},
    {value:'MT', label:'MT'},{value:'PA', label:'PA'},{value:'PE', label:'PE'},{value:'PI', label:'PI'},
    {value:'PB', label:'PB'},{value:'PR', label:'PR'},{value:'RJ', label:'RJ'},{value:'RN', label:'RN'},
    {value:'RS', label:'RS'},{value:'RO', label:'RO'},{value:'RR', label:'RR'},{value:'SC', label:'SC'},
    {value:'SE', label:'SE'},{value:'SP', label:'SP'},{value:'TO', label:'TO'}]

    const [animal, setAnimal] = useState(
        {chip:undefined, 
         nome:undefined,
         especie:undefined, 
         especie_outras:undefined, 
         nascimento:'', 
         genero:undefined, 
         raca:undefined, 
         porte:undefined, 
         cor:undefined, 
         caracteristicas:undefined, 
         antirabica:undefined, 
         datavacina:'', 
         tipo:undefined,
         logradouro:undefined,
         numero:undefined,
         complemento:undefined,
         bairro:undefined,
         localidade:undefined,
         cep:undefined,
         uf:undefined,
         dtcadastro:Moment( Date()).format('YYYY-MM-DD')
        })

    const porteOpcoes = [
        {value:'PEQUENO', label:'PEQUENO'},
        {value:'MÉDIO',label:'MÉDIO'},
        {value:'GRANDE',label:'GRANDE'}
        ]

    const generoOpcoes = [
        {value:'MACHO', label:'MACHO'},
        {value:'FEMEA',label:'FEMEA'}
        ]

    const especiesOpcoes =[
        {value:"ABELHAS",label:"ABELHAS"},
        {value:"ALPACA",label:"ALPACA"},
        {value:"AMANDINE",label:"AMANDINE"},
        {value:"AVESTRUZ AFRICANA",label:"AVESTRUZ AFRICANA"},
        {value:"BAVETE MASQUE",label:"BAVETE MASQUE"},
        {value:"BICHO DA SEDA",label:"BICHO DA SEDA"},
        {value:"BÚFALO",label:"BÚFALO"},
        {value:"CABRA",label:"CABRA"},
        {value:"CACHORRO",label:"CACHORRO"},
        {value:"CALAFATE TIMOR",label:"CALAFATE TIMOR"},
        {value:"CALOPSITA",label:"CALOPSITA"},
        {value:"CAMELO",label:"CAMELO"},
        {value:"CAMUNDONGO",label:"CAMUNDONGO"},
        {value:"CANÁRIO DO REINO",label:"CANÁRIO DO REINO"},
        {value:"CAVALO",label:"CAVALO"},
        {value:"CHINCHILA",label:"CHINCHILA"},
        {value:"CISNE BRAVO",label:"CISNE BRAVO"},
        {value:"CISNE DA TUNDRA",label:"CISNE DA TUNDRA"},
        {value:"CISNE NEGRO",label:"CISNE NEGRO"},
        {value:"COBAIA",label:"COBAIA"},
        {value:"CODORNA",label:"CODORNA"},
        {value:"COELHO",label:"COELHO"},
        {value:"CORDON BLEU",label:"CORDON BLEU"},
        {value:"DIAMANTE DE GOULD",label:"DIAMANTE DE GOULD"},
        {value:"DIAMANTE DE PEITO BEGE",label:"DIAMANTE DE PEITO BEGE"},
        {value:"DIAMANTE MANDARIM",label:"DIAMANTE MANDARIM"},
        {value:"DROMEDÁRIO",label:"DROMEDÁRIO"},
        {value:"ESCARGOT VERDADEIRO",label:"ESCARGOT VERDADEIRO"},
        {value:"FAISÃO DE COLEIRA",label:"FAISÃO DE COLEIRA"},
        {value:"FAISÃO DE TEMMINCK",label:"FAISÃO DE TEMMINCK"},
        {value:"GADO BOVINO",label:"GADO BOVINO"},
        {value:"GADO ZEBUÍNO",label:"GADO ZEBUÍNO"},
        {value:"GALINHA",label:"GALINHA"},
        {value:"GALINHA DANGOLA",label:"GALINHA DANGOLA"},
        {value:"GANSO",label:"GANSO"},
        {value:"GANSO CANADENSE",label:"GANSO CANADENSE"},
        {value:"GANSO DO NILO",label:"GANSO DO NILO"},
        {value:"GATO",label:"GATO"},
        {value:"GRANATINA PÚRPURA",label:"GRANATINA PÚRPURA"},
        {value:"GRANATINA VIOLETA",label:"GRANATINA VIOLETA"},
        {value:"HAMSTER",label:"HAMSTER"},
        {value:"JUMENTO",label:"JUMENTO"},
        {value:"LHAMA",label:"LHAMA"},
        {value:"MANON",label:"MANON"},
        {value:"MARRECO",label:"MARRECO"},
        {value:"MINHOCA",label:"MINHOCA"},
        {value:"OVELHA",label:"OVELHA"},
        {value:"PATO AUSTRALIANO",label:"PATO AUSTRALIANO"},
        {value:"PATO CAROLINA",label:"PATO CAROLINA"},
        {value:"PATO DAS BAHAMAS",label:"PATO DAS BAHAMAS"},
        {value:"PATO DE CRISTA DA COREIA",label:"PATO DE CRISTA DA COREIA"},
        {value:"PATO MANDARIM",label:"PATO MANDARIM"},
        {value:"PATO PARAÍSO",label:"PATO PARAÍSO"},
        {value:"PAVÃO",label:"PAVÃO"},
        {value:"PEITO CELESTE",label:"PEITO CELESTE"},
        {value:"PERDIZ CHUCAR",label:"PERDIZ CHUCAR"},
        {value:"PERDIZ CINZA",label:"PERDIZ CINZA"},
        {value:"PERIQUITO AUSTRALIANO",label:"PERIQUITO AUSTRALIANO"},
        {value:"PERU",label:"PERU"},
        {value:"POMBA DIAMANTE",label:"POMBA DIAMANTE"},
        {value:"POMBO DOMESTICO",label:"POMBO DOMESTICO"},
        {value:"PORCO",label:"PORCO"},
        {value:"RATO",label:"RATO"},
        {value:"SPARROW",label:"SPARROW"},
        {value:"TADORNA PATO BRANCO",label:"TADORNA PATO BRANCO"},
        {value:"TADORNA RAJÁ",label:"TADORNA RAJÁ"},
        {value:"TADORNA SULAFRICANA",label:"TADORNA SULAFRICANA"},
        {value:"TENTILHÃO CABEÇA DE AMEIXA",label:"TENTILHÃO CABEÇA DE AMEIXA"},
        {value:"OUTRAS",label:"OUTRAS"}
    ]

    // const [loading, setLoading] = useState(false);
    // const [novaConsulta, setNovaConsulta] = useState(false);
    const [token] = useState(localStorage.getItem('token') || '')
    const history = useNavigate();
    // const { setFlashMessage } = useFlashMessage()


    const colunasTutor = [ { field: 'tutorId', headerName: 'Id', hideable: true, sortable: false, width: 50, headerAlign: 'center' },
    { field: 'tutor_docto', headerName: 'CPF', hideable: false, sortable: false, width: 150, align: 'center', headerAlign: 'center' },
    { field: 'tutor_nome', headerName: 'Nome do Tutor', hideable: false, sortable: false, width: 500, headerAlign: 'left'  }, 
    { field: 'actions', type: 'actions', headerName: 'Delete', width: 100, cellClassName: 'actions',getActions: ({ id }) => {
        return [
            <GridActionsCellItem
                icon={<VscTrash/>}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
            />,
            ];}
        }]
    
/*
<Button
    variant="contained"
    color="primary"
    onClick={handleDeleteClick(id)}
>
Excluir
</Button>
*/


    const colunasLog = [ { field: 'id', headerName: 'ID', hideable: true, sortable: false, width: 1, align: 'center', headerAlign: 'center' },
    { field: 'cep', headerName: 'CEP', hideable: true, sortable: false, width: 100, align: 'center', headerAlign: 'center' },
    { field: 'logradouro', headerName: 'Logradouro', hideable: false, sortable: false, width: 250, align: 'left', headerAlign: 'center' },
    { field: 'complemento', headerName: 'Complemento', hideable: false, sortable: false, width: 150, align: 'left', headerAlign: 'left'  }, 
    { field: 'bairro', headerName: 'Bairro', hideable: false, sortable: false, width: 200, align: 'left', headerAlign: 'center'  },   
    { field: 'uf', headerName: 'UF', hideable: false, sortable: false, width: 50, align: 'center', headerAlign: 'center'  },  
    { field: 'actions', type: 'actions', headerName: 'Selecione', width: 90, cellClassName: 'actions',getActions: ({ id }) => {
    return [
    <GridActionsCellItem
        icon={<FcCheckmark/>}
        label="Selecione"
        onClick={handleSelectClick(id)}
        color="inherit"
    />,
    ];}
    }]



    const handleDeleteClick = (id) => () => {
        setLinhasTutor(linhasTutor.filter((row) => row.tutorId !== id));
    };

    
    const [showElement, setShowElement] = useState(false)


    /* ********** CEP por Logradouro ************** */

    const handleSelectClick = (id) => () => {
        setLinLogTmp1(linhasLog.filter((row) => row.id === id));
        setOpen(false);
    };


    useEffect(() => {
         linLogTmp2.forEach((item, index)=>{
            item.id = index+1
        })
        setLinhasLog(linLogTmp2)      
    }, [linLogTmp2]);


    useEffect(() => {
        if (linLogTmp1.length !== 0){
            delete(linLogTmp1[0].id)
            setAnimal(state => {
                return { ...state, ...linLogTmp1[0] };
            });
        }
    }, [linLogTmp1])


    /* ********** Fim  CEP por Logradouro ************** */


    /* ********** Logradouro por CEP ************** */

    useEffect(() => {
        setCepLog(ceplogTmp1);
    }, [ceplogTmp1]);


    useEffect(() => {
        pesquisaCep();
    }, [animal.cep])


    useEffect(() => {
        setAnimal(state => {
            return { ...state, ...ceplog };
          });
    }, [ceplog])


    /* ********** Fim Logradouro por CEP ************** */

    
    function handleChange(e) {
        setAnimal({ ...animal, [e.target.name]: toUpperCase(e.target.value) })
     }
 

    function handleChangeCep(e) {
        setAnimal({ ...animal, [e.target.name]: toUpperCase(e.target.value) })
        setNewSearch( true )
    }


    function handleChangeBox(e) {
         if ( e.target.checked ) {
             setAnimal({ ...animal, [e.target.name]: 1 })
         } else {
             setAnimal({ ...animal, [e.target.name]: 0 } )
         }
      }

    
    function handleGenero(e) {
        setAnimal({...animal, genero: e.target.options[e.target.selectedIndex].value, })
    }


    function handleEspecies(e) {
        setAnimal({...animal, especie: e.target.options[e.target.selectedIndex].value, })
    }
    

    function handlePorte(e) {
        setAnimal({...animal, porte: e.target.options[e.target.selectedIndex].value, })
    }
    
    
     function handleChangeCpfTutor(e) {
        setCpfTmp( { ...cpfTmp, [e.target.name]: e.target.value} ) 
    }


    useEffect(() => {
        setAnimal({ ...animal, tutores: linhasTutor })
    }, [linhasTutor]);


    const handleSubmit = (e) => {
        e.preventDefault()
       cadastrar( animal )
   }


    async function cadastrar( animal ) {
        let msgText = ''
        let msgType = 'success'
        let confirme = ''
        let novoAnimal

        animal = await ConvertToUpper( animal )

        console.log(animal)

        await api.post('/animais/cadastrar', animal,
                {headers: 
                {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },})
            .then((response) => {
                    confirme = response.status
                    novoAnimal = response.data.novoAnimal
                })
            .catch((err) => {
                    msgText = err.response.data.message
                    msgType = 'error'
                })  

        // setFlashMessage(msgText, msgType)

        toastMessage(msgText, msgType)

        if ( confirme === 200 ){
            setTimeout(() => {
            history( `/animais/galeria/${novoAnimal.id}`)
            }, 1000);
        }

    }

    async function fetchData(){
        let msgType = 'sucess'
        let msgText = ''
        let msgError = false

        if (cpfTmp.docto_tutor){ 

        let cpf = cpfTmp.docto_tutor   // e.target.value

        cpf = cpf.replace(/[^\d]+/g,'');	
        
        if ( cpf.length === 11 ) {
            try {

                // setLoading(true);

        await api.post('/tutores/consultarcpf', JSON.stringify({cpf: cpf}),
                {headers: 
                {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },})
                .then((response) => {
                    // setTutorTmp( tutorTmp => [ tutorTmp, response.data.tutor] )
                    setTutorTmp( [response.data.tutor] )
                })
            }  catch (error) {
                msgText = error.response.data.message
                msgType = 'error'
                msgError = true
            } finally {
                // setLoading(false);
                setShowElement(false)
            } }
        } 

        if (msgError === true){
            toastMessage(msgText, msgType)
        }

    }



    const pesquisaLogradouro = async () => {
        
        const logTmp = nomeLog.pesqLogradouro;
        const locTmp = nomeLog.pesqLocalidade;
        const ufTmp = nomeLog.pesqUf;

        const urlTmp = `https://viacep.com.br/ws/${ufTmp}/${locTmp}/${logTmp}/json/`


        if(logTmp === '') return false;
        if (logTmp.length < 3 ){ return false; }

        if(locTmp === '') return false;
        if (locTmp.length < 5 ){ return false; }

        if(ufTmp === '') return false;
        if (ufTmp.length < 2 ){ return false; }


        const result = await fetch(urlTmp,{
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }}).then((result) => result.json()).catch((err) => {
                return err.response
            });        
            setLinLogTmp2( result )
    }



    const PesquisaLocalidade = async () => {
        
        const ufTmp = nomeLog.pesqUf;
        const urlTmp = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufTmp}/municipios`

        if(ufTmp === '') return false;

        if (ufTmp.length < 2 ){ return false; }

        const result = await fetch(urlTmp,{
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }}).then((result) => result.json()).catch((err) => {
                return err.response
            });        
            setLocTmp( result )
    }


    
    const pesquisaCep = async () => {
        if (newSearch){

            const cepTmp = animal.cep.replace(/[^\d]+/g,'');

            if(cepTmp === '') return false;

            if (cepTmp.length !== 8 ){ return false; }

            const urlTmp = `https://viacep.com.br/ws/${cepTmp}/json/`

            const result = await fetch(urlTmp,{
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                }}).then((result) => result.json()).catch((err) => {
                    return err.response
            });          
            setCepLogTmp1( result )
            setNewSearch( false )
        }
    }



    function handlePesqUf(e) {
        setNomeLog({ ...nomeLog, pesqUf: e.target.options[e.target.selectedIndex].value})
    }


    function handleUf(e) {
        setTutor({...tutor, uf: e.target.options[e.target.selectedIndex].value})
    }



    function handleChangePesqLogradouro(e) {
        if ((e.target.value !== '') && (e.target.value !== undefined )){
            e.target.value = toUpperCase(e.target.value)
            setNomeLog({ ...nomeLog, [e.target.name]: e.target.value })
        }
    } 



    function handleChangePesqLocalidade(e) {
        if ((e.target.value !== '') && (e.target.value !== undefined )){
            e.target.value = toUpperCase(e.target.value)
            setNomeLog({ ...nomeLog, [e.target.name]: e.target.value })
        }
    }



    const handleClickOpen = () => {
        setLinhasLog([])
        setOpen(true);
    };
  


    const handleClose = () => {
        setOpen(false);
    };



    useEffect( () => {
        fetchData()
    }, [cpfTmp]);



    useEffect(() => {
        setTutor([
            ...tutor,
            ...tutorTmp]
        );
    }, [tutorTmp]);


    
    useEffect(() => {
        const result = tutor.filter((v,i) => {
            return tutor.map((item)=> item.tutorId).indexOf(v.tutorId) === i
          })
        setLinhasTutor(result)
    }, [tutor]);
    

 

    useEffect(() => {
        PesquisaLocalidade()
    }, [nomeLog.pesqUf])



    useEffect(() => {
        let locOpcoes = []
        if ( locTmp && locTmp.length >0 ){
            locTmp.forEach((item,index)=>{
                locOpcoes.push({value:toUpperCase(item.nome), label:toUpperCase(item.nome)})
            })
            setLogOptions(locOpcoes)
        }
    }, [locTmp])



    function mostraCpf(){
        setShowElement(true)
    }


    function ocultaCpf(){
        setShowElement(false)
    }

    
    function homeAnimais(){
        history('/animais')
    }


    return (
        <section className={formStyles.form_container}>

            <h1>Cadastro de Animal</h1>  

            <form onSubmit={handleSubmit}>
            <Container className='p-4 shadow-lg text-dark'>
            <fieldset className={Styles.form_container}>
            <div  className={formStyles.form_center}>
                <label> Dados do Animal </label>
            </div>
            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                </div>

                <div className={formStyles.format20}>
                <Input
                    text="Data do Cadastro"
                    name="dtcadastro"
                    type="date"
                    value={Moment(animal.dtcadastro).format('YYYY-MM-DD') || ''}
                    readOnly="readonly"
                    />
                </div>
            </div>
            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                    <Input
                    text="Nome do Animal"
                    name="nome"
                    type="text"
                    placeholder="Digite o nome do Animal"
                    handleOnChange={handleChange}
                    value={toUpperCase(animal.nome) || ''}
                    />
                </div>

                <div className={formStyles.format20}>
                <Input
                    text="Data de Nascimento"
                    name="nascimento"
                    type="date"
                    handleOnChange={handleChange} 
                    value={Moment(animal.nascimento).format('YYYY-MM-DD')}
                    />
                </div>

            </div>
            </fieldset>

            <p/>

            <fieldset>
            <div className={formStyles.format}>
            <div className={formStyles.format20}>
                    <Select
                        text="Genero"
                        name="genero"
                        options={generoOpcoes}
                        handleOnChange={handleGenero}
                        value={animal.genero || ''}
                    />  
                </div>

                <div className={formStyles.format30}>
                    <Select
                        text="Espécie"
                        name="especie"
                        options={especiesOpcoes}
                        handleOnChange={handleEspecies}
                        value={animal.especie || ''}
                    />  
                </div>

                <div className={formStyles.format40}>
                    <Input
                        text="Descreva em Caso de Outra Espécie"
                        name="especie_outras"
                        type="text"
                        placeholder="Informe a espécie"
                        handleOnChange={handleChange}
                        value={toUpperCase(animal.especie_outras) || ''}
                    />  
                </div>
            </div>

            <div className={formStyles.format}>
            <div className={formStyles.format20}>
                    <Select
                        text="Porte"
                        name="porte"
                        options={porteOpcoes}
                        handleOnChange={handlePorte}
                        value={animal.porte || ''}
                    />  
                </div>

                <div className={formStyles.format40}>
                    <Input
                        text="Raça"
                        name="raca"
                        type="text"
                        placeholder="Digite a Raça"
                        handleOnChange={handleChange}
                        value={toUpperCase(animal.raca) || ''}
                    />
                </div>

                <div className={formStyles.format30}>
                    <Input
                        text="Cor Predominante"
                        name="cor"
                        type="text"
                        placeholder="Digite a Cor Predominante"
                        handleOnChange={handleChange}
                        value={toUpperCase(animal.cor) || ''}
                    />
                </div>
            </div>

           
            <div className={formStyles.format}>
                <div className={formStyles.format30}>
                    <CheckBox
                        text="&nbsp;&nbsp;&nbsp;Vacina Anti-Rabica"
                        name="antirabica"
                        type="checkbox"
                        handleOnChange={handleChangeBox}
                    />               
                </div>

                <div className={formStyles.format20}>
                    <Input
                        text="Data da Vacinação"
                        name="datavacina"
                        type="date"
                        placeholder="Digite a Data de Vacinação"
                        handleOnChange={handleChange}
                        value={Moment(animal.datavacina).format('YYYY-MM-DD')}
                    />  
                </div>

                <div className={formStyles.format40}>
                    <Input
                        text="Nº do CHIP"
                        name="chip"
                        type="text"
                        placeholder="Digite o Numero do CHIP"
                        handleOnChange={handleChange}
                        maxLength={15}
                        value={ toNumeric( animal.chip ) || '' }
                    />  
                </div>
            </div>
            </fieldset>

            <fieldset>
            <div className={formStyles.format}>
                <div className={formStyles.format100}>
                    <TextArea
                        text="Outras Caracteristicas: Max. 250 caracteres"
                        name="caracteristicas"
                        type="text"
                        placeholder="Digite as Outras Caracteristicas"
                        maxLength={255}
                        rows={4}
                        handleOnChange={handleChange}
                        value={toUpperCase(animal.caracteristicas) || ''}
                    />
                </div>
            </div>

            </fieldset>
            
            <p/>

            <fieldset>
        <div className={formStyles.format}>

        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}
        PaperProps={{
            elevation: 0,
            sx: {
                borderRadius: 3,
                border: "solid 1px black",
                boxShadow: 6,
            }
          }}
       >
            <DialogTitle className={formStyles.form_textcenter}>Pesquisa de CEP por Logradouro</DialogTitle>
            <DialogContentText className={formStyles.form_textcenter}>
                    Entre com os Dados para Pesquisa
            </DialogContentText>
            <DialogContent  sx={{ 
            border: "solid 1px",
            borderRadius: 2,
            borderColor: 'gray',
            boxShadow: 3,
            mt: 5, // margin-top
            ml: 3, // margin-left
            mr: 3  // margin-right
            }}>

            <div className={formStyles.format}>
                <div className={formStyles.format20}>
                <Select
                    text="UF"
                    name="pesqUf"
                    options={ufOpcoes}
                    handleOnChange={handlePesqUf}
                    value={nomeLog.pesqUf || ''}
                />  
                </div>

                <div className={formStyles.format50}>
                <Select
                    text="Localidade"
                    name="pesqLocalidade"
                    options={logOptions}
                    placeholder="Digite o Logradouro"
                    handleOnChange={handleChangePesqLocalidade}
                    value={nomeLog.pesqLocalidade || ''}
                />
                </div>

                <div className={formStyles.format30}>
                    <DialogActions>
                        <Button variant="outlined" color="error" endIcon={<BsXSquare />} onClick={handleClose}>Cancelar&nbsp;</Button>
                    </DialogActions>
                </div>
            </div>

            <div className={formStyles.format}>
                <div className={formStyles.format70}>
                <Input
                    text="Logradouro"
                    name="pesqLogradouro"
                    type="text"
                    placeholder="Digite o Logradouro"
                    handleOnChange={handleChangePesqLogradouro}
                    value={nomeLog.pesqLogradouro || ''}
                />
                </div>

                <div className={formStyles.format30}>
                    <DialogActions>
                        <Button variant="outlined" color="success" endIcon={<BsSearch />} onClick={pesquisaLogradouro}>Pesquisar</Button>
                    </DialogActions>
                </div>

            </div>
            </DialogContent>

               
            <DialogContent style={{height:'250px'}}>
                <DataGrid 
                    getRowId={(row) => row.id}
                    rows={linhasLog}
                    columns={colunasLog}
                    editMode="row"
                    pageSize={3}
                    rowsPerPageOptions={[3]}
                    disableMultipleSelection={true}
                    density="compact"
                    // checkboxSelection
                    disableColumnFilter
                    disableHideColumn
                    disableColumnSelector
                    disableColumnMenu
                    initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                            },
                        },
                    }}
                    sx={{
                        boxShadow: 3,
                        border: 1,
                        borderRadius: 2,
                        borderColor: 'gray',
                    }}
                />

            </DialogContent>

        </Dialog>

</div>
<div className={formStyles.format}>
        <div className={formStyles.format20}>
                <Input
                text="CEP"
                name="cep"
                type="text"
                placeholder="Digite o CEP"
                handleOnChange={handleChangeCep}
                value={cepMask(animal.cep) || ''}
                />
        </div>
        
        <div className={formStyles.format10}>
            <div>
                <Icon className={Styles.iconGrid3} icon={questionCircleFill} color="gray" onClick={handleClickOpen} />
            </div>
        </div>

        <div className={formStyles.format70}>
            <Input
                text="Logradouro"
                name="logradouro"
                type="text"
                placeholder="Digite o Logradouro"
                handleOnChange={handleChange}
                value={toUpperCase(animal.logradouro) || ''}
                maxLength={100}
            />  
        </div>
</div>

<div className={formStyles.format}>
    <div className={formStyles.format10}>
        <Input
            text="Numero"
            name="numero"
            type="text"
            placeholder="Digite o Numero"
            handleOnChange={handleChange}
            value={toNumeric(animal.numero) || '0'}
            maxLength={6}
        />  
    </div>
    <div className={formStyles.format30}>
        <Input
            text="Complemento"
            name="complemento"
            type="text"
            placeholder="Digite o Complemento"
            handleOnChange={handleChange}
            value={toUpperCase(animal.complemento) || ''}
            maxLength={20}
        />  
    </div>

    <div className={formStyles.format50}>
        <Input
            text="Bairro"
            name="bairro"
            type="text"
            placeholder="Digite o Bairro"
            handleOnChange={handleChange}
            value={toUpperCase(animal.bairro) || ''}
            maxLength={100}
        />  
    </div>
</div>

<div className={formStyles.format}>
    <div className={formStyles.format70}>
        <Input
            text="Localidade"
            name="localidade"
            type="text"
            placeholder="Digite a Localidade"
            handleOnChange={handleChange}
            value={toUpperCase(animal.localidade) || ''}
            maxLength={120}
        />  
    </div>

    <div className={formStyles.format20}>
        <Select
            text="UF"
            name="uf"
            options={ufOpcoes}
            handleOnChange={handleUf}
            value={animal.uf || ''}
        />  
    </div>
</div>

</fieldset>

<p/>
            </Container>

            <p/>

            <Container className='p-4 shadow-lg text-dark'>
            <fieldset>
                <div  className={formStyles.form_center}>
                    <label> Definição de Tutor </label>
                </div>
                <div style={{ height: 200, width: '100%' }}>
                    <Stack direction="row" spacing={2} sx={{ mb: 0 }}>
                        <div>
                            <div>
                                &nbsp; 
                            </div>
                            <div>
                                <Button size="small" onClick={mostraCpf}>
                                    Declarar Tutor
                                </Button>
                            </div>
                        </div>

                        { showElement ? 

                        <div className={formStyles.format}>
                            <div className={formStyles.format10}>
                                &nbsp; 
                            </div>
                            <div className={formStyles.format70}>
                                <Input
                                    text="CPF do Tutor"
                                    name="docto_tutor"
                                    type="text"
                                    placeholder="Digite o CPF do Tutor"
                                    handleOnChange={handleChangeCpfTutor}
                                />
                            </div>
                            <div className={formStyles.format10}>
                                &nbsp; 
                            </div>
                            <div>
                                <div className={formStyles.label}>
                                    &nbsp; 
                                </div>
                                <div className={formStyles.format10}>
                                    <FcCancel className={Styles.icon} onClick={ocultaCpf} />
                                </div>
                            </div>
                        </div>

                    : null  }

                    </Stack>

                    <DataGrid 
                        getRowId={(row) => row.tutorId}
                        rows={linhasTutor}
                        columns={colunasTutor}
                        editMode="row"
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                        disableMultipleSelection={true}
                        density="compact"
                        // checkboxSelection
                        disableColumnFilter
                        disableHideColumn
                        disableColumnSelector
                        disableColumnMenu
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                tutorId: false,
                                },
                            },
                        }}
                        sx={{
                            boxShadow: 3,
                            border: 1,
                            borderColor: 'gray',
                        }}
                    />

                </div>

                <div>
                    <div>
                        &nbsp; 
                    </div>
                    <div>
                        &nbsp; 
                    </div>
                </div>
            
            </fieldset>
            </Container>

            <p/>

            <Container className='p-4 shadow-lg text-dark'>
            <fieldset>
                <div className={formStyles.format}>
                    <div className={formStyles.format}>
                        &nbsp;  
                    </div>
                </div>
                
                <div className={Styles.lista_row}/>

                <div className={formStyles.format}>
                    <div className={formStyles.format20}/>
                        <div className={formStyles.format60}>
                            <div className={formStyles.actions}>
                                <div> 
                                    <input type="submit" value="Cadastrar" />
                                </div>  
                            <div> 
                                <input type="button" value="Voltar" onClick={homeAnimais}/>
                            </div>
                        </div>
                    </div>
                    <div className={formStyles.format20}/>
                </div>
            </fieldset>
            </Container>

        </form>
            
        </section>
    )
}

export default CadastroAnimal



/*

                <div className={formStyles.format20}>
                    <Input
                    text="Data de Nascimento"
                    name="nascimento"
                    type="text"
                    placeholder="Digite a data de Nascimento"
                    handleOnChange={handleChange}
                    />  
                </div>

*/
import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
}  from 'react-router-dom'

// import { useParams } from 'react-router-dom'
// import toast, { Toaster } from 'react-hot-toast';

/* componentes */
import Navbarra from './componentes/layout/elementos/Navbar'
import Container from './componentes/layout/elementos/Container'
// import Message from './componentes/layout/elementos/Message'
import Footer from './componentes/layout/elementos/Footer'


/* Carregar Paginas */
import Home from './componentes/paginas/Home'
import Login from './componentes/paginas/Login'

import ListarUsuarios from './componentes/paginas/usuarios/ListarUsuarios'
import CadastroUsuario from './componentes/paginas/usuarios/CadastroUsuario'
import EditarUsuario from './componentes/paginas/usuarios/EditarUsuario'
import PerfilUsuario from './componentes/paginas/usuarios/PerfilUsuario'

import ListarTutores from './componentes/paginas/tutores/ListarTutores'
import CadastroTutor from './componentes/paginas/tutores/CadastroTutor'
import EditarTutor from './componentes/paginas/tutores/EditarTutor'

import ListarAnimais from './componentes/paginas/animais/ListarAnimais'
import CadastroAnimal from './componentes/paginas/animais/CadastroAnimal'
import EditarAnimal from './componentes/paginas/animais/EditarAnimal'
import GaleriaAnimal from './componentes/paginas/animais/GaleriaAnimal'

import TutorAnimaisPdf from './componentes/paginas/relatorios/TutorAnimaisPdf'
import TutorLogradouroPdf from './componentes/paginas/relatorios/TutorLogradouroPdf'
import TutorBairroPdf from './componentes/paginas/relatorios/TutorBairroPdf'

import RelatDashboard from './componentes/paginas/relatorios/Dashboard'
import AnimaisTutorPdf from './componentes/paginas/relatorios/AnimaisTutorPdf'
import AnimaisLogradouroPdf from './componentes/paginas/relatorios/AnimaisLogradouroPdf'
import AnimaisBairroPdf from './componentes/paginas/relatorios/AnimaisBairroPdf'

import Transferencias from './componentes/paginas/tranferencias/Transferencia'
import TransferAnimalId from './componentes/paginas/tranferencias/TransferAnimalId'

/* Context e Provider */
import { UserProvider } from './context/UserContext'

import { Toaster } from 'react-hot-toast';

function App() {
  
  return (
      <Router>
        <UserProvider>
        <Navbarra/>
       
        <Container>
        
        <Toaster 
          reverseOrder={false}
          gutter={8}
          containerClassName=""
        />

        <Routes>
          <Route path="/" element={<Home />} default />

          <Route path="/login" element={<Login />} />
          
          <Route path="/usuarios/" element={<ListarUsuarios />} />          
          <Route path="/usuarios/cadastro" element={<CadastroUsuario />} />
          <Route path="/usuarios/editar/:id" element={<EditarUsuario />} />
          <Route path="/perfil/" element={<PerfilUsuario />} />

          <Route path="/tutores/" element={<ListarTutores />} />
          <Route path="/tutores/cadastro" element={<CadastroTutor />} />
          <Route path="/tutores/editar/:id" element={<EditarTutor />} />

          <Route path="/animais/" element={<ListarAnimais />} />
          <Route path="/animais/cadastro" element={<CadastroAnimal />} />
          <Route path="/animais/editar/:id" element={<EditarAnimal />} />
          <Route path="/animais/galeria/:id" element={<GaleriaAnimal />} />

          <Route path="/transferencias/" element={<Transferencias />} />
          <Route path="/transferencias/animalid/:id" element={<TransferAnimalId />} />

          <Route path="/relatorios" element={<RelatDashboard />} />
          <Route path="/relatorios/tutoranimais" element={<TutorAnimaisPdf />} />
          <Route path="/relatorios/tutorlogradouro" element={<TutorLogradouroPdf />} />
          <Route path="/relatorios/tutorbairro" element={<TutorBairroPdf />} />

          <Route path="/relatorios/animaistutor" element={<AnimaisTutorPdf />} />
          <Route path="/relatorios/animaislogradouro" element={<AnimaisLogradouroPdf />} />
          <Route path="/relatorios/animaisbairro" element={<AnimaisBairroPdf />} />

          <Route path="*" element={<Navigate replace to="/" />} />

        </Routes>
        </Container>
        <Footer />
        </UserProvider>
      </Router>
  );
}

export default App;

/*
npm install react-scripts
npm install sequelize
npm install handlebars
npm install mysql3
npm install express express-handlebars
npm install nodemon
npm install mysql2
npm install cors bcrypt cookie-parser
npm install jsonwebtoken
npm install multer
npm install bcrypt
npm install axios events react-icons react-router-dom
npm install react-bootstrap bootstrap
npm install http
npm install stream-http
npm install react-select
npm install moment 
npm install dotenv
npm install file-saver
npm install react-input-mask@next --save
npm install @mui/x-data-grid
npm install @mui/x-data-grid-generator
npm install react-hot-toast
npm install jspdf
npm install object-deep-compare
npm install @iconify/react
npm install @iconify/icons-material-symbols
npm install @iconify/icons-bi
npm install react-tooltip
npm install @react-18-pdf/renderer 
npm install react-paginate
npm install react-loader-spinner 
npm install @shoelace-style/shoelace
npm install pdf-lib
npm install @emotion/styled
npm install @coreui/react-chartjs
npm install @coreui/react
*/

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import styles from '../estilos/Navbar.module.css'
import stylesTeste from '../estilos/Novoteste.module.css'

import { Context } from '../../../context/UserContext'
import Logo from '../../../assets/img/logo_portal.png'

import { SlButton, SlDivider, SlDropdown, SlMenu, SlMenuItem } from '@shoelace-style/shoelace/dist/react';


function Navbarra() {

const { authenticated, logout } = useContext( Context )
const isAdmin = localStorage.getItem('isAdmin')

const homePrefeitura = () => {
    window.location.replace('http://www.franciscomorato.sp.gov.br')
}

return ( 
<div  className={stylesTeste.dropdownHoist}>
<nav className={styles.navbar}>
      <div className={styles.navbar_logo}>
            <img src={Logo} alt="Registro Geral Animal" onClick={homePrefeitura} />
            <h1>Registro Geral Animal</h1>
      </div>
  <div>
  { (( authenticated === true ) && ( JSON.parse(isAdmin) === true )) ? ( 
    <>
<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
          <Link to="/">Home</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
      <Link to="/tutores">Tutores</Link>
    </SlButton>
</SlDropdown>      

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/animais">Animais</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/transferencias">Transferências</Link>
    </SlButton>
</SlDropdown>

<SlDropdown distance={20} skidding={10}>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger" caret>
        <Link to="/relatorios" >Relatórios</Link>
    </SlButton>
    <SlMenu className={stylesTeste.dropDownMenu}>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutoranimais">Tutores-Animais</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutorlogradouro">Tutores-Logradouro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutorbairro">Tutores-Bairro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/animaistutor">Animais-Tutores</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/animaislogradouro">Animais-Logradouro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/animaisbairro">Animais-Bairro</Link></SlMenuItem>
        <SlDivider />
    </SlMenu>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/usuarios">Usuários</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} onClick={logout} slot="trigger">
          <Link to="">Sair</Link>
    </SlButton>
</SlDropdown>

    </>
  ) : ( (( authenticated === true ) && ( JSON.parse(isAdmin) === false )) ? (
    <>
<SlDropdown distance={50}>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
          <Link to="/">Home</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
      <Link to="/tutores">Tutores</Link>
    </SlButton>
</SlDropdown>      

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/animais">Animais</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/transferencias">Transferências</Link>
    </SlButton>
</SlDropdown>

<SlDropdown distance={20} skidding={10}>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger" caret>
        <Link to="/relatorios">Relatórios</Link>
    </SlButton>
    <SlMenu className={stylesTeste.dropDownMenu}>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutoranimais">Tutores-Animais</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutorlogradouro">Tutores-Logradouro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/tutorbairro">Tutores-Bairro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/relatorios/animaistutor">Animais-Tutores</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/">Animais-Logradouro</Link></SlMenuItem>
        <SlMenuItem className={stylesTeste.dropDownItem}><Link to="/">Animais-Bairro</Link></SlMenuItem>
        <SlDivider />
    </SlMenu>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
        <Link to="/perfil">Perfil</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} onClick={logout} slot="trigger">
    <Link to="">Sair</Link>
    </SlButton>
</SlDropdown>

    </>
  )
  :(
    <>
<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
          <Link to="/">Home</Link>
    </SlButton>
</SlDropdown>

<SlDropdown>
    <SlButton className={stylesTeste.dropDownButton} slot="trigger">
          <Link to="/login">Entrar</Link>
    </SlButton>
</SlDropdown>

    </>
  ))}

  </div>
  </nav>
</div>
)

}

export default Navbarra




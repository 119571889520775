// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_form_control__\\+9Ucz {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
  }
  
  .Input_form_control__\\+9Ucz label {
    margin-bottom: 0.1em;
    font-weight: bold;
    font-size: 0.8em;
  }
  
 .Input_form_control__\\+9Ucz input {
    padding: 0.1em 0 0 0.4em;
    border: 1px solid #777;
    border-radius: 5px;
  }

  .Input_center__QwkfZ {
    padding: 0.1em;
    border: 1px solid #777;
    border-radius: 5px;
  }

  .Input_form_control__\\+9Ucz input::placeholder {
    color: #7b7b7b;
  }`, "",{"version":3,"sources":["webpack://./src/componentes/formularios/Input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;EACtB;;EAEA;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;EAClB;;CAED;IACG,wBAAwB;IACxB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".form_control {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-bottom: 0.5em;\r\n  }\r\n  \r\n  .form_control label {\r\n    margin-bottom: 0.1em;\r\n    font-weight: bold;\r\n    font-size: 0.8em;\r\n  }\r\n  \r\n .form_control input {\r\n    padding: 0.1em 0 0 0.4em;\r\n    border: 1px solid #777;\r\n    border-radius: 5px;\r\n  }\r\n\r\n  .center {\r\n    padding: 0.1em;\r\n    border: 1px solid #777;\r\n    border-radius: 5px;\r\n  }\r\n\r\n  .form_control input::placeholder {\r\n    color: #7b7b7b;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Input_form_control__+9Ucz`,
	"center": `Input_center__QwkfZ`
};
export default ___CSS_LOADER_EXPORT___;

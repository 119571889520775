import React from 'react'
import { useState, useContext } from 'react'
import Input from '../formularios/Input'
// import { Link } from 'react-router-dom'

import frmStyles from '../formularios/FormPaginas.module.css'
import Styles from '../../componentes/layout/estilos/Dashboard.module.css'
import stylesTeste from '../../componentes/layout/estilos/Novoteste.module.css'

/* contexts */
import { Context } from '../../context/UserContext'

import 'bootstrap/dist/css/bootstrap.min.css';  
import {Container , Button} from 'react-bootstrap'  
import Logo from '../../assets/img/logo_same.png'

function Login() {
  const [user, setUser] = useState({})
  const { login } = useContext(Context)

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    login(user)
  }

  return (

    <section  className={frmStyles.form_container_login}>
      <div className='p-4 shadow'>
          <div className={frmStyles.form_textcenter} >
              <img src={Logo} width="400" height="100"/>
          </div>

          <div className={frmStyles.form_textcenter_login} >
              <h1>Login</h1>
            </div>
          <div>
          <Container>
              <form onSubmit={handleSubmit}>
                  <Input
                    text="Usuario"
                    type="Text"
                    name="usuario"
                    placeholder="Digite o seu Usuario"
                    handleOnChange={handleChange}
                  />

                  <Input
                    text="Senha"
                    type="password"
                    name="password"
                    placeholder="Digite a sua Senha"
                    handleOnChange={handleChange}
                  />
                  <p/>
                  <Button variant="primary" type="submit" >Entrar</Button>
              </form>
          </Container>
        </div>
      </div>
    </section>

  )
}

export default Login
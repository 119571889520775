import React from 'react'
// import { Link } from 'react-router-dom'
// import api from '../../utils/Api'
// import { useState, useEffect } from 'react'

import styles from './Home.module.css'


function Home() {
  /*
  const [rga, setRga] = useState([])

  useEffect(() => {
    api.get('/').then((response) => {
      setRga(response.data.rga)
    })
  }, [])
  */

  return (
    <section>
    <div className={styles.rga_home_header}>
       <h3>Sistema de Identificação Animal</h3>
    </div>
    <div className={styles.rga_container}>
      <p>Existem várias formas de identificação de animais, que variam com a espécie. Assim, bovinos podem ser identificados por brincos; suínos, ovinos e 
        caprinos por tatuagem e os cães e gatos apresentam várias opções tais como coleira com plaqueta, tatuagem e também a implantação de <b>microchip.</b></p>
      <h3>Microchip</h3>
      <p>O Microchip é um <b>micro circuito eletrônico contendo um código único e inalterável, inserido em uma cápsula de biovidro cirúrgico</b> e revestido 
      de substâncias de propriedades antimigratórias, possibilitando a implantação em animais. O microchip não contém bateria e está inerte. Ao receber as 
      ondas emitidas pelo leitor (scanner), retorna a informação na forma de um número. Esse número é composto por quinze algarismos, o que impossibilita a 
      duplicidade. Os dispositivos utilizados pela Prefeitura Municipal de Francisco Morato são adquiridos de acordo com as normas ISO 11784, ISO 11785 FDX-B 
      e certificação pelo ICAR.</p>
      <h3>Agulha e Aplicador de Microchip</h3>
      <p>Cada microchip a ser implantado está contido em uma agulha de injeção estéril e descartável. Já o aplicador tem a <b>forma e o mecanismo de uma seringa 
        de injeção</b>, tratando-se do melhor método de aplicação de microchip em animais.</p>
      <p>Os aplicadores podem ser descartáveis ou reutilizáveis, e a maior parte dos procedimentos é realizada sem o uso de anestesia geral, pois não há necessidade 
        diante da rapidez do processo e do pequeno tamanho do microchip.</p>
      <h3>Leitor de Microchip</h3>
      <p>O leitor faz a verificação do código contido no microchip por um <b>dispositivo de varredura (scanner)</b>. Seu mecanismo é a emissão de sinal de rádio 
      de baixa frequência que mostra o código contido no microchip, sendo exibido no visor do equipamento. A Prefeitura, clínicas veterinárias e estabelecimentos 
      que comercializam animais possuem esses leitores, o que aumenta a possibilidade de identificação de animais perdidos.</p>
      <h3>Microchipei Meu Animal, e Agora?</h3>
      <p>É importante ressaltar que, ao contrário do que muitos pensam, o microchip não tem a função de rastreabilidade por satélites. Trata-se de um mecanismo 
        de identificação do animal, que por sua vez, permite que este seja rastreado através de rede de contatos.</p>
    </div>
    </section>
  )
}

export default Home
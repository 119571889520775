import React, { createContext } from "react";

import useAuth from "../hooks/usuarioAuth";

const Context = createContext();

function UserProvider({ children }) {
  
  const { authenticated, loading, login, logout } = useAuth();

  return (
    <Context.Provider value={{ authenticated, loading, login, logout }} >
      {children}
    </Context.Provider>
  );
}

export { Context, UserProvider };

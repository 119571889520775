import React from 'react'
import styles from './TextArea.module.css'

function TextArea({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    value,
    maxLength,
    rows,
    readOnly,
  }) {
    return (
      <div className={styles.form_control}>
        <label htmlFor={name}>{text}</label>
        <textarea className={styles.center}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          maxLength={maxLength}
          rows={rows}
          {...(readOnly ? { readOnly } : '')}
        />
      </div>
    )
  }
  
export default TextArea
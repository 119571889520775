import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import api from '../../../utils/Api'

import galeryStyles from '../../layout/estilos/Galery.module.css'
import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'

import Input from '../../formularios/Input'
import Select from '../../formularios/Select'
import { toUpperCase } from '../../../utils/toUpperCase'

import toastMessage from './../../../hooks/toastModel'

import { BsCameraFill } from "react-icons/bs"
import { BsFillFileEarmarkCheckFill } from "react-icons/bs"
import { BsFillFileEarmarkExcelFill } from "react-icons/bs"

import Button from '@mui/material/Button';


function GaleriaAnimal() {

    const siteApi = process.env.REACT_APP_API

    const [token] = useState(localStorage.getItem('token') || '')
    const history = useNavigate();

    const [previewImg, setPreviewImg] = useState([0])
    const [galeria, setGaleria] = useState({})
    const [photoGallery, updatePhotoGallery] = useState([])
    const [photoGalleryArray, updatePhotoGalleryArray] = useState([])

    const [nomeImagem, setNomeImagem] = useState('Adicionar Imagem à Galeria')

    const [showElement, setShowElement] = useState(false)

    const [changeFile, setChangeFile] = useState(false)

    const legendaArray =[
        {value:'LADO DIREITO', label:'LADO DIREITO'},
        {value:'LADO ESQUERDO',label:'LADO ESQUERDO'},
        {value:'LINHA SUPERIOR DOS OLHOS',label:'LINHA SUPERIOR DOS OLHOS'},
        {value:'MEMBROS ANTERIORES, VISTA POSTERIOR', label:'MEMBROS ANTERIORES, VISTA POSTERIOR'},
        {value:'MEMBROS POSTERIORES, VISTA POSTERIOR', label:'MEMBROS POSTERIORES, VISTA POSTERIOR'},
        {value:'PESCOÇO, VISTA INFERIOR', label:'PESCOÇO, VISTA INFERIOR'},
        {value:'FOCINHO', label:'FOCINHO'},
        {value:'OUTRA DESCRIÇÃO', label:'OUTRA DESCRIÇÃO'}
        ]

    const { id } = useParams()



    function showOrHide(){
        setNomeImagem('Adicionar Imagem à Galeria')
        setGaleria({ ...galeria, images: [null] })
        setShowElement(true)
    }



    useEffect(() => {
      setGaleria(galeria => ({
        ...galeria,
        animalId: id})
      );
    }, [photoGallery]);

 


    useEffect(() => {
        setGaleria({ ...galeria, images: [previewImg[0]] })
    }, [previewImg])



    function  handleChangeGaleria(e) {
        setPreviewImg(Array.from(e.target.files)) 
    }

    useEffect(() => {
        setNomeImagem(previewImg[0].name)
    }, [previewImg])



    async function excluirImagem(imagemId, animalId) {
        let msgType = 'success'
        let msgText = 'Imagem excluida com sucesso'
        let confirme = ''

        await api.post('/animais/imagemdel', JSON.stringify({img_id: imagemId, animalId: animalId}),
            {headers: {
                      'Authorization': `Bearer ${JSON.parse(token)}`,
                      'Content-Type': 'application/json',},
            })
        .then((response) => {
                confirme = response.status           
            })
        .catch((error) => {
                msgType = 'error'
                msgText = error.response.data.message
            })

        toastMessage(msgText, msgType)

        if ( confirme === 200 ){
            setChangeFile(true)
        }

        // setChangeFile(true)

    }



    function gravarImagem() {
        // e.preventDefault()
        imagemUpload(galeria)
        // setShowElement(false)
    }



    function cancelImagem(){
        setNomeImagem('Adicionar Imagem à Galeria')
        setGaleria(state => {
            return {...state, ...{legenda: undefined, images: [null]} }
        })
        setShowElement(false)
    }



    async function imagemUpload(animal){
        let msgText = ''
        let msgType = ''
        let confirme = ''

        const formData = new FormData()

        const imagemFormData = await Object.keys(galeria).forEach((key) => {
            if (key === 'images') {
                for (let i = 0; i < animal[key].length; i++) {
                    formData.append('images', galeria[key][i])
                }
            } else {
                formData.append(key, galeria[key])
            }
        })

        formData.append('dados', imagemFormData)

        await api.request({     
            method: "post",      
            url: '/animais/imagemcad',    
            headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data',}, 
            data: formData,      
            })
            .then (result => {     
                confirme = result.status

                if ( confirme === 200 ){
                    msgText = result.data.message
                    msgType = 'success'

                    setChangeFile(true)
                }
            })
            .catch((error) => {

                msgText = error.response.data.message
                msgType = 'error'
                return error.response.data
            })
            
        toastMessage(msgText, msgType)

        setGaleria(state => {
            return {...state, ...{legenda: undefined, images: [null]} }
        })

        setNomeImagem('Adicionar Imagem à Galeria')

    }



    function handleChange(e) {
        setGaleria({ ...galeria, [e.target.name]: toUpperCase( e.target.value ) })
    }
    

    function handleChangeLegenda(e) {
        setGaleria({...galeria, legenda: e.target.options[e.target.selectedIndex].value, })
    }
    

    function mostraZoom(e) {
    }


    useEffect(() => {
        fetchData();
    }, [changeFile] )



    async function fetchData(){
    
    const result = await fetch(`${siteApi}/animais/imagemlist`,{
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json'},
        body: JSON.stringify({animalId: id})
        })
        .then((result) => result.json())
        
        .catch((error) => {
            return error.response.data
        });

        updatePhotoGallery( result.imagemPesq );
        setChangeFile(false)

    }



    useEffect(() => {
        fetchImages()
    }, [photoGallery] ) 


    async function fetchImages() {

        const array1Tmp = []
        const array2Tmp = []

        updatePhotoGalleryArray([])

        photoGallery.forEach((item, index) => {

            array1Tmp.push( item )

            if (array1Tmp.length === 2){
              array2Tmp.push( [array1Tmp[0],array1Tmp[1]] )
              array1Tmp.length = 0
            }

            if ( ( photoGallery.length === ( index + 1) ) && ( array1Tmp.length === 1 ) ){
               array2Tmp.push( [array1Tmp[0]] )
            }

        } ) 

        // console.log( array2Tmp )
        
        updatePhotoGalleryArray( array2Tmp )

    }


    function homeAnimais(){
        history('/animais')
    }

return (
  <section>

  <div className={Styles.lista_header}>

      <div className={formStyles.format60}>
          <h1>Galeria de Fotos</h1>  
      </div>

        <div className={formStyles.format20}>
            <div className={Styles.actions}>
                <button className={Styles.conclude_btn} onClick={showOrHide} > Adicionar Imagem </button>  

            </div>
        </div>

        <div className={formStyles.format20}>
            <div className={Styles.actions}>
                <button className={Styles.exclude2_btn} onClick={homeAnimais}> Voltar </button>
            </div>
        </div>

  </div>


  <form>
  { showElement ?  
    <div className={galeryStyles.container}>

    <div className={formStyles.format} >

        <div className={formStyles.format50}>
            <div className={formStyles.format100}>
                <Select
                text="Descrição para a Imagem"
                name="legenda"
                options={legendaArray}
                placeholder="Digite Um Legenda Para a Imagem"
                handleOnChange={handleChangeLegenda}
                value={galeria.legenda || ''}
                />
            </div> 

            <div className={formStyles.format100}>
                <Input
                text="Descreva a Outra Descrição"
                name="sublegenda"
                type="text"
                placeholder="Digite Descrição Para a Imagem"
                handleOnChange={handleChange}
                value={toUpperCase(galeria.sublegenda) || ''}
                />
            </div>
        </div>
        
        <div className={formStyles.format10} />

        <div className={formStyles.format40}>
            
            <div className={formStyles.format100}>
                <div className={formStyles.form_textcenter}>
                    <label>&nbsp;</label> 
                </div>
            </div>

            <div className={formStyles.format100}>
                <div className={formStyles.form_textcenter}>
                    <label>{nomeImagem}</label> 
                </div>
            </div>

            <p/>

            <div className={formStyles.format100}>
                <div className={Styles.lista_header }>
                    <div>
                        <Button variant="contained" size="small" component="label" startIcon={<BsCameraFill />}>
                            Upload
                            <input
                            name="imagem"
                            type="file"
                            onChange={handleChangeGaleria}
                            hidden
                            />
                        </Button>
                    </div>

                    <div>
                        <Button variant="contained" size="small" component="label" startIcon={<BsFillFileEarmarkCheckFill />}  onClick={() => gravarImagem()}>
                            Salvar&nbsp;
                        </Button>
                    </div>

                    <div>
                        <Button variant="contained" size="small" component="label" startIcon={<BsFillFileEarmarkExcelFill />}  onClick={() => cancelImagem()} >
                            Cancelar 
                        </Button>
                    </div>

                </div>
            </div>

        </div>  

        <div>
            <Input
            name="animalId"
            type="hidden"
            value={id}
            />
        </div>
    </div>

    </div>

  : null 
  }
  
  <p/>

  </form>   
  <fieldset className={galeryStyles.container}> 
    {
   ( photoGalleryArray.length > 0 )? (
        photoGalleryArray.map((item, index) => (
          
            <div key={index}  className={galeryStyles.fieldset}>
                {item.map((image, index2) => {           
                    return (   
                        <div key={index2} className={galeryStyles.format50}>

                            <div className={formStyles.form_center}>
                                <label>{image.legenda}</label>
                            </div>

                            <div className={galeryStyles.imagem}>
                                <img 
                                    className={galeryStyles.hover_zoom}
                                    src={`${siteApi}/images/animais/${image.img_animal}`}
                                    alt={`image_${image.animalId}`}
                                    height="300"
                                    width="300"
                                    key={`index_${index}_${image.id}`}
                                    onClick={ mostraZoom(`${image.id}`) }
                                />
                            </div>

                            <div className={Styles.actions}>
                                    <button className={Styles.exclude_btn} onClick={() => { excluirImagem(image.id, image.animalId) }} > Excluir </button>
                            </div>
                            
                        </div>
                    )
                })}
            </div>
 
        ))): (
            <>
            <div className={formStyles.format100}>
                    <label className={formStyles.form_center}>Não há imagem cadastrada na Galeria</label>
            </div>
            </>
        ) 
    }

</fieldset>

</section>

)

}

export default GaleriaAnimal


/*

    <div className={formStyles.form_container }>
        <div className={formStyles.format30}>
            <div className={formStyles.actions}>
                <input type="button" value="Voltar" onClick={homeAnimais} />
            </div>
        </div>
    </div>

*/

/*
                  <Button variant="outlined" color="success" onClick={() => gravarImagem()}>Gravar&nbsp;</Button>
                  <Button  variant="outlined" color="error" onClick={() => cancelImagem() } >Cancelar</Button>
                  */

/*

                </div>

                <div className={formStyles.form_textcenter}>
                    <input type="submit" value="Gravar" />
                </div>
                </div>
                <div className={formStyles.format40}>
                <div className={formStyles.form_textcenter}>
                    <input type="submit" value="Cancelar" />
                </div>
*/


/*
      if (index % 2 !== 0) {
        if (photoGallery[index+1]){
            arrayTmp.push([photoGallery[index],photoGallery[index+1]])
        } else {
            arrayTmp.push([photoGallery[index]]) 
        }
        }
      */

/*


<fieldset>
    {
   ( photoGalleryArray ) && ( photoGalleryArray.length ) &&(
            photoGalleryArray.map((item, index) => (
              
            <div className={galeryStyles.fieldset}>
             
              {item.map((image) => {           
                return (   
                <div className={galeryStyles.format40}>
                <div>
                <label>{image.legenda} </label>
                
                <img 
                  className={galeryStyles.imagem}
                  src={`${siteApi}/images/animais/${image.img_animal}`}
                  alt={`image_${image.id_animal}`}
                  height="350"
                  width="450"
                  key={`index_${index}_${image.id}`}
                  onClick={mostraZoom(image.id)}
                />
                </div>

                <div className={Styles.actions}>
                    <button className={Styles.exclude_btn} onClick={() => { excluirImagem(image.id) }} > Excluir </button>
                </div>
                 
                </div>

                )
              })}

            </div>
            ))) 
    }
</fieldset>

*/




/*

<fieldset>
    {

            photoGalleryArray.map((image, index) => (
              
            <div className={galeryStyles.fieldset}>
             
                <div className={galeryStyles.format40}>
                <div>
                <label>{image.legenda} </label>
                
                <img 
                  className={galeryStyles.imagem}
                  src={`${siteApi}/images/animais/${image.img_animal}`}
                  alt={`image_${image.id_animal}`}
                  height="350"
                  width="450"
                  key={`index_${index}_${image.id}`}
                  onClick={mostraZoom(image.id)}
                />
                </div>

                <div className={Styles.actions}>
                    <button className={Styles.exclude_btn} onClick={() => { excluirImagem(image.id) }} > Excluir </button>
                </div>
                 
                </div>

            </div>
            ))
    }
</fieldset>

*/

/*




*/


/*

            <div className={formStyles.format90}>
                <Input
                text="Adicionar Imagem à Galeria"
                name="imagem"
                type="file"
                placeholder="Carregue o Arquivo de Imagem"
                handleOnChange={handleChangeGaleria}
                />
            </div>


            */
import React from 'react'
import styles from './Input.module.css'

function Input({
  type,
  text,
  name,
  placeholder,
  handleOnChange,
  value,
  readOnly,
  defaultValue,
  maxLength,
  multiple,
  hidden
}) {
  return (
    <div className={styles.form_control}>
      <label htmlFor={name}>{text}</label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={value}
        {...(readOnly ? {readOnly} : '')}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...(multiple ? { multiple } : '')}
        {...(hidden ? { hidden } : '')}
      />
    </div>
  )
}

export default Input


import React from 'react'
import styles from "../estilos/Footer.module.css";

function Footer() {
  return <footer className={styles.footer}>
    <b>Registo Geral Animal</b> &copy; 2022
  </footer>
}

export default Footer

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .Home_rga_home_header__12F3- {
    margin-bottom: 2em;
  }
  
  .Home_rga_home_header__12F3- h1 {
    margin-bottom: 0.3em;
  }
  
  .Home_rga_container__h8Wgt {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .Home_rga_card__GC\\+op {
    width: 22%;
    margin: 1.5%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .Home_rga_card_image__Nsh21 {
    background-size: cover;
    background-position: center;
    height: 225px;
    width: 100%;
    margin-bottom: 1.2em;
  }
  
  .Home_rga_card__GC\\+op h3 {
    color: #16479d;
    margin-bottom: 1em;
    font-size: 1.6em;
    padding: 8px;
  }
  
  .Home_rga_card__GC\\+op p {
    margin-bottom: 1em;
  }
  
  .Home_rga_card__GC\\+op a {
    border-radius: 8px;
    color: #16479d;
    background-color: #ffd400;
    border: none;
    min-width: 100px;
    padding: 15px;
    width: 100%;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
    text-decoration: none;
  }
  
  .Home_rga_card__GC\\+op a:hover {
    background-color: #e7c30d;
    color: #fff;
  }
  
  .Home_adopted_text__d6OHG {
    font-weight: bold;
    color: #25b456;
    padding: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/componentes/paginas/Home.module.css"],"names":[],"mappings":"EAAE;IACE,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,WAAW;IACX,oBAAoB;EACtB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,aAAa;EACf","sourcesContent":["  .rga_home_header {\r\n    margin-bottom: 2em;\r\n  }\r\n  \r\n  .rga_home_header h1 {\r\n    margin-bottom: 0.3em;\r\n  }\r\n  \r\n  .rga_container {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    flex-wrap: wrap;\r\n  }\r\n  \r\n  .rga_card {\r\n    width: 22%;\r\n    margin: 1.5%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n  }\r\n  \r\n  .rga_card_image {\r\n    background-size: cover;\r\n    background-position: center;\r\n    height: 225px;\r\n    width: 100%;\r\n    margin-bottom: 1.2em;\r\n  }\r\n  \r\n  .rga_card h3 {\r\n    color: #16479d;\r\n    margin-bottom: 1em;\r\n    font-size: 1.6em;\r\n    padding: 8px;\r\n  }\r\n  \r\n  .rga_card p {\r\n    margin-bottom: 1em;\r\n  }\r\n  \r\n  .rga_card a {\r\n    border-radius: 8px;\r\n    color: #16479d;\r\n    background-color: #ffd400;\r\n    border: none;\r\n    min-width: 100px;\r\n    padding: 15px;\r\n    width: 100%;\r\n    cursor: pointer;\r\n    font-size: 1.1em;\r\n    font-weight: bold;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .rga_card a:hover {\r\n    background-color: #e7c30d;\r\n    color: #fff;\r\n  }\r\n  \r\n  .adopted_text {\r\n    font-weight: bold;\r\n    color: #25b456;\r\n    padding: 15px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rga_home_header": `Home_rga_home_header__12F3-`,
	"rga_container": `Home_rga_container__h8Wgt`,
	"rga_card": `Home_rga_card__GC+op`,
	"rga_card_image": `Home_rga_card_image__Nsh21`,
	"adopted_text": `Home_adopted_text__d6OHG`
};
export default ___CSS_LOADER_EXPORT___;

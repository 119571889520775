import React from 'react'
import api from '../../../utils/Api'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Animais.module.css'

import AnimalForm from '../animais/AnimaisForm'
import { useNavigate } from 'react-router-dom'

/* hooks */
// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

import ConvertToUpper from '../../../utils/ConvertToUpper'

function EditarAnimal() {
    const { id } = useParams()

    const [token] = useState(localStorage.getItem('token') || '')
    
    const [animal, setAnimal] = useState(
        {chip:undefined, 
         nome:undefined,
         especie:undefined, 
         especie_outras:undefined, 
         nascimento:undefined, 
         genero:undefined, 
         raca:undefined, 
         porte:undefined, 
         cor:undefined, 
         caracteristicas:undefined, 
         antirabica:undefined, 
         datavacina:undefined, 
         logradouro:undefined,
         numero:undefined,
         complemento:undefined,
         bairro:undefined,
         localidade:undefined,
         cep:undefined,
         uf:undefined,
        })

    const [tutor, setTutor] = useState([{  
        tutor_id:undefined, 
        tutor_docto:undefined, 
        tutor_nome:undefined,
    }])

    const history = useNavigate();
    // const { setFlashMessage } = useFlashMessage()


    async function fetchAnimal(){

        await api.post('/animais/editar', JSON.stringify({animalId: id}),{
             headers: {
            'Authorization': `Bearer ${JSON.parse(token)}`,
            'Content-Type': 'application/json',
        },
        }).then((response) => {
            const vtutor = response.data.animal.tutors
            delete response.data.animal.tutors
            const vanimal = response.data.animal
            setAnimal( vanimal ) 
            limpaTutor( vtutor )
            return response.data
        })
        .catch((err) => {
            return err.response.data
        })     
    }

    function limpaTutor(tutorTmp){
        const arrayTutor = []
        tutorTmp.forEach( (item, index ) => {
            delete item.tutels
            arrayTutor.push(item)
        })
        setTutor(arrayTutor)
    }


    useEffect(() => {
        fetchAnimal();
    }, [] )
   

    async function updateAnimal(animal) {
        let msgText = 'Alteração Realizada com Sucesso!'
        let msgType = 'success'
        let confirme = ''

        animal = await ConvertToUpper( animal )

        await api.post('/animais/gravar', JSON.stringify(animal),{
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },
            })
        .then((response) => {
                confirme = response.status
            })
        .catch((error) => {

                msgType = 'error'
                msgText = error.response.data.message
            })
            
      
        toastMessage(msgText, msgType)

        if ( confirme === 200 ){
            setTimeout(() => {
                history(`/animais/galeria/${id}`)
            }, 1000);
        }

    }

    return (
        <section>
            <div className={styles.addAnimal_header}>
                <h1>Editando: {animal.nome}</h1>
                <p>Depois da edição os dados serão atualizados no sistema</p>
            </div>
            {animal.nome && (
            <AnimalForm handleSubmit={updateAnimal} animalData={animal} tutorData={tutor} btnText="Gravar" />
            )}
        </section>
    )

}

export default EditarAnimal
import React  from 'react'
import api from '../../../utils/Api'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Tutores.module.css'
import TutorForm from '../../paginas/tutores/TutoresForm'
import { useNavigate } from 'react-router-dom'

/* hooks */
// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

// import { toUpperCase } from '../../../utils/toUpperCase'
import ConvertToUpper from '../../../utils/ConvertToUpper'


function EditarTutor() {
    const { id } = useParams()

    const [token] = useState(localStorage.getItem('token') || '')
    
    const history = useNavigate();
    // const { setFlashMessage } = useFlashMessage()

    const [tutor, setTutor] = useState({})


    
    useEffect(() => {
        
        let msgText = 'Consulta efetuada com sucesso'
        let msgType = 'success'

        api
            .post('/tutores/editar',JSON.stringify({id: id}), {
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },
            })
        .then((response) => {
                setTutor(response.data.tutor)
            })
        .catch((error) => {
                if( error ){
                    msgText = error.response.data.message
                    msgType = 'error'

                    toastMessage(msgText, msgType)

                }}) 

    }, [token, id])



    async function updateTutor(tutor) {
        let msgText = 'Alteração Realizada com sucesso!'
        let msgType = 'success'
        let confirme = ''

        tutor = await ConvertToUpper( tutor )

        const formData = new FormData()

        const tutorFormData = await Object.keys(tutor).forEach((key) => {
            if (key === 'images') {
              for (let i = 0; i < tutor[key].length; i++) {
                formData.append('images', tutor[key][i])
              }
            } else {
              formData.append(key, tutor[key])
            }
          })

        formData.append('tutor', tutorFormData)

            await api.post('/tutores/gravar', formData,{
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                confirme = response.status
            })
            .catch((error) => {
                msgText = error.response.data.message
                msgType = 'error'
            })

            //setFlashMessage(msgText, msgType)
            
            toastMessage(msgText, msgType)

            if ( confirme === 200 ){
                setTimeout(() => {
                    history('/tutores')
                }, 2000);
            }
    }

    return (
        <section>
            <div className={styles.addTutor_header}>
                <h1>Editando: {tutor.nome}</h1>
                <p>Depois da edição os dados serão atualizados no sistema</p>
            </div>
            {tutor.nome && (
            <TutorForm handleSubmit={updateTutor} tutorData={tutor} btnText="Gravar" />
            )}
        </section>
    )

}

export default EditarTutor
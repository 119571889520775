import React from 'react'
import styles from './InputMask.module.css'
//  import InputMask from 'react-input-mask';


function InputMask({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    value,
    maxLength,
    readOnly,
    multiple,
  }) {
    return (
      <div className={styles.form_control}>
        <label htmlFor={name}>{text}</label>
        <input className={styles.center}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          maxLength={maxLength}
          {...(readOnly ? { readOnly } : '')}
          {...(multiple ? { multiple } : '')}
        />
      </div>
    )
  }
  
export default InputMask
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import formStyles from '../../formularios/FormPaginas.module.css'
import Style from '../../../componentes/layout/estilos/Dashboard.module.css'
import {Container} from 'react-bootstrap'

import Input from '../../formularios/Input'
// import CheckBox from '../../formularios/Checkbox'

function UsuarioForm({ handleSubmit, usuarioData, btnText }) {

    const [user, setUser] = useState(usuarioData || {})
    const history = useNavigate();

    function handleChange(e) {
        setUser({ ...user, [e.target.name]: e.target.value })
    }


    const submit = (e) => {
        e.preventDefault()
        handleSubmit(user)
    }


    function homePerfil(){
        history('/')
    }

    return (
    <form onSubmit={submit} className={formStyles.form_container_sm}>

        <Container className='p-4 shadow-lg text-dark'>
        <fieldset> 
            <Input
            text="Nome"
            type="text"
            name="nome"
            placeholder="Digite o seu nome"
            handleOnChange={handleChange}
            value={user.nome }
            readOnly="readonly"
            />

            <Input
            text="Usuario"
            type="text"
            name="usuario"
            placeholder="Digite o usuario"
            handleOnChange={handleChange}
            value={user.usuario}
            readOnly="readonly"
            />  

            <Input
            text="E-mail"
            type="email"
            name="email"
            placeholder="Digite o seu e-mail"
            handleOnChange={handleChange}
            value={user.email || ''}
            />

            <Input
            text="Senha"
            type="password"
            name="password"
            placeholder="Digite a sua senha"
            handleOnChange={handleChange}
            />

            <Input
            text="Confirmação de senha"
            type="password"
            name="retpassword"
            placeholder="Confirme a sua senha"
            handleOnChange={handleChange}
            />

            <div className={formStyles.actions}>
                <div> 
                    <input type="submit" value={btnText} />
                </div>  
                <div> 
                    <input type="button" value="Voltar" onClick={homePerfil}/>
                </div>
            </div>
        </fieldset>
        </Container>

        </form>
    )
}

export default UsuarioForm



import React from 'react'
import { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../layout/estilos/Dashboard.module.css'
import formControl from '../../formularios/Input.module.css'

import Select from '../../formularios/Select'
import Input from '../../formularios/Input'
import { FcOk } from "react-icons/fc"

import { toUpperCase } from '../../../utils/toUpperCase'

import { useNavigate } from 'react-router-dom'

import ReactPaginate from 'react-paginate'
import {Container , Button, Row, Col} from 'react-bootstrap'  


function Transferencias() {

    const siteApi = process.env.REACT_APP_API
    const [token] = useState(localStorage.getItem('token') || '')
    const [transferForm, setTransferForm] = useState({});
    const [lista, setLista] = useState({});
    
    const [changePesquisa, setChangePesquisa] = useState(false);

    const pesqOpcoes = [
        {value:'CHIP', label:'Nº do CHIP'}, 
        {value:'NOME', label:'Nome do Animal'},
        {value:'TUTOR', label:'Nome do Tutor'},
        ]


    const history = useNavigate();

    /* **************************** */
    const [savedPesqTransf, setSavedPesqTransf] = useState( JSON.parse(localStorage.getItem('savePesqTransf')) || {})
    const [pesquisa, setPesquisa] = useState(true)

    const [reloadPesq, setReloadPesq]= useState(false)

    let pageCount = 0
    let offset = 1
    let currentPageData = []
    
    const PER_PAGE = 4;
    
    const [currentPage, setCurrentPage] = useState(0)
    
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }
    
    /* **************************** */   


    function handlePesquisa(e) {
        // setTransferForm( {...transferForm, ...{opcao: e.target.options[e.target.selectedIndex].value} })
        setTransferForm( transferForm => {
            return {...transferForm, ...{opcao: e.target.options[e.target.selectedIndex].value, assunto: ' '}}
        })

        // setLista({})
    }


    function handleChange(e) {
        setTransferForm({ ...transferForm, [e.target.name]: toUpperCase(e.target.value) })
     }


    const handleSubmit = (e) => {
        e.preventDefault()
        fetchData()
     }


     useEffect(() => {
        setChangePesquisa(true)     
    },[lista])  


    function transferPesquisar(){
        setPesquisa(!pesquisa)   
    }


    async function fetchData(){

        if (transferForm.opcao && transferForm.assunto){

            const result = await fetch(`${siteApi}/transfer/pesquisar` ,{
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${JSON.parse(token)}`,
                    'Content-Type': 'application/json',
                },
            body: JSON.stringify(transferForm),
            })
            .then((result) => result.json())
            .then( setSavedPesqTransf(transferForm) ) 
            .catch((err) => {
                return err.response.data
            });
            setLista( result.pesqTrans );
        }
    }



    useEffect(() => {
        localStorage.setItem('savePesqTransf', JSON.stringify(savedPesqTransf))
        if ( savedPesqTransf && savedPesqTransf.assunto && savedPesqTransf.assunto !==  transferForm.assunto ){
            setTransferForm( savedPesqTransf )
            setReloadPesq(true)
        } 
    }, [savedPesqTransf] )



    useEffect(() => {
        if (reloadPesq){
            fetchData()
        }
    }, [reloadPesq] )



    useEffect(() => {
        fetchData()
    }, [pesquisa] )



    useEffect(() => {
        setLista([])
    }, [transferForm.opcao] )



    function carregarLink(animalId){
        history(`/transferencias/animalid/${animalId}`)
    }


    const renderContent = () => {
        if (lista && lista.length > 0){

            offset = currentPage * PER_PAGE
            currentPageData = lista.slice(offset, offset + PER_PAGE)
            pageCount = Math.ceil(lista.length / PER_PAGE)


            if (transferForm.opcao === 'Selecione'){
                transferForm.assunto = ''
            }

        switch (transferForm.opcao) {
            case 'CHIP':
                return (
                    <Container className='p-4 shadow-lg text-dark'>
                    
                    <Row>
                        <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                            <Alert variant='success'>Selecione o animal para prosseguir com a transferência de tutor</Alert>
                        </Col>
                    </Row>

                    <fieldset >
                                          
                        { currentPageData.map((itemAnimal, index) => {  
                            return (
                                < div key={index} >
                                    <Row>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3}>CHIP</Col>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={1} />
                                        <Col className="bg-secondary p-2 fs-5 text-left text-white font-weight-bold " md={8}>Nome do Animal</Col>
                                    </Row>
    
                                    <Row>
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold text-center" md={3} >
                                            <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.chip}</div>
                                        </Col>  
                                        <Col className="bg-info p-1 border-bottom border-dark text-white font-weight-bold" md={1} />
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={7} >
                                            <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.nome}</div>
                                        </Col>
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={1} >
                                            < FcOk  className={Styles.icon_grid2} onClick={() => carregarLink(itemAnimal.id)} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="bg-white p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                    </Row>

                                    <Row>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CPF</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={7} >Nome do Tutor</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={2} />
                                    </Row>  

                                    { (itemAnimal.tutors)?
                                        <>    
                                        { itemAnimal.tutors.map((itemTutor) => {
                                            return (
                                                <div key={itemTutor.tutor_docto}>
                                                <Row>
                                                    <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={3} >
                                                        {itemTutor.tutor_docto}
                                                    </Col>
                                                    <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold" md={8} >
                                                        {itemTutor.tutor_nome}
                                                    </Col>
                                                    <Col className="bg-white p-1 border-bottom border-dark  text-black font-weight-bold" md={1} />
                                                </Row>
                                                </div>  
                                              )            
                                            }
                                          )
                                        }
                                        <Row>
                                            <Col className="bg-danger p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                        </Row>
                                        </>
                                    : 
                                        <Row>
                                            <Col className="bg-danger p-1 border-bottom border-dark text-black font-weight-bold text-center" md={12} >
                                                <span>Este Animal Não Possui Tutor</span> 
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                
                            )
                        })}

                    </fieldset>
                    <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
        
                                containerClassName="pagination"
        
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
        
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
        
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
        
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
        
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </Container>
                    )

            case 'NOME':
                
                return (
                    <Container className='p-4 shadow-lg text-dark'>

                    <Row>
                        <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                            <Alert variant='success'>Selecione o animal para prosseguir com a transferência de tutor</Alert>
                        </Col>  
                    </Row>

                    <fieldset >

                        { currentPageData.map((itemAnimal, index) => {  
                            return (
                                <div key={index}>
                                    <Row>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CHIP</Col>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={1} ></Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={8} >Nome do Animal</Col>
                                    </Row>
    
                                    <Row>
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold text-center" md={3} >
                                            <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.chip}</div>
                                        </Col>  
                                        <Col className="bg-info p-1 border-bottom border-dark text-white font-weight-bold" md={1} />
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={7} >
                                            <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.nome}</div>
                                        </Col>
                                        <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={1} >
                                            < FcOk  className={Styles.icon_grid2} onClick={() => carregarLink(itemAnimal.id)} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="bg-white p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                    </Row>

                                    <Row>  
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CPF</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={7} >Nome do Tutor</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={2} />
                                    </Row>  

                                    { (itemAnimal.tutors)?
                                        <>    
                                        { itemAnimal.tutors.map((itemTutor) => {
                                            return (
                                                <div key={itemTutor.tutor_docto}>
                                                <Row>
                                                    <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={3} >
                                                        {itemTutor.tutor_docto}
                                                    </Col>
                                                    <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold" md={8} >
                                                        {itemTutor.tutor_nome}
                                                    </Col>
                                                    <Col className="bg-white p-1 border-bottom border-dark  text-black font-weight-bold" md={1} />
                                                </Row>
                                               </div>
                                            )
                                        })}
                                        <Row>
                                            <Col className="bg-danger p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                        </Row>
                                        </>
                                    : 
                                        <Row>
                                            <Col className="bg-danger p-1 border-bottom border-dark text-black font-weight-bold text-center" md={12} >
                                                <span>Este Animal Não Possui Tutor</span> 
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            )
                        })}

                    </fieldset>
                    <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
        
                                containerClassName="pagination"
        
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
        
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
        
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
        
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
        
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                    </Container>

                    )

            case 'TUTOR':
                
                return (
                    <Container className='p-4 shadow-lg text-dark'>
                    
                    <Row>
                        <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                            <Alert variant='success'>Selecione o animal para prosseguir com a transferência de tutor</Alert>
                        </Col>  
                    </Row>

                    <fieldset >
                    { currentPageData.map((itemTutor, index) => {  
                        return (
                            <div key={index}>

                                <Row>  
                                    <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={2} >CPF</Col>  
                                    <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={1} ></Col>
                                    <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={6} >Nome do Tutor</Col>
                                    <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={3} />
                                </Row>

                                <Row>
                                    <Col className="bg-info p-1 border-bottom border-dark font-weight-bold text-center" md={2} >
                                        {itemTutor.tutor_docto}
                                    </Col>  
                                    <Col className="bg-info p-1 border-bottom border-dark text-white font-weight-bold" md={1} ></Col>
                                    <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={6} >
                                        {itemTutor.tutor_nome}
                                    </Col>
                                    <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={3} />
                                </Row>

                                <Row>
                                    <Col className="bg-white p-1 fs-3 font-weight-bold text-center" md={12} />
                                </Row>

                                {
                                (itemTutor.animals && itemTutor.animals.length >0)?
                                <>
                                    <Row>  
                                        <Col className="bg-secondary p-2 border-bottom border-dark fs-5 text-black font-weight-bold link" md={1}/>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={6} >Nome do Animal</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >Nº do CHIP</Col>
                                        <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={2} />
                                    </Row>  

                                { itemTutor.animals.map((itemAnimal) => {
                                    return (
                                        <div key={itemAnimal.animalId}>
                                        <Row>
                                            <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold link" md={1}/>
                                            <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold link" md={6} >
                                                <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.animalId)}>{itemAnimal.animalNome}</div>  
                                            </Col>
                                            <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={3} >
                                                <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.animalId)}>{itemAnimal.chip}</div>   
                                            </Col>
                                            <Col className="bg-white p-1 border-bottom border-dark font-weight-bold" md={2} >
                                                < FcOk  className={Styles.icon_grid2} onClick={() => carregarLink(itemAnimal.animalId)} />
                                            </Col>
                                        </Row>
                                        </div>
                                        )
                                    })}
                                    <Row>
                                        <Col className="bg-danger p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                    </Row>
                                </>
                                : 
                                <Row>
                                    <Col className="bg-danger p-1 border-bottom border-dark text-black font-weight-bold text-center" md={12} >
                                        <span>Este Tutor Não Possui Animal</span> 
                                    </Col>
                                </Row> 
                                }

                            </div>
                        )
                    })}

                </fieldset>
                <p/>
                        <div>
                            <ReactPaginate
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                initialPage={0}
        
                                containerClassName="pagination"
        
                                previousLabel="← Anterior"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
        
                                nextLabel="Próxima →"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
        
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
        
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
        
                                disabledClassName="pagination__link--disabled"
                                activeClassName="pagination__link--active"
                            />
                        </div>
                </Container>
                )

                default :
                    return (
                        <Container className='p-4 shadow-lg text-dark'>
                        
                        <Row>
                            <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                                <Alert variant='success'>Selecione o animal para prosseguir com a transferência de tutor</Alert>
                            </Col>
                        </Row>
    
                        <fieldset >
                                              
                            { currentPageData.map((itemAnimal, index) => {  
                                return (
                                    < div key={index} >
                                        <Row>  
                                            <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3}>CHIP</Col>  
                                            <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={1} />
                                            <Col className="bg-secondary p-2 fs-5 text-left text-white font-weight-bold " md={8}>Nome do Animal</Col>
                                        </Row>
        
                                        <Row>
                                            <Col className="bg-info p-1 border-bottom border-dark font-weight-bold text-center" md={3} >
                                                <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.chip}</div>
                                            </Col>  
                                            <Col className="bg-info p-1 border-bottom border-dark text-white font-weight-bold" md={1} />
                                            <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={7} >
                                                <div className={Styles.linkCol} onClick={() => carregarLink(itemAnimal.id)}>{itemAnimal.nome}</div>
                                            </Col>
                                            <Col className="bg-info p-1 border-bottom border-dark font-weight-bold" md={1} >
                                                < FcOk  className={Styles.icon_grid2} onClick={() => carregarLink(itemAnimal.id)} />
                                            </Col>
                                        </Row>
    
                                        <Row>
                                            <Col className="bg-white p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                        </Row>
    
                                        <Row>  
                                            <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={3} >CPF</Col>
                                            <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold" md={7} >Nome do Tutor</Col>
                                            <Col className="bg-secondary p-2 fs-5 text-white font-weight-bold text-center" md={2} />
                                        </Row>  
    
                                        { (itemAnimal.tutors)?
                                            <>    
                                            { itemAnimal.tutors.map((itemTutor) => {
                                                return (
                                                    <div key={itemTutor.tutor_docto}>
                                                    <Row>
                                                        <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold text-center" md={3} >
                                                            {itemTutor.tutor_docto}
                                                        </Col>
                                                        <Col className="bg-white p-1 border-bottom border-dark text-black font-weight-bold" md={8} >
                                                            {itemTutor.tutor_nome}
                                                        </Col>
                                                        <Col className="bg-white p-1 border-bottom border-dark  text-black font-weight-bold" md={1} />
                                                    </Row>
                                                    </div>  
                                                  )            
                                                }
                                              )
                                            }
                                            <Row>
                                                <Col className="bg-danger p-1 fs-3 font-weight-bold text-center" md={12} /> 
                                            </Row>
                                            </>
                                        : 
                                            <Row>
                                                <Col className="bg-danger p-1 border-bottom border-dark text-black font-weight-bold text-center" md={12} >
                                                    <span>Este Animal Não Possui Tutor</span> 
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                    
                                )
                            })}
    
                        </fieldset>
                        <p/>
                            <div>
                                <ReactPaginate
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    initialPage={0}
            
                                    containerClassName="pagination"
            
                                    previousLabel="← Anterior"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
            
                                    nextLabel="Próxima →"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
            
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
            
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
            
                                    disabledClassName="pagination__link--disabled"
                                    activeClassName="pagination__link--active"
                                />
                            </div>
                        </Container>
                        )
    
        }

        }
        setChangePesquisa(false)
    }

    const renderNull = () => {
            return (
                <Container className='p-4 shadow-lg text-dark'>
                    <fieldset >
                    <Row>
                        <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                            <Alert variant='danger'>Pesquise um animal antes de prosseguir com a transferência de tutor</Alert>
                        </Col>  
                    </Row>
                    </fieldset>
                </Container>
        )
    }

    return (

    <section>

        <div className={Styles.lista_header}>
            <div className={formStyles.format30}>
                <label className={formControl.form_control}>&nbsp;</label>
                <h1>Transferência de Tutor</h1>
            </div>
        </div>

        <Container className='p-2 shadow-lg text-dark'>  
            <Row  className="bg-secondary p-2 text-white font-weight-bold" />
            <Row  className="bg-white p-0 text-white font-weight-bold">&nbsp;</Row>
            <Row>          
                <form onSubmit={handleSubmit} className={formStyles.form_container}>
                    <div className={formStyles.format}>
                        <div className={formStyles.format30}>
                            <Select
                            text="Pesquise o Animal por:"
                            name="pesquisa"
                            options={pesqOpcoes}
                            handleOnChange={handlePesquisa}
                            value={transferForm.opcao || ''}
                            />  
                        </div>

                        <div className={formStyles.format50}>
                            <Input
                            text="Digite o Conteúdo a ser Pesquisado"
                            name="assunto"
                            type="text"
                            placeholder="Digite o Valor a ser Pesquisado"
                            handleOnChange={handleChange} 
                            value={transferForm.assunto || ''}
                            />
                        </div>

                        <div className={formStyles.format10}>
                            <div className={formStyles.format10}>
                                <Button variant="outline-danger" onClick={() => transferPesquisar() } >Pesquisar</Button>
                            </div>
                        </div>
                    </div>
                </form>
          </Row>
       </Container>

       <p/>

       { changePesquisa ? renderContent() : renderNull() }

       <p/>

    </section>
    )
}

export default Transferencias

/*


                <Container className='p-4 shadow-lg text-dark'>
                    <fieldset >
                    <Row>
                        <Col className="p-0 font-weight-bold text-black text-center" md={12}>
                            <Alert variant='danger'>Pesquise um animal antes de prosseguir com a transferência de tutor</Alert>
                        </Col>  
                    </Row>

                    <Row>  
                        <Col className="bg-secondary p-3 fs-5 text-white font-weight-bold text-center" md={12}/>  
                    </Row>
    
                    <Row>
                        <Col className="bg-info p-2 border-bottom border-dark font-weight-bold text-center" md={12}/>
                    </Row>

                    <Row>
                        <Col className="bg-white p-2 fs-3 font-weight-bold text-center" md={12} /> 
                    </Row>

                    <Row>
                        <Col className="bg-white p-2 text-black font-weight-bold text-center" md={12} />
                    </Row>

                    <Row>  
                        <Col className="bg-secondary p-3 fs-5 text-white font-weight-bold text-center" md={12} />
                    </Row>  
                    
                    <Row>
                        <Col className="bg-info p-2 border-bottom border-dark font-weight-bold text-center" md={12} />
                    </Row>
                    </fieldset>
                </Container>

*/
import React from 'react'
import styles from './Select.module.css'

function Select({ text, name, options, handleOnChange, value }) {
  return (
    <div className={styles.form_control}>
      <label htmlFor={name}>{text}</label>
      <select
        name={name}
        id={name}
        onChange={handleOnChange}
        value={value || ''}
      >
        <option>Selecione</option>
          {options.map((option,index) => (
          <option value={option.value} key={index}>
              {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select


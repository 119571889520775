import React from 'react'
import { useState } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react'

import Styles from '../../layout/estilos/Dashboard.module.css'
import formStyles from '../../formularios/FormPaginas.module.css'

import {Container , Button, Row} from 'react-bootstrap'  
// import Tooltip from "react-tooltip"
import {Tooltip, TooltipProvider, TooltipWrapper, removeStyle} from "react-tooltip"
import Modal from 'react-bootstrap/Modal'

import { BsSearch, BsPlusCircle } from "react-icons/bs"
// import { BiEdit, BiCamera, BiListOl, BiTrash, BiSearchAlt } from "react-icons/bi"
import { BiEdit, BiCamera, BiListUl, BiTrash } from "react-icons/bi"

import Moment from 'moment'

function PesqAnimalLogradouro({ adicionarAnimalPesq, editarAnimalPesq, excluirAnimalPesq, historicAnimalPesq, galeriaAnimalPesq, showOrHidePesq, listaData}) {

    const itensRef = useRef([])
    const [isOpen, setIsOpen] = useState({})
    const [isHistoric, setIsHistoric] = useState({})
    const [historic, setHistoric]  = useState([])
 

    function hideModal() {
        setIsOpen({show: 0});
    }


    function excluirAnimal( id ){
        setIsOpen({show: id});
    }


    useEffect(() => {
        if (historic.length > 0){
            setIsHistoric({show: historic[0].animalId});
        }
    }, [historic] )



    async function showHistoric( id ) {
        const result = await historicAnimalPesq( id )
        setHistoric( result.histAnimal );
    }



    function hideModalHistoric() {
        setHistoric([])
        setIsHistoric({show: 0});
    }

    return (
        <Container> 
            <Row  className="bg-secondary p-0 text-white font-weight-bold">  
                <div className={Styles.lista_clear}>

                    <div className={formStyles.format10}>
                        Nº CHIP
                    </div>
                    <div className={formStyles.format05} />

                    <div className={formStyles.format30}>
                         Nome do Animal
                    </div>

                    <div className={formStyles.format40}>
                         Logradouro
                    </div>

                    <div className={formStyles.format05}>
                        <BsSearch onClick={showOrHidePesq} className={Styles.icon_grid2}
                        data-tip="Pesquisar"
                        data-for="btn_Pesquisa" />
                        <Tooltip id="btn_Pesquisa" type="warning" effect="solid" borderColor="black" />
                    </div>
                    <div className={formStyles.format05}>
                        <BsPlusCircle onClick={adicionarAnimalPesq} className={Styles.icon_grid2}
                        data-tip="Cadastrar"
                        data-for="btn_Cadastro" />
                        <Tooltip id="btn_Cadastro" type="error" effect="solid" borderColor="black" />
                    </div>
                </div>
          </Row>

      <>   
      {
      listaData.length > 0 &&
      listaData.map((item, index) => {
      return (
          <Row key={index}  className="bg-white p-0 text-black font-weight-bold">  
              <div key={item.id}  className={Styles.lista_row} >       
            
                  <div className={formStyles.format10}>
                      <span className={Styles.linkCol}>{item.chip}</span>
                  </div>

                  <div className={formStyles.format05}>
                      <span className="bold"> </span>
                  </div>

                  <div className={formStyles.format30}>
                      <span className={Styles.linkCol}>{item.nome}</span>
                  </div>

                  <div className={formStyles.format40}>
                      <span className={Styles.linkCol}>{item.logradouro}</span>
                  </div>
                  <div>

                  <Modal  ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                      itensRef.current[item.id][0] = ref } } 
                      show={ isOpen.show === item.id}
                      onHide={() => hideModal(0) }
                      className='p-1 shadow-lg text-dark'
                      >

                      <Modal.Header closeButton>
                          <Modal.Title>
                              Exclusão de Animal
                          </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                          <Container>
                              <div className={formStyles.form_textcenter}>
                                  <span>Confirma a Exclusão de: {item.nome} ?</span>
                              </div>
                          </Container>
                      </Modal.Body>

                      <Modal.Footer>
                          <Container>
                              <div className={formStyles.format}>
                                  <div className={formStyles.format30}/>
                                  <div className={formStyles.format20}>
                                      <Button variant="outline-primary" onClick={() => excluirAnimalPesq( item.id )}>&nbsp;Excluir&nbsp;</Button>
                                  </div>
                                  <div className={formStyles.format10}/>
                                  <div className={formStyles.format20}>
                                      <Button variant="outline-danger" onClick={() => hideModal(0) } >Cancelar</Button>
                                  </div>
                                  <div className={formStyles.format30}/>
                              </div>
                          </Container>
                      </Modal.Footer>
                  </Modal>

                  <Modal  ref={ (ref) => {itensRef.current[item.id] = itensRef.current[item.id] || []
                      itensRef.current[item.id][1] = ref } } 
                      show={ isHistoric.show === item.id} 
                      onHide={() => hideModalHistoric(0)} 
                      size="lg"
                      className='p-4 shadow-lg text-dark'
                      >

                      <Modal.Header closeButton>
                          <Modal.Title>
                              Histórico de Movimentação: {item.nome}
                          </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                          <Container>
                              <Row  className="bg-secondary p-2 text-white font-weight-bold">      
                                  <div  className={Styles.lista_clear}>
                                      <div className={formStyles.format05} />
                                      <div className={formStyles.format10}>
                                          <span className="text-center">Data</span>
                                      </div>
                                      <div className={formStyles.format05}/>
                                      <div className={formStyles.format60}>
                                          <span className="text-center">Movimento</span>
                                      </div>
                                      <div className={formStyles.format20}>
                                          <span className="text-center">Usuário</span>
                                      </div>
                                  </div>
                              </Row>

                              {

                              historic &&
                              historic.map((item, index) => {
                              return (
                                  <Row key={index} className="bg-white p-0 text-black font-weight-bold">
                                      <div  key={item.id} className={Styles.lista_clear}>
                                          <div className={formStyles.format05} />
                                          <div className={formStyles.format15}>
                                              <span className={Styles.linkCol}>{Moment( item.dt_action ).format('DD-MM-YYYY')}</span>
                                          </div>
          
                                          <div className={formStyles.format05}>
                                              <span className="bold"> </span>
                                          </div>
          
                                          <div className={formStyles.format60}>
                                              <span className={Styles.linkCol}>{item.action}</span>
                                          </div>

                                          <div className={formStyles.format20}>
                                              <span className={Styles.linkCol}>{item.userUsuario}</span>
                                          </div>

                                      </div>
                                  </Row>
                                )}
                              )}

                              <Row  className="bg-white p-0 text-white font-weight-bold">&nbsp;</Row>
                              <Row  className="bg-secondary p-1 text-white font-weight-bold" />
                          </Container>
                      </Modal.Body>

                      <Modal.Footer>
                          <Button variant="outline-danger" onClick={() => hideModalHistoric(0)} >&nbsp;Fechar&nbsp;</Button>
                      </Modal.Footer>
                  </Modal>

                  </div>

                  <div className={Styles.actions}>

                        <BiEdit  className={Styles.icon_grid2} onClick={() => { editarAnimalPesq( item.id ) }} 
                        data-tip="Editar"
                        data-for="btn_Editar" />
                        <Tooltip id="btn_Editar" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiCamera className={Styles.icon_grid2} onClick={() => { galeriaAnimalPesq( item.id ) }} 
                        data-tip="Galeria"
                        data-for="btn_Galeria" />
                        <Tooltip id="btn_Galeria" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiListUl className={Styles.icon_grid2} onClick={() => { showHistoric( item.id ) }} 
                        data-tip="Histórico"
                        data-for="btn_Historico" />
                        <Tooltip id="btn_Historico" type="info" effect="solid" borderColor="black" />
                        {' '}

                        <BiTrash className={Styles.icon_grid2} onClick={() => { excluirAnimal( item.id ) }}
                        data-tip="Excluir"
                        data-for="btn_Excluir" />
                        <Tooltip id="btn_Excluir" type="error" effect="solid" borderColor="black" />

                        </div>
              </div>
          </Row>
      )})

      }
      </>

      <>
      {  
          listaData.length === 0 && <p>Não há Animais pesquisados!</p> 
      }
      </>

      </Container>

    )
}

export default PesqAnimalLogradouro

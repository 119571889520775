import React from 'react'
import api from '../../../utils/Api'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// import Styles from './Usuario.module.css'

import formStyles from '../../formularios/FormPaginas.module.css'
import Styles from '../../../componentes/layout/estilos/Dashboard.module.css'
import UsuarioForm from '../../paginas/usuarios/UsuarioForm'

/* hooks */
// import useFlashMessage from '../../../hooks/useFlashMessage'
import toastMessage from './../../../hooks/toastModel'

function EditarUsuario() {
    const { id } = useParams()

    const [usuario, setUsuario] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    // const { setFlashMessage } = useFlashMessage()

    
    async function fetchData(){
        api
            .post('/usuarios/editar',JSON.stringify({id: id}), {
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setUsuario(response.data.usuario)
            })
    }


    useEffect(() => {
        fetchData()
    }, [token, id])


    async function updateUsuario(usuario) {
        let msgText = 'Usuário atualizado com sucesso'
        let msgType = 'success'

        await api.post('/usuarios/gravar', JSON.stringify(usuario),{
                headers: {
                'Authorization': `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'application/json',
                },
            })
        .then((response) => {
                return response.data
            })
        .catch((error) => {
                msgType = 'error'
                msgText = error.response.data.message
            })

            // setFlashMessage(data.message, msgType)

            toastMessage(msgText, msgType)
    }

    return (
        <section className={formStyles.form_container_sm}>
            <div>
                <h1>Editando: {usuario.nome}</h1>
                <p>Depois da edição os dados serão atualizados no sistema</p>
            </div>
            {usuario.nome && (
            <UsuarioForm handleSubmit={updateUsuario} usuarioData={usuario} btnText="Gravar" />
            )}
        </section>
    )

}

export default EditarUsuario